import React, { useRef } from "react";
import useEditorStore from "store/useEditorStore";
import { SlidFeatures } from "utils/privilegeManager";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { sendAmplitudeData } from "utils/amplitude";
import { useSaveDocumentAndCreateHistory } from "hooks/useSaveDocumentAndCreateHistory";
import { useLocation } from "react-router-dom";

const useEditorPDFUpload = () => {
  const location = useLocation();
  const { focusEditor, fileUploadInputRef } = useEditorStore();

  const { saveDocument } = useSaveDocumentAndCreateHistory();
  const mode = useRef(location.pathname.split("/")[1] === "vdocs" ? "vdocs" : "plain docs");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  const handleClickPDFUpload = async () => {
    // [PDF] This method force click the invisible input element <PDFFileUploader /> to open file system for selecting pdf file.
    sendAmplitudeData(`Click upload pdf`, {
      origin: mode.current,
    });
    if (!confirmPrivilege(SlidFeatures.pdfUpload)) return showInsufficientPrivilegeModal();
    await saveDocument();
    focusEditor();
    fileUploadInputRef.current?.click();
  };

  return {
    handleClickPDFUpload,
  };
};

export default useEditorPDFUpload;
