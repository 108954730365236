import React, { useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createSlidLinkPreviewNode, $isSlidLinkPreviewNode, SlidLinkPreviewNode, SlidLinkPreviewProps } from "components/NewEditor/nodes/SlidLinkPreviewNode";
import { $isNodeSelection, $getSelection, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand, LexicalEditor, $createTextNode, $createRangeSelection, $setSelection } from "lexical";
import { $insertNodeToNearestRoot, mergeRegister } from "@lexical/utils";
import styled from "styled-components";
import { getLinkPreviewData } from "redux/actions/vdocsActions";
import store from "redux/store";
import { Button, Typography13 } from "@slid/slid-ips";
import useEditorStore from "store/useEditorStore";
import { useTranslation } from "react-i18next";
import TextInput from "components/NewEditor/ui/TextInput";
import { DialogActions } from "components/NewEditor/ui/Dialog";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";

export const INSERT_LINK_PREVIEW_COMMAND: LexicalCommand<string> = createCommand();
export const FOCUS_LINK_DUMMY_TEXTAREA_COMMAND: LexicalCommand<void> = createCommand("FOCUS_LINK_DUMMY_TEXTAREA_COMMAND");
export type InsertLinkPreviewPayload = Readonly<SlidLinkPreviewProps>;

export function InsertLinkPreviewDialog({ activeEditor, onClose }: { activeEditor: LexicalEditor; onClose: () => void }): JSX.Element {
  const { focusEditor } = useEditorStore();
  const hasModifier = useRef(false);
  const [linkText, setLinkText] = useState("");
  const [isProgressing, setIsProgressing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation("EditorModal");

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e: KeyboardEvent) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [activeEditor]);

  const fetchLinkData = async (url: string) => {
    setIsProgressing(true);

    try {
      const result = await store.dispatch(getLinkPreviewData(url));

      if (result.data?.message === "Invalid URL") {
        setErrorMessage("Please enter a valid URL");
        return;
      }

      if (!result.success) {
        setErrorMessage(result.data?.message || "An error occurred");
        return;
      }

      const metaData = result.meta;
      const link = result.link;

      if (!metaData) {
        setErrorMessage("Wrong response format from the server");
        return;
      }

      setIsProgressing(false);

      return {
        meta: metaData,
        link,
      };
    } catch (error) {
      console.error(error);
      setErrorMessage("Couldn't get this link data, try the other one");
    }
  };

  const handleClickConfirm = async () => {
    let url = linkText;

    setErrorMessage("");
    const response = await fetchLinkData(url);
    if (response) {
      focusEditor();
      activeEditor.dispatchCommand(INSERT_LINK_PREVIEW_COMMAND, JSON.stringify(response));
      activeEditor.dispatchCommand(FOCUS_LINK_DUMMY_TEXTAREA_COMMAND, undefined);
      onClose();
    } else {
      setIsProgressing(false);
    }
  };

  return (
    <Wrapper>
      <TextInput
        placeholder={t("link-preview.placeholder", { keySeparator: ".", ns: "EditorModal" })}
        onChange={(e) => {
          setLinkText(e);
          if (errorMessage) {
            setErrorMessage("");
          }
        }}
        value={linkText}
        showClear
      />
      {errorMessage && <Typography13 text={t("link-preview.errorMessage", { keySeparator: ".", ns: "EditorModal" })} color="--red7" marginTop="8px" />}
      <DialogActions>
        <Button
          appearance="primary"
          size="medium"
          isDisabled={isProgressing || linkText === "" || errorMessage !== ""}
          callback={handleClickConfirm}
          text={t("link-preview.createButton", { keySeparator: ".", ns: "EditorModal" })}
          isLoading={isProgressing}
        />
      </DialogActions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmButton = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  /* e of reset button  */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: 0px;
  background-color: #eee;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  align-self: flex-end;
  margin-top: 20px;
`;

function $getLinkNodeInSelection(): SlidLinkPreviewNode | null {
  const selection = $getSelection();
  if (!$isNodeSelection(selection)) {
    return null;
  }
  const nodes = selection.getNodes();
  const node = nodes[0];
  return $isSlidLinkPreviewNode(node) ? node : null;
}

const SlidLinkPreviewPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([SlidLinkPreviewNode])) {
      throw new Error("SlidLinkPreviewPlugin: SlidLinkPreviewNode is not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        FOCUS_LINK_DUMMY_TEXTAREA_COMMAND,
        () => {
          const linkNode = $getLinkNodeInSelection();
          if (linkNode) {
            const linkNodeKey = linkNode.getKey();
            const linkNodeElement = editor.getElementByKey(linkNodeKey);
            if (linkNodeElement) {
              const input = linkNodeElement.querySelector("textarea");
              if (input) {
                input.focus();
              }
            }
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        INSERT_LINK_PREVIEW_COMMAND,
        (payload: string) => {
          const parsedPayload = JSON.parse(payload);
          const linkPreviewNode = $createSlidLinkPreviewNode({
            link: parsedPayload.link,
            meta: parsedPayload.meta,
          });

          const selection = $getSelection();
          const currentNode = selection?.getNodes()[0];
          const isParagraphNode = currentNode?.getType() === "slid-paragraph";
          const isEmptyParagraphNode = currentNode?.getTextContent() === "";
          if (isParagraphNode && isEmptyParagraphNode) {
            currentNode.replace(linkPreviewNode);
            const newParagraphNode = $createSlidParagraphNode();
            linkPreviewNode.insertAfter(newParagraphNode);
            newParagraphNode.select();
          } else {
            $insertNodeToNearestRoot(linkPreviewNode);
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
};

export default SlidLinkPreviewPlugin;
