import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const FloatingMarkupIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 24} height={height ? height : 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill={color === "#fff" || color === "#ffffff" ? "transparent" : color} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4571 5.14641C15.0666 4.75588 14.4334 4.75588 14.0429 5.14641L5.04289 14.1464C4.85536 14.3339 4.75 14.5883 4.75 14.8535V18.1464C4.75 18.6987 5.19772 19.1464 5.75 19.1464H18.75C19.0261 19.1464 19.25 18.9226 19.25 18.6464C19.25 18.3703 19.0261 18.1464 18.75 18.1464H10.4571L18.75 9.85352C19.1405 9.46299 19.1405 8.82983 18.75 8.4393L15.4571 5.14641ZM15.4064 11.7829L9.04289 18.1464H5.75V14.8535L12.1135 8.49003L15.4064 11.7829ZM16.1135 11.0758L18.0429 9.14641L14.75 5.85352L12.8206 7.78292L16.1135 11.0758Z"
        fill="#212529"
      />
    </SvgWrapper>
  );
};
