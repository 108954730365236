import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export default function BgColorCheckIcon({ width, height, color, style }: SvgIconProps) {
  return (
    <SvgWrapper width={width ? width : 28} height={height ? height : 28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g id="Frame" clip-path="url(#clip0_3357_4299)">
        <g id="Group 3465422">
          <path id="Vector 253" d="M8.5 6H16.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 258" d="M8.5 16H16.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 259" d="M8.5 18H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 260" d="M8.5 20H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 261" d="M8.5 22H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 254" d="M8.5 8H19" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 257" d="M8.5 14H19" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 256" d="M8.5 12H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 262" d="M17 12H20" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 263" d="M8.5 10H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector 264" d="M17 10H20" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3357_4299">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}
