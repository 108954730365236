import React, { useEffect, useRef, useState } from "react";
import { useModal, useToast } from "@slid/slid-ips";
import AutoNotesVideoNoteButton from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesVideoNoteButton";
import OneClickCaptureButtonUI from "components/VideoCaptureButton/ui/OneClickCaptureButtonUI";
import SnipCaptureButtonUI from "components/VideoCaptureButton/ui/SnipCaptureButtonUI";
import VideoRecordButton from "components/VideoCaptureButton/VideoRecordButton";
import WhisperRealTimeSmartLiveTextVideoNoteButton from "components/SmartLiveTextWhisperRealTime/SmartLiveTextVideoNoteButton";
import WhisperAutoNotesMainUI from "components/smartautonotes/AutoNoteVdocsComponents/WhisperAutoNotesMainUI";
import { CaptureButtonContainer } from "components/VideoCaptureButton/style/buttonStyles";
import useWebExtensionCapture from "components/VideoNoteFeatureButtons/utils/useWebExtensionCapture";
import { useVideoRecordStore } from "components/VideoCaptureButton/utils/useVideoRecordStore";
import { $isSlidVideoNode } from "components/NewEditor/nodes/SlidVideoNode";
import PermissionGrantedAlertMessage from "components/alerts/PermissionGrantedAlertMessage";
import ExtensionMediaPermissionModal from "components/VideoDocument/ExtensionMediaPermissionModal";
import { alertExtensionMediaPermissionGranted } from "components/alerts";
import { useAppDispatch, useAppSelector } from "hooks";
import { v4 as uuidv4 } from "uuid";
import { setIsGettingClipRecordingMediaPermission, setIsGettingExtensionMediaPermission, setShowExtensionMediaPermissionModal, setIsExtensionMediaPermitted } from "redux/actions/vdocsActions";
import { setIsGettingSTTMediaPermission } from "redux/actions/sttActions";
import { setIsGettingAutoNotesMediaPermission } from "redux/modules/autoNotesSlice";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import useEditorStore from "store/useEditorStore";
import { $getNodeByKey } from "lexical";
import { setCheckIsExtensionMediaPermittedResponseListener, setIsExtensionClickedListener, setSetVideoArrayBufferResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import { uploadBase64Image, uploadFile } from "utils/aws/s3Interface";
import { trackEvent } from "utils/eventTracking";
import { sendResetCaptureAreaForOneClickCaptureRequestToParentWindow, sendVideoInfoRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { getDefaultSettingCookie, setDefaultSettingCookie } from "utils/cookies/cookies";
import { showCustomModal } from "utils/modal";
import { useTranslation } from "react-i18next";
import { eventTypes } from "types/eventTracking";
import { useHistory, useLocation } from "react-router-dom";

const ExtensionFeatureButtons = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showModal, closeModal } = useModal();
  const { isGettingSTTMediaPermission } = useAppSelector((state) => state.sttReducer);
  const { currentVideo, currentDocument, isGettingExtensionMediaPermission, isExtensionMediaPermitted, isGettingClipRecordingMediaPermission, showExtensionMediaPermissionModal } = useAppSelector(
    (state) => state.vdocs
  );
  const { isWhisperSLTSupported, isRealTimeTranscriptSupported } = useWhisperSLTStore();
  const { isWhisperAutoNotesSupported, isAutoNotesSupported, isGettingAutoNotesMediaPermission } = useAppSelector((state) => state.autoNotes);
  const { manualCapture, showSelectAreaPopup } = useWebExtensionCapture();
  const { videoLoaderNodeKeys } = useVideoRecordStore();
  const { lexicalEditorRef } = useEditorStore();
  const [openRecentNote, setOpenRecentNote] = useState(getDefaultSettingCookie({ property: "openRecentNote" }) === "true");
  const [recentNoteDocumentKey, setRecentNoteDocumentKey] = useState(null);

  const currentDocumentRef = useRef(currentDocument);
  const videoLoaderNodeKeysRef = useRef(videoLoaderNodeKeys);

  const isGettingExtensionMediaPermissionRef = useRef(isGettingExtensionMediaPermission);
  const isExtensionMediaPermittedRef = useRef(isExtensionMediaPermitted);
  const isGettingAutoNotesMediaPermissionRef = useRef(isGettingAutoNotesMediaPermission);
  const isGettingSTTMediaPermissionRef = useRef(isGettingSTTMediaPermission);
  const isGettingClipRecordingMediaPermissionRef = useRef(isGettingClipRecordingMediaPermission);

  useEffect(() => {
    // initialize custom setting cookie
    setOpenRecentNote(getDefaultSettingCookie({ property: "openRecentNote" }) === "true");
    setRecentNoteDocumentKey(getDefaultSettingCookie({ property: "recentNoteDocumentKey" }));
  }, []);

  useEffect(() => {
    const checkRecentNoteDocumentKey = async () => {
      if (location.pathname === "/vdocs/new" && recentNoteDocumentKey && openRecentNote) {
        history.push(`./${recentNoteDocumentKey}`);
      }
    };
    checkRecentNoteDocumentKey();
  }, [recentNoteDocumentKey, openRecentNote]);

  useEffect(() => {
    videoLoaderNodeKeysRef.current = videoLoaderNodeKeys;
  }, [videoLoaderNodeKeys]);

  useEffect(() => {
    currentDocumentRef.current = currentDocument;
    if (currentDocument) {
      setDefaultSettingCookie({
        property: `recentNoteDocumentKey`,
        value: currentDocument["document_key"],
      });
    }
  }, [currentDocument]);

  useEffect(() => {
    if (!currentVideo) {
      sendVideoInfoRequestToParentWindow();
    }
  }, [currentVideo]);

  useEffect(() => {
    if (showExtensionMediaPermissionModal) {
      showCustomModal({
        showModal: showModal,
        closeModal: closeModal,
        customComponentContent: <ExtensionMediaPermissionModal />,
      });
    } else {
      closeModal();
    }
  }, [showExtensionMediaPermissionModal]);

  useEffect(() => {
    isGettingExtensionMediaPermissionRef.current = isGettingExtensionMediaPermission;
    isExtensionMediaPermittedRef.current = isExtensionMediaPermitted;
    isGettingAutoNotesMediaPermissionRef.current = isGettingAutoNotesMediaPermission;
    isGettingSTTMediaPermissionRef.current = isGettingSTTMediaPermission;
    isGettingClipRecordingMediaPermissionRef.current = isGettingClipRecordingMediaPermission;
  }, [isGettingExtensionMediaPermission, isExtensionMediaPermitted, isGettingAutoNotesMediaPermission, isGettingSTTMediaPermission]);

  useEffect(() => {
    setSetVideoArrayBufferResponseListener({
      responseHandler: async (receivedData) => {
        if (!currentDocumentRef.current) return;
        let currentDocumentKey = currentDocumentRef.current["document_key"];
        const videoBlob = new Blob([receivedData.buffer], {
          type: "audio/webm;codecs=opus",
        });
        const uploadedVideoUrl = await uploadFile({
          path: `clip_videos/${currentDocumentKey}`,
          file: new File([videoBlob], `${uuidv4()}.webm`),
        });

        const uploadedVideoPosterUrl = await uploadBase64Image({
          path: `clip_video_poster/${currentDocumentKey}`,
          base64: receivedData.posterUrl,
        });
        if (!uploadedVideoPosterUrl || !uploadedVideoUrl) return;

        const loaderBlockKey = videoLoaderNodeKeysRef.current.shift();
        if (!loaderBlockKey) return;
        lexicalEditorRef.current?.update(() => {
          const videoLoaderNode = $getNodeByKey(loaderBlockKey);
          if ($isSlidVideoNode(videoLoaderNode)) {
            videoLoaderNode.updateVideoData({
              isLoading: false,
              isRecording: false,
              videoUrl: uploadedVideoUrl,
              posterUrl: uploadedVideoPosterUrl,
            });
          }
        });
      },
    });

    setIsExtensionClickedListener({
      responseHandler: async () => {
        if (isGettingExtensionMediaPermissionRef.current && !isExtensionMediaPermittedRef.current) {
          if (isGettingAutoNotesMediaPermissionRef.current) {
            dispatch(setIsGettingAutoNotesMediaPermission(false));
            showToast(t("ToastForPermissionGranted", { ns: "VideoNote" }));
            trackEvent({
              eventType: eventTypes.success.AUTO_NOTES_PERMISSION,
            });
          } else if (isGettingSTTMediaPermissionRef.current) {
            dispatch(setIsGettingSTTMediaPermission(false));
            showToast(t("ToastForPermissionGranted", { ns: "VideoNote" }));
            trackEvent({
              eventType: eventTypes.success.SMART_LIVETEXT_PERMISSION,
            });
          } else if (isGettingClipRecordingMediaPermissionRef.current) {
            alertExtensionMediaPermissionGranted(<PermissionGrantedAlertMessage />);
            trackEvent({
              eventType: eventTypes.success.VIDEO_SNIPPET_PERMISSION,
            });
            dispatch(setIsGettingClipRecordingMediaPermission(false));
          }
          dispatch(setIsExtensionMediaPermitted(true));
          dispatch(setShowExtensionMediaPermissionModal(false));
          dispatch(setIsGettingExtensionMediaPermission(false));
        }
      },
    });

    setCheckIsExtensionMediaPermittedResponseListener({
      responseHandler: async ({ isExtensionMediaPermitted }) => {
        dispatch(setIsExtensionMediaPermitted(isExtensionMediaPermitted));
      },
    });
  }, []);

  const onClickCaptureButton = () => {
    manualCapture();
  };

  const onClickSettingButton = (e) => {
    e.stopPropagation();
    trackEvent({
      eventType: "Click capture setting button",
    });
    sendResetCaptureAreaForOneClickCaptureRequestToParentWindow();
  };

  const onClickSnipCaptureButton = () => {
    showSelectAreaPopup();
  };

  return (
    <CaptureButtonContainer>
      <SnipCaptureButtonUI onClick={onClickSnipCaptureButton} />
      <OneClickCaptureButtonUI onClickCaptureButton={onClickCaptureButton} onClickSettingButton={onClickSettingButton} />
      <VideoRecordButton />
      {isAutoNotesSupported && currentVideo?.videoType === "youtube" && <AutoNotesVideoNoteButton />}
      {isRealTimeTranscriptSupported && <WhisperRealTimeSmartLiveTextVideoNoteButton />}
      {isWhisperAutoNotesSupported && !isRealTimeTranscriptSupported && <WhisperAutoNotesMainUI />}
    </CaptureButtonContainer>
  );
};

export default ExtensionFeatureButtons;
