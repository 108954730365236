// auth 2.0
import packageJson from "../../../package.json";
import env from "config/env";

export const sendReadyToExitSlidToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_readyToExitSlid",
    data: {},
  });
};

export const sendUserIsGettingExtensionMediaPermission = (shouldGetPermission) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_userNeedsSTTPermission",
    data: {
      shouldGetPermission: shouldGetPermission,
    },
  });
};

export const sendIsSTTSessionStartedToParentWindow = (isSTTSessionStarted) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_IsSTTSessionStarted",
    data: {
      isSTTSessionStarted: isSTTSessionStarted,
    },
  });
};

export const sendDisplayVideoWithEditorRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_displayVideo",
    data: {},
  });
};

export const sendChromeExtensionVersionRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_checkExtensionVersion",
    data: {},
  });
};

export const sendCheckIsExtensionMediaPermitted = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_checkIsExtensionMediaPermitted",
    data: {},
  });
};

export const sendWebVersionToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendWebVersion",
    data: {
      webVersion: packageJson.version,
    },
  });
};

export const sendVideoInfoRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_getVideoInfo",
    data: {},
  });
};

export const sendVideoMetadataRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_getVideoMetadata",
    data: {},
  });
};

export const sendVideoTimestampRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_getCurrentVideoTimestamp",
    data: {},
  });
};

export const sendVideoPlaceholderPositionToParentWindow = ({ videoPlaceholderPosition }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendVideoRect",
    data: {
      videoRect: {
        top: videoPlaceholderPosition.top,
        left: videoPlaceholderPosition.left,
        width: videoPlaceholderPosition.width,
        height: videoPlaceholderPosition.height,
      },
    },
  });
};

export const sendScreenCaptureRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_captureCurrentArea",
    data: {},
  });
};

export const sendOneClickCaptureRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_oneClickCaptureCurrentArea",
    data: {},
  });
};

export const sendSetCaptureAreaRequestToParentWindow = (isCaptureAreaActive) => {
  sendMessageToParentContent({
    action: isCaptureAreaActive ? "IFRAME_TO_CONTENT_setCaptureArea" : "IFRAME_TO_CONTENT_removeCaptureArea",
    data: {},
  });
};

export const sendSetSnipCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setCaptureAreaForSnipCapture",
    data: {},
  });
};

export const sendRemoveSnipCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_removeCaptureAreaForSnipCapture",
    data: {},
  });
};

export const sendSetFocusOnIframeRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setFocusOnIframe",
    data: {},
  });
};

export const sendCancelCaptureAreaRequestToParentWindow = (isCaptureAreaActive) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_cancelCaptureArea",
    data: {},
  });
};

export const sendSetOneClickCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setCaptureAreaForOneClickCapture",
    data: {},
  });
};

export const sendApplyCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setCurrentCaptureArea",
    data: {},
  });
};

export const LEGACY_sendResetCaptureAreaRequestToParentWindow = ({ videoPlaceholderPosition }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendVideoRect",
    data: {
      videoRect: {
        top: videoPlaceholderPosition.top,
        left: videoPlaceholderPosition.left,
        width: videoPlaceholderPosition.width,
        height: videoPlaceholderPosition.height,
      },
    },
  });
};

export const sendResetCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_resetCaptureArea",
    data: {},
  });
};

export const sendResetCaptureAreaForOneClickCaptureRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_resetCaptureAreaForOneClickCapture",
    data: {},
  });
};

export const sendVideoDownloadUrlRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_getVideoDownloadUrl",
    data: {},
  });
};

export const sendVideoControlRequestToParentWindow = ({ type, value }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_controlVideo",
    data: {
      type: type,
      value: value,
    },
  });
};

export const sendVideoSeekToTimestampRequestToParentWindow = ({ clipTs }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_seekToTimestamp",
    data: {
      timestamp: clipTs,
    },
  });
};

export const sendStartRecordClipRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_StartRecordClip",
    data: {
      audioBitsPerSecond: 64000,
      videoWidth: 1280,
      videoHeight: 720,
      videoFrameRate: 24,
    },
  });
};

export const sendStopRecordClipRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_StopRecordClip",
    data: {},
  });
};

export const sendWakeUpRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_wakeUpOffscreen",
    data: {},
  });
};

export const sendStartSTTRequestToParentWindow = (transcriptLanguage, isAutoNotesSTT = false) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_StartAudioTranscript",
    data: {
      transcriptLanguage: transcriptLanguage,
      isAutoNotesSTT: isAutoNotesSTT,
    },
  });
};

export const sendRestartSTTRequestToParentWindow = (transcriptLanguage, isAutoNotesSTT = false) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_RestartAudioTranscript",
    data: {
      transcriptLanguage: transcriptLanguage,
      isAutoNotesSTT: isAutoNotesSTT,
    },
  });
};

export const sendStartNewSTTRequestToParentWindow = (documentKey = "", isWhisperAutoNotesSTT = false) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_StartNewAudioTranscript",
    data: {
      documentKey: documentKey,
      isWhisperAutoNotesSTT: isWhisperAutoNotesSTT,
    },
  });
};

export const sendStopSTTRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_StopAudioTranscript",
    data: {},
  });
};
export const sendSplitOnDragStartRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_SplitOnDragStart",
    data: {},
  });
};

export const sendSplitOnDragEndRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_SplitOnDragEnd",
    data: {},
  });
};

export const sendHideVideoRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendVideoRect",
    data: {
      videoRect: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
    },
  });
};

const sendMessageToParentContent = ({ action, data }) => {
  window.parent.postMessage(
    {
      action,
      data,
    },
    "*"
  );
};

export const sendMessageFromWebToExtension = async ({ action, data, responseHandler }) => {
  await window.chrome.runtime.sendMessage(env.end_point_url.extension_id, { action, data }, responseHandler);
};

export const sendHideExtensionViewerButton = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_HideViewerButton",
    data: {},
  });
};

export const sendShowExtensionViewerButton = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_ShowViewerButton",
    data: {},
  });
};

export const sendActivatePreviousTab = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_activatePreviousTab",
    data: {},
  });
};

export const sendReLocatateExtensionViewerButton = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_relocateViewerButton",
    data: {},
  });
};

export const sendGoBackExtensionViewerButton = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_goBackViewerButton",
    data: {},
  });
};
