// auth 2.0
import { Typography15, Typography13, Button, Icon, Typography24, useModal } from "@slid/slid-ips";
import React, { useCallback, useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import styled from "styled-components";
import { sendMessageToPrimary } from "utils/utils";
import loading_animation from "components/animations/loading_animation.json";
import Lottie from "react-lottie";
import { trackEvent } from "utils/eventTracking";
import { setIsDesktopCaptureAreaSet, setIsDesktopCaptureAreaSetting } from "redux/actions/vdocsActions";
import ModalPortal from "./ModalPortal";
import BaseModalContainer from "./BaseModalContainer";
import { useModalStore } from "store/useModalStore";

const SelectWindowModal = memo(() => {
  const { hideModal } = useModalStore();
  const { t } = useTranslation("Desktop");
  const dispatch = useAppDispatch();
  const [clickedProcessIndex, setClickedProcessIndex] = useState<number | null>(null);
  const [selectedAppName, setSelectedAppName] = useState<string | null>();
  const { iframePort, selectableProcesses } = useAppSelector((state) => state.vdocs);
  const { desktopVersion } = useAppSelector((state) => state.slidGlobal);

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loading_animation,
    renderer: "svg",
  };

  const updateSelectableProcess = useCallback(() => {
    sendMessageToPrimary({
      message: { type: "IFRAME_TO_PRIMARY_SELECT_PROCESS", payload: null },
      port: iframePort,
    });
  }, [iframePort]);

  const showCannotFindWindow = useCallback(() => {
    trackEvent({ eventType: "Click CANNOT FIND APP in app select modal" });
    sendMessageToPrimary({
      message: { type: "IFRAME_TO_PRIMARY_NO_PREFER_PROCESS", payload: null },
      port: iframePort,
    });
  }, [iframePort]);

  useEffect(() => {
    updateSelectableProcess();
  }, [updateSelectableProcess]);

  const getCapturableWindowList = () => {
    const CapturableWindows = selectableProcesses.map((item: any, index: number) => {
      if (item.name.toLowerCase().includes(desktopVersion)) return null;
      if (!item.thumbnail?.split(",")[1]) return null;
      return (
        <CapturableWindowItem key={index}>
          <CapturableWindowScreenContainer
            isSelectedWindow={clickedProcessIndex === index}
            onClick={() => {
              trackEvent({
                eventType: "Click APP TO CAPTURE in app select modal",
              });
              setSelectedAppName(item.name);
              setClickedProcessIndex(index);
            }}
          >
            <CapturableWindowScreen src={item.thumbnail} alt="" />

            {clickedProcessIndex === index && (
              <SelectedWindowContainer>
                <CheckIcon icon={`check24`} color="--white" />
              </SelectedWindowContainer>
            )}
          </CapturableWindowScreenContainer>

          <CapturableWindowInfo>
            {item.appIcon?.split(",")[1] ? (
              <CapturableWindowIcon src={item.appIcon} alt="" />
            ) : (
              <CapturableWindowNoIconContainer>
                <img src="/src/icons/icon_no_logo_12.svg" alt="" />
              </CapturableWindowNoIconContainer>
            )}

            <ProcessName weight={400} color={clickedProcessIndex === index ? "--blue8" : "--gray17"} text={item.name} />
          </CapturableWindowInfo>
        </CapturableWindowItem>
      );
    });
    CapturableWindows.push(
      <NoCapturableWindowContainer key={selectableProcesses.length} onClick={() => showCannotFindWindow()}>
        <img src="/src/icons/icon_add_window_24.svg" alt="" />
        <Typography13 weight={400} color="--gray7" text={t("SelectWindowModalNoAppText")} textAlign="center" marginTop="4px" />
      </NoCapturableWindowContainer>
    );
    return CapturableWindows;
  };

  return (
    <ModalPortal target="body">
      <BaseModalContainer closable={false}>
        <ModalContainer>
          <Typography24 weight={700} color="--gray17" text={t("SelectWindowModalTitle")} />
          <Typography15 weight={400} color="--gray9" text={t("SelectWindowModalText")} marginTop="12px" />

          {selectableProcesses ? (
            <CapturableWindowListContainer>{getCapturableWindowList()}</CapturableWindowListContainer>
          ) : (
            <LoadingWrapper>
              <Lottie options={defaultLottieOptions} height={80} width={80} />
              <Typography15 text={t("SelectWindowModalLoadingText")} color="--gray17" weight={700} marginTop="32px" />
            </LoadingWrapper>
          )}
          <ButtonContainer>
            <Button
              appearance="secondary"
              size="medium"
              text={t("SelectWindowModalCancelButton")}
              callback={() => {
                trackEvent({ eventType: "Click CANCEL in app select modal" });
                sendMessageToPrimary({
                  message: { type: "IFRAME_TO_PRIMARY_clickCancelInSelectWindowModal", payload: null },
                  port: iframePort,
                }); // To show capture area again
                hideModal();
              }}
            />
            <Button
              appearance="primary"
              size="medium"
              text={t("SelectWindowModalConfirmButton")}
              isDisabled={clickedProcessIndex !== null && clickedProcessIndex + 1 ? false : true}
              callback={() => {
                trackEvent({ eventType: "Click SELECT in app select modal", eventProperties: { "app name": selectedAppName } });
                sendMessageToPrimary({
                  message: {
                    type: "IFRAME_TO_PRIMARY_SEND_SELECTED_PROCESS",
                    payload: selectableProcesses[clickedProcessIndex as number],
                  },
                  port: iframePort,
                });
                dispatch(setIsDesktopCaptureAreaSetting(true));
                dispatch(setIsDesktopCaptureAreaSet(true));

                hideModal();
              }}
              style={{ width: "calc(100% - 206px)" }}
            />
            <IconButtonContainer
              onClick={() => {
                trackEvent({ eventType: "Click REFRESH in app select modal" });
                updateSelectableProcess();
                setSelectedAppName(null);
                setClickedProcessIndex(null);
              }}
            >
              <img src="/src/icons/icon_restart.svg" alt="" />
            </IconButtonContainer>
          </ButtonContainer>
        </ModalContainer>
      </BaseModalContainer>
    </ModalPortal>
  );
});

export default SelectWindowModal;

interface ModalStyleProps {
  isSelectedWindow?: boolean;
}

const ModalContainer = styled.div`
  width: 452px;
  height: 598px;
  background-color: var(--gray1);
  border-radius: 8px;
  padding: 36px 24px 0px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 99999;
`;

const CapturableWindowListContainer = styled.div`
  max-height: calc(100% - 180px);
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 4px;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray4);
    border-radius: 4px;
  }
`;

const CapturableWindowItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 28px;
`;

const CapturableWindowScreenContainer = styled.div<ModalStyleProps>`
  position: relative;
  width: 190px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--gray4);
  border-radius: 8px;
  border: ${({ isSelectedWindow }) => (isSelectedWindow ? "2px solid var(--blue8)" : "2px solid var(--gray3)")};
  cursor: pointer;

  :hover {
    background: linear-gradient(180deg, rgba(33, 37, 41, 0) 0%, rgba(33, 37, 41, 0.2) 100%);
    box-shadow: var(--boxShadow2);
    border: 2px solid var(--gray5);
  }
`;

const CapturableWindowScreen = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SelectedWindowContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(33, 37, 41, 0.3);
`;

const CheckIcon = styled(Icon)`
  && {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const CapturableWindowInfo = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const CapturableWindowIcon = styled.img`
  width: 24px;
  height: 24px;
  background-color: var(--gray3);
  border-radius: 8px;
`;

const CapturableWindowNoIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--gray3);
  border-radius: 8px;
`;

const ProcessName = styled(Typography15)`
  && {
    width: 158px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const NoCapturableWindowContainer = styled.div`
  width: 190px;
  height: 100px;
  background: var(--gray2);
  border: 1px solid var(--gray3);
  border-radius: 8px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  margin-bottom: 24px;

  :hover {
    background-color: var(--gray3);
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0px 0px 8px 8px;
  filter: drop-shadow(var(--boxShadow2));
  border-top: 1px solid var(--gray3);
  background-color: var(--gray1);
`;

const IconButtonContainer = styled.div`
  width: 49px;
  height: 49px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  :hover {
    background-color: var(--gray3);
  }
  :active {
    background-color: var(--gray2);
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;
