import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const InlineCodeIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 32} height={height ? height : 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="inline code">
        <path id="Vector 231" d="M13.5 10L10 16L13.5 22" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 232" d="M19 22L22.5 16L19 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </SvgWrapper>
  );
};
