import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as TextIcon } from "../../images/icons/text.svg";
import { ReactComponent as TrashcanIcon } from "../../images/icons/trashcan.svg";
import { Tooltip } from "@slid/slid-ips";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createTextNode,
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  BaseSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  NodeKey,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { mergeRegister } from "@lexical/utils";
import { createCommand, LexicalCommand } from "lexical";
import { $isSlidImageTextExtractorNode } from ".";
import { $createSlidParagraphNode } from "../SlidParagraphNode";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  nodeKey: NodeKey;
}

export const CONVERT_TO_PARAGRAPH_COMMAND: LexicalCommand<string> = createCommand();
export const FOCUS_IMAGE_TEXT_EXTRACTOR_COMMAND: LexicalCommand<void> = createCommand();

const SlidImageTextExtractorComponent = ({ text, nodeKey }: Props) => {
  const [showButton, setShowButton] = useState(false);
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const activeEditorRef = useRef<LexicalEditor | null>(null);

  const { t } = useTranslation("ImageNode");
  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(
        CONVERT_TO_PARAGRAPH_COMMAND,
        (payload) => {
          handleConvertToParagraph();
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor;
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        FOCUS_IMAGE_TEXT_EXTRACTOR_COMMAND,
        () => {
          const textExtractorNode = $getImageTextExtractorNodeInSelection();
          if (textExtractorNode) {
            const textExtractorNodeKey = textExtractorNode.getKey();
            const textExtractorNodeElement = editor.getElementByKey(textExtractorNodeKey);
            if (textExtractorNodeElement) {
              const input = textExtractorNodeElement.querySelector("input");
              if (input) {
                input.focus();
              }
            }
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );

    return () => {
      unregister();
    };
  }, [editor]);

  const $getImageTextExtractorNodeInSelection = () => {
    const selection = $getSelection();
    if (!$isNodeSelection(selection)) {
      return null;
    }
    const nodes = selection.getNodes();
    const node = nodes[0];
    return $isSlidImageTextExtractorNode(node) ? node : null;
  };

  const handleConvertToParagraph = () => {
    const textExtractorNode = $getImageTextExtractorNodeInSelection();
    if (!textExtractorNode) {
      return;
    }

    const text = textExtractorNode.getText();
    const paragraphNode = $createSlidParagraphNode();
    const textNode = $createTextNode(text);
    paragraphNode.append(textNode);
    textExtractorNode.replace(paragraphNode);
  };

  useEffect(() => {
    if (isSelected) {
      editor.dispatchCommand(FOCUS_IMAGE_TEXT_EXTRACTOR_COMMAND, undefined);
    }
  }, [isSelected, editor]);

  const handleTextConvert = () => {
    let contentText = escapeHtml(text);
    function escapeHtml(unsafe) {
      return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
    }

    // keep break line
    // keep space
    // keep tab
    contentText = contentText.replaceAll("\n", "<br/>").replaceAll(" ", "&nbsp;").replaceAll("	", "&#009;");

    editor.update(() => {
      const textExtractorNode = $getNodeByKey(nodeKey);
      const slidParagraphNode = $createSlidParagraphNode();
      const textNode = $createTextNode(text);

      slidParagraphNode.append(textNode);
      textExtractorNode?.replace(slidParagraphNode);
    });
  };

  const handleDelete = () => {
    editor.update(() => {
      const textExtractorNode = $getNodeByKey(nodeKey);
      textExtractorNode?.remove();
    });
  };

  return (
    <Container onMouseEnter={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}>
      <ButtonContainer showButton={showButton}>
        <Tooltip title={t("text_extraction_convert")}>
          <TextConvertButton onClick={handleTextConvert}>
            <TextIcon />
          </TextConvertButton>
        </Tooltip>
        <Tooltip title={t("text_extraction_delete")}>
          <DeleteButton onClick={handleDelete}>
            <TrashcanIcon />
          </DeleteButton>
        </Tooltip>
      </ButtonContainer>
      <TextArea defaultValue={text} isSelected={isSelected} ref={textAreaRef} onFocus={() => setSelected(true)} onBlur={() => setSelected(false)} />
    </Container>
  );
};

const Container = styled.div`
  margin: 14px 0;
  position: relative;
`;

const ButtonContainer = styled.div<{ showButton: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  align-items: center;
  justify-content: center;
  display: ${({ showButton }) => (showButton ? "flex" : "none")};
`;

const ButtonItem = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  &:focus {
    outline: 0;
  }
  /* e of reset button  */

  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 37, 41, 0.8);
  cursor: pointer;
  border-right: 1px solid var(--gray9);

  svg {
    overflow: visible !important;
  }

  &:first-child {
  }

  &:last-child {
  }

  &:hover {
    background-color: rgba(33, 37, 41, 0.9);
  }
`;

const TextConvertButton = styled(ButtonItem)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const DeleteButton = styled(ButtonItem)`
  border-right: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const TextArea = styled.textarea<{ isSelected: boolean }>`
  min-height: 150px;
  width: 100%;
  font-family:
    Menlo,
    Monaco,
    Consolas,
    Courier New,
    monospace;
  color: var(--gray15);
  line-height: 1.6;
  font-size: 1.3rem;
  background: var(--gray1);
  box-shadow: none;
  white-space: inherit;
  word-wrap: normal;
  overflow-x: auto;
  resize: vertical;
  outline: ${({ isSelected }) => (isSelected ? "2px solid var(--blue7)" : "1px solid var(--gray4)")};
  border: none;
  border-radius: 4px;
`;

export default SlidImageTextExtractorComponent;
