import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const MarkerIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 32} height={height ? height : 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="4" fill={color === "#fff" || color === "#ffffff" ? "transparent" : color} />
      <path
        d="M13.9565 22H10.5652C10.4153 22 10.2715 21.9405 10.1655 21.8345C10.0595 21.7285 10 21.5847 10 21.4348V18.2774C10.0001 18.1277 10.0595 17.9841 10.1653 17.8782L18.8781 9.16542C18.9841 9.0595 19.1278 9 19.2776 9C19.4275 9 19.5712 9.0595 19.6772 9.16542L22.8346 12.3207C22.9405 12.4267 23 12.5704 23 12.7203C23 12.8701 22.9405 13.0138 22.8346 13.1198L13.9565 22Z"
        stroke="#212529"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23 22H11" stroke="#212529" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 12L20 15" stroke="#212529" strokeLinecap="round" strokeLinejoin="round" />
    </SvgWrapper>
  );
};
