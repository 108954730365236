import { Typography14, Typography15 } from "@slid/slid-ips";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as RecentNoteIcon } from "../images/icons/recent-note.svg";
import { ReactComponent as ImportPDFIcon } from "../images/icons/import-pdf.svg";
import useEditorStore from "store/useEditorStore";
import usePlaceholder from "../hooks/usePlaceholder";
import { $getRoot } from "lexical";
import useEditorPDFUpload from "hooks/useEditorPDFUpload";

const PCEditorPlaceholder = () => {
  const { t } = useTranslation("EditorPlaceholder");
  const { showEditorPlaceholder, lexicalEditorRef, setPlaceholderVisibilityBasedOnContent, currentDocumentKey, isFetchingDocument } = useEditorStore();
  const { handlePlaceholderClick, handleClickRecentNote } = usePlaceholder();
  const { handleClickPDFUpload } = useEditorPDFUpload();

  useEffect(() => {
    const checkIfEditorIsEmpty = () => {
      let isEmpty = false;
      lexicalEditorRef.current?.read(() => {
        const root = $getRoot();
        const firstChildText = root.getFirstChild()?.getTextContent();
        const firstChildType = root.getFirstChild()?.getType();
        isEmpty = (firstChildType === "slid-paragraph" && root.getChildrenSize() === 1 && firstChildText === "") || (!firstChildText && !firstChildType);
      });
      return isEmpty;
    };

    if (!isFetchingDocument) {
      const isEmpty = checkIfEditorIsEmpty();
      setPlaceholderVisibilityBasedOnContent(!isEmpty);
    }
  }, [currentDocumentKey, isFetchingDocument]);

  if (!showEditorPlaceholder) return null;
  return (
    <Container onClick={() => handlePlaceholderClick()}>
      <Typography15 text={t("PCEditorPlaceholder")} color="--gray10" />
      <ButtonWrapper>
        <PlaceholderButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClickRecentNote();
          }}
        >
          <RecentNoteIcon />
          <Typography14 text={t("OpenRecentNote")} color="--gray10" />
        </PlaceholderButton>
        <PlaceholderButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClickPDFUpload();
          }}
        >
          <ImportPDFIcon />
          <Typography14 text={t("ImportPDF")} color="--gray10" />
        </PlaceholderButton>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 28px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const PlaceholderButton = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  /* e of reset button  */

  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: var(--gray1);
  cursor: pointer;

  &:hover {
    background: var(--gray4);
  }

  &:active {
    background: var(--gray3);
  }
`;

export default PCEditorPlaceholder;
