import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { addSTTData, setActiveSTTNoteBlock, setCurrentSTTData, setProcessedSTTData } from "redux/actions/sttActions";
import { useUpdateEffect } from "utils/customHook/customHook";
import { TranscriptBlock } from "types/smartLiveText";

import { split } from "sentence-splitter";
import { wordsCount } from "words-count";
import useEditorStore from "store/useEditorStore";
import { $createTextNode, $getNodeByKey, $getSelection } from "lexical";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { setEditorLastActiveBlockPosition } from "redux/actions/vdocsActions";
import usePlaceholder from "components/NewEditor/hooks/usePlaceholder";

const BLOCK_MAX_WORDS = 60;
const BLOCK_MAX_CHARACTERS = 150; // or 200? for asian languages
const LANGUAGES_TO_SPLIT = ["English"];

const getMaxBlockSize = (language: string): number => {
  const characterBasedLanguages = ["한국어", "中文", "हिन्दी", "日本語"];
  const wordBasedLanguages = ["English", "Español", "Français", "Português", "Deutsch"];

  if (characterBasedLanguages.includes(language)) {
    return BLOCK_MAX_CHARACTERS;
  } else if (wordBasedLanguages.includes(language)) {
    return BLOCK_MAX_WORDS;
  } else {
    return BLOCK_MAX_WORDS;
  }
};

const shouldSplit = (language: string): boolean => {
  return LANGUAGES_TO_SPLIT.includes(language);
};

const useSLTData = () => {
  const dispatch = useAppDispatch();
  const { smartLiveTextLanguage, isSTTToggledOn, isManualAddingMode, isSTTActive, currentSTTData, processedSTTData } = useAppSelector((state) => state.sttReducer);
  const currentDocument = useAppSelector((state) => state.vdocs.currentDocument);
  const lexicalEditorRef = useEditorStore((state) => state.lexicalEditorRef);
  const showEditorPlaceholder = useEditorStore((state) => state.showEditorPlaceholder);
  const showEditorPlaceholderRef = useRef(showEditorPlaceholder);
  const { handlePlaceholderClick } = usePlaceholder();

  const splitTranscriptIntoBlocks = (currentSTTData: TranscriptBlock) => {
    if (!shouldSplit(smartLiveTextLanguage)) return [currentSTTData];

    let blocks: TranscriptBlock[] = [];
    const sentences = split(currentSTTData.text);

    const addSentenceToLastBlock = (sentence: string) => {
      const lastBlockIndex = blocks.length - 1;
      blocks[lastBlockIndex] = {
        ...blocks[lastBlockIndex],
        text: blocks[lastBlockIndex].text + sentence,
      };
    };

    const createNewBlock = (sentence: string, isTyping = false) => {
      if (sentence.trim() === "") return;
      blocks.push({ text: sentence, isFinal: false, isTyping });
    };

    sentences.forEach((sentence) => {
      const sentenceWordCount = wordsCount(sentence.raw);
      const canAddToLastBlock = blocks.length > 0 && wordsCount(blocks[blocks.length - 1].text) + sentenceWordCount <= getMaxBlockSize(smartLiveTextLanguage);
      if (canAddToLastBlock) {
        addSentenceToLastBlock(sentence.raw);
      } else {
        createNewBlock(sentence.raw);
      }
    });

    if (blocks.length > 0) {
      blocks[blocks.length - 1].isTyping = currentSTTData.isTyping;
    }

    return blocks;
  };

  const insertSLTResult = async (data) => {
    if (!currentDocument) {
      await handlePlaceholderClick();
    }
    lexicalEditorRef.current?.update(() => {
      data.forEach((item, index) => {
        const paragraphNode = $createSlidParagraphNode();
        const textNode = $createTextNode(item.text);
        paragraphNode.append(textNode);
        const currentNodeKey = $getSelection()?.getNodes()[0]?.getKey();

        if (currentNodeKey === null || currentNodeKey === undefined) {
          $insertNodeToNearestRoot(paragraphNode);
        } else {
          const currentNode = $getNodeByKey(currentNodeKey);
          const currentNodeType = currentNode?.getType();
          const isCurrentNodeEmpty = currentNode?.getTextContent() === "";
          if (isCurrentNodeEmpty && currentNodeType && ["paragraph", "slid-paragraph", "list", "list-item"].includes(currentNodeType)) {
            currentNode.replace(paragraphNode);
            const newParagraphNode = $createSlidParagraphNode();
            paragraphNode.insertAfter(newParagraphNode);
            const paragraphNodeKey = paragraphNode.getKey();
            dispatch(setEditorLastActiveBlockPosition(paragraphNodeKey));
            newParagraphNode.select();
          } else {
            $insertNodeToNearestRoot(paragraphNode);
          }
        }
      });
    });
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveSTTNoteBlock(null));
    };
  }, []);

  useUpdateEffect(() => {
    showEditorPlaceholderRef.current = showEditorPlaceholder;
  }, [showEditorPlaceholder]);

  useUpdateEffect(() => {
    // When user changes to manual mode or stops stt, insert STT blocks to editor
    const shouldConvertAllSTTBlocks = (!isSTTToggledOn && !isManualAddingMode) || (isSTTToggledOn && isManualAddingMode);

    if (!shouldConvertAllSTTBlocks || processedSTTData.length === 0) return;
    insertSLTResult(processedSTTData);
  }, [isManualAddingMode, isSTTToggledOn]);

  useUpdateEffect(() => {
    if (!currentSTTData || currentSTTData.length === 0) {
      dispatch(setProcessedSTTData([]));
      return;
    }
    const processedSTTData = splitTranscriptIntoBlocks(currentSTTData);
    if (currentSTTData.isFinal) {
      dispatch(addSTTData(processedSTTData));
      if (!isManualAddingMode) {
        insertSLTResult(processedSTTData);
      }

      return;
    }
    dispatch(setProcessedSTTData(processedSTTData));
  }, [currentSTTData, isManualAddingMode]);

  useEffect(() => {
    if (isSTTActive || processedSTTData.length === 0 || !isSTTToggledOn) return;

    if (!isManualAddingMode) {
      insertSLTResult(processedSTTData);
    }
    dispatch(setCurrentSTTData(null));
    dispatch(setProcessedSTTData([]));
  }, [isSTTActive, processedSTTData, isManualAddingMode, isSTTToggledOn]);
};

export default useSLTData;
