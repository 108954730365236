import { useEffect, useState } from "react";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import useEditorStore from "store/useEditorStore";
import { getCurrentDocumentKey } from "utils/editor/util";
import { $createTextNode, $getRoot } from "lexical";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { $insertNodeToNearestRoot } from "@lexical/utils";

const useSLTData = () => {
  const { isManualAddingMode, transcriptData } = useWhisperSLTStore();
  const lexicalEditorRef = useEditorStore((state) => state.lexicalEditorRef);
  const setPlaceholderVisibilityBasedOnContent = useEditorStore((state) => state.setPlaceholderVisibilityBasedOnContent);

  const [insertedIds, setInsertedIds] = useState(new Set());

  useEffect(() => {
    if (isManualAddingMode || !transcriptData.length || !lexicalEditorRef.current) return;

    const insertTranscriptData = async () => {
      setPlaceholderVisibilityBasedOnContent(true);

      const currentDocumentKey = await getCurrentDocumentKey();
      if (!currentDocumentKey) return false;

      if (transcriptData.length > 0) {
        const lastData = transcriptData[transcriptData.length - 1];
        if (!insertedIds.has(lastData.id)) {
          lexicalEditorRef.current?.update(() => {
            const root = $getRoot();
            const lastChild = root.getLastChild();

            const paragraphNode = $createSlidParagraphNode();
            const textNode = $createTextNode(lastData.text);
            paragraphNode.append(textNode);

            if (lastChild) {
              const isLastChildParagraphNode = lastChild.getType() === "slid-paragraph";
              const isLastChildEmpty = lastChild.getTextContent() === "";
              if (isLastChildParagraphNode && isLastChildEmpty) {
                lastChild.replace(paragraphNode);
              } else {
                lastChild.insertAfter(paragraphNode);
              }
              const newParagraphNode = $createSlidParagraphNode();
              paragraphNode.insertAfter(newParagraphNode);
              newParagraphNode.select();
            } else {
              $insertNodeToNearestRoot(paragraphNode);
            }
          });

          setInsertedIds(new Set(Array.from(insertedIds).concat(lastData.id)));
        }
      }
    };

    insertTranscriptData();
  }, [transcriptData, isManualAddingMode]);
};

export default useSLTData;
