import { $applyNodeReplacement, DecoratorNode, DOMExportOutput, type EditorConfig, LexicalNode, NodeKey, SerializedParagraphNode, Spread } from "lexical";
import React from "react";
import SlidImageTextExtractorComponent from "./SlidImageTextExtractorComponent";

export type SerializedSlidImageTextExtractorNode = Spread<
  {
    text: string;
    key?: NodeKey;
  },
  SerializedParagraphNode
>;

export class SlidImageTextExtractorNode extends DecoratorNode<JSX.Element> {
  __text: string;

  constructor(text: string, key?: NodeKey) {
    super(key);
    this.__text = text;
  }

  static getType() {
    return "slid-image-text-extractor";
  }

  static clone(node: SlidImageTextExtractorNode) {
    return new SlidImageTextExtractorNode(node.__text, node.__key);
  }

  static importJSON(serializedNode: SerializedSlidImageTextExtractorNode) {
    const node = $createSlidImageTextExtractorNode({ text: serializedNode.text });
    return node;
  }

  exportJSON() {
    return { type: "slid-image-text-extractor", text: this.__text, version: 1 };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");
    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    return { element };
  }

  updateDOM(prevNode: SlidImageTextExtractorNode, dom: HTMLElement): boolean {
    return false;
  }

  isInline() {
    return false;
  }

  canBeEmpty() {
    return true;
  }

  setText(text: string) {
    const writable = this.getWritable();
    writable.__text = text;
  }

  getText() {
    return this.__text;
  }

  decorate(): JSX.Element {
    return <SlidImageTextExtractorComponent text={this.__text} nodeKey={this.__key} />;
  }
}

export function $createSlidImageTextExtractorNode({ text }: { text: string }) {
  const textExtractorNode = new SlidImageTextExtractorNode(text);
  textExtractorNode.setText(text);
  return $applyNodeReplacement(textExtractorNode);
}

export function $isSlidImageTextExtractorNode(node: LexicalNode | null | undefined): node is SlidImageTextExtractorNode {
  return node instanceof SlidImageTextExtractorNode;
}
