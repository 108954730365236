import styled from "styled-components";

export const CaptureButtonContainer = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 4px 0;
  z-index: 1;
`;

export const ButtonText = styled.span`
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--gray17);
`;

export const CaptureButton = styled.div`
  // S of reset button
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding: 0;
  position: relative;

  &:focus {
    outline: 0;
    border: none;
  }
  // E of reset button

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 84px;
  height: 52px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }

  &:active {
    background-color: var(--gray3);
  }
`;
