import { Icon } from "@slid/slid-ips";
import { Tooltip } from "@slid/slid-ips";
import React from "react";
import { isMacOs } from "react-device-detect";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SnipCaptureButtonUI = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement={`top`} title={t("SnipCapture", { ns: "VideoCaptureButton" })} description={isMacOs ? "(Option + A)" : "(Alt + A)"}>
      <CaptureButton onClick={onClick}>
        <Icon icon={`snip28`} color={`--gray17`} />
        <ButtonText>{t("Snip", { ns: "VideoNote" })}</ButtonText>
      </CaptureButton>
    </Tooltip>
  );
};

const CaptureButton = styled.div`
  // S of reset button
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding: 0;
  position: relative;

  &:focus {
    outline: 0;
    border: none;
  }
  // E of reset button

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 84px;
  height: 52px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }

  &:active {
    background-color: var(--gray3);
  }
`;

const ButtonText = styled.span`
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--gray17);
`;

export default SnipCaptureButtonUI;
