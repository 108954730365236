import { NodeKey, type LexicalNode, type Spread } from "lexical";
import { HeadingNode, SerializedHeadingNode } from "@lexical/rich-text";
import { type HeadingTagType } from "@lexical/rich-text";

export type LegacySlidHeading = {
  type: "header1" | "header2" | "header3";
  id: string;
  data: {
    text: string;
    level: number;
  };
};
export type SerializedSlidHeadingNode = Spread<LegacySlidHeading, SerializedHeadingNode>;

export class SlidHeadingNode extends HeadingNode {
  constructor(tag: HeadingTagType = "h1", key?: NodeKey) {
    super(tag, key);
  }

  static getType(): string {
    return "slid-heading";
  }

  static clone(node: SlidHeadingNode) {
    return new SlidHeadingNode(node.__tag, node.__key);
  }

  static importJSON(serializedNode: SerializedSlidHeadingNode): HeadingNode {
    const { tag, data } = serializedNode;
    const node = $createSlidHeadingNode(tag);
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      tag: this.getTag(),
      type: "slid-heading",
      version: 1,
    };
  }
}

export function $createSlidHeadingNode(tag = "h1"): SlidHeadingNode {
  return new SlidHeadingNode(tag as HeadingTagType);
}

export function $isSlidHeadingNode(node: LexicalNode | null | undefined): node is SlidHeadingNode {
  return node instanceof SlidHeadingNode;
}
