import { create } from "zustand";

interface VideoRecordStore {
  videoLoaderNodeKeys: string[];
  setVideoLoaderNodeKeys: (videoLoaderNodeKeys: string[]) => void;
}

export const useVideoRecordStore = create<VideoRecordStore>((set) => ({
  videoLoaderNodeKeys: [],
  setVideoLoaderNodeKeys: (videoLoaderNodeKeys) => set({ videoLoaderNodeKeys }),
}));
