// auth 2.0
import React, { useEffect, useRef, useState } from "react";
import "./VideoDocumentEditor.css";
import EditorNoteLoadingScreen from "components/editorLoadingStates/EditorNoteLoadingScreen";
import PCEditor from "components/NewEditor/PCEditor";
import styled, { css } from "styled-components";

import DocumentSearch from "../../popups/DocumentSearch";
import DocumentShare from "../../popups/DocumentShare";
import DeletedDocuments from "../../popups/DeletedDocuments";
import DocumentListView from "../../listItem/DocumentListView";
import {
  setIsListOpen,
  setIsHelpMenuOpen,
  setShowSearchPopup,
  setShowSharePopup,
  setShowDeletedDocumentsPopup,
  setEditorWrapperClassName,
  setShowDisableMediaRecordingModal,
  setCaptureRect,
  setIsCaptureAreaSetForOneClickCapture,
  setIsGuestModeAlertShown,
  setIsSetCaptureAreaButtonClicked,
} from "redux/actions/vdocsActions";

import { useHistory, useLocation } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import { InputGroup, FormControl } from "react-bootstrap";
import Sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getEventLocationMode } from "utils/utils";
import { setCheckIsSlidInstantLaunchedListener, setIsExtensionClickedListener } from "utils/extensionInterface/setListenerFromExtension";
import { sendHideVideoRequestToParentWindow, sendVideoPlaceholderPositionToParentWindow } from "utils/extensionInterface/sendToExtension";
import * as Sentry from "@sentry/browser";
import { sendAmplitudeData } from "utils/amplitude";
import env from "config/env";
import emitter from "react-ab-test/lib/emitter";
import { trackEvent } from "utils/eventTracking";
import { fabric } from "fabric";
import { Icon, Button, useModal } from "@slid/slid-ips";
import HelpMenu from "components/DropdownMenus/HelpMenu";
import { useTranslation } from "react-i18next";
import { PopupContainer, PopupLayer } from "styles";
import { isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";
import DisableMediaRecordingModal from "../../popups/DisableMediaRecordingModal";
import { SliddyIcon } from "components/icons/SliddyIcon";
import SlidGPT from "components/SlidGPT/SlidGPT";
import { eventTypes } from "types/eventTracking";

import { UserMembership } from "types/globals";
import { VideoNoteStudyChallengeInfo } from "components/myNotes/StudyChallenge";
import VideoDocumentEditorHeader from "./VideoDocumentEditorHeader";
import AutoNotesMainUI from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesMainUI";
import RealTimeSmartLiveTextProgressInfoView from "components/SmartLiveTextWhisperRealTime/SmartLiveTextProgressInfo";
import RealTimeWhisperAutoNotesMainUI from "components/smartautonotes/AutoNoteVdocsComponents/RealTimeWhisperAutoNotesMainUI";
import { useAppDispatch, useAppSelector } from "hooks";
import { useChannelTalk } from "hooks/useChannelTalk";
import ImageCroppingModal from "components/NewImageCroppingModal/ImageCroppingModal";
import { useMediaQuery } from "react-responsive";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import VideoDocumentEditorBanner from "./VideoDocumentEditorBanner";
import VideoDocumentEditorSaveButton from "./VideoDocumentEditorSaveButton";
import PCEditorTitle from "components/NewEditor/components/PCEditorTitle";
import { useImageNodeStore } from "components/NewEditor/store/useImageNodeStore";
import ImageMarkup from "components/ImageMarkupModal";

import WebFeatureButtons from "components/VideoNoteFeatureButtons/WebFeatureButtons";
import ExtensionFeatureButtons from "components/VideoNoteFeatureButtons/ExtensionFeatureButtons";
import DesktopAppFeatureButtons from "components/VideoNoteFeatureButtons/DesktopAppFeatureButtons";
import useEditorStore from "store/useEditorStore";
import { OnboardingPromotionModal } from "../OnboardingPromotionModal";
import { showCustomModal } from "utils/modal";
import { getDefaultSettingCookie, setDefaultSettingCookie } from "utils/cookies/cookies";
import DesktopSmartLiveTextProgressInfoView from "components/DesktopSmartLiveText/DestkopSmartLiveTextProgressInfo";

export const ReactSweetalert = withReactContent(Sweetalert);
export const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

export let shouldRedrawArea;
export let mouseDownEventsToIgnore;
export let rect;
export let initX, initY;
export let mouseX, mouseY;
export let canvas;

const VideoDocumentEditor: React.FC = (props) => {
  const { bootChannelTalk } = useChannelTalk();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isSlidInstantLaunched, setIsSlidInstantLaunched] = useState(false);
  const [isHelpButtonHoveredOnce, setIsHelpButtonHoveredOnce] = useState(false);

  const { applicationType, lang, userData, screenOrientation } = useAppSelector((state) => state.slidGlobal);
  const {
    videoPlayerRef,
    isCaptureAreaSetForOneClickCapture,
    editorLastActiveBlockPosition,
    iframePort,
    showGuestModeAlert,
    captureRect,
    isListOpen,
    isHelpMenuOpen,
    showSearchPopup,
    showDeletedDocumentsPopup,
    showSharePopup,
    showDisableMediaRecordingModal,
    loaderBlockKeys,
    isDesktopCaptureAreaSet,
    isSTTSupported,
  } = useAppSelector((state) => state.vdocs);
  const { isAutoNotesToggledOn, isWhisperAutoNotesSupported } = useAppSelector((state) => state.autoNotes);
  const { modalOn } = useAppSelector((state) => state.modal);
  const { isRealTimeTranscriptSupported } = useWhisperSLTStore();
  const { showSliddyChat } = useAppSelector((state) => state.aiSliddy);
  const { showImageMarkupPopup, showImageCroppingModal } = useImageNodeStore();
  const { editorScrollWrapperRef } = useEditorStore();
  const { showModal, closeModal } = useModal();
  const history = useHistory();
  const searchPopupRef = useRef(null);
  const sharePopupRef = useRef(null);
  const disableMediaRecordingModalRef = useRef(null);
  const deletedDocumentsPopupRef = useRef(null);
  const listContainerRef = useRef(null);
  const desktopAuthLinkInputRef = useRef<HTMLInputElement | null>(null);
  const isDesktopCaptureAreaSetRef = useRef(false);

  const loaderBlockKeysRef = useRef(loaderBlockKeys);
  const videoPlayerRefRef = useRef(videoPlayerRef);

  const showGuideMessageForDesktopCaptureFailure = () => {
    ReactSweetalert.fire({
      icon: "info",
      target: ".video-document-container",
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: lang === "ko" ? "캡처 도중 오류" : "Selected app not available!",
      html: (
        <>
          {lang === "ko" ? (
            <p>
              앗! 선택된 프로그램을 찾을 수 없습니다. 😅
              <br />
              슬리드를 재시작해 프로그램을 다시 선택해주세요.
            </p>
          ) : (
            <p>Oops! There was an error capturing, restarting Slid may help.</p>
          )}
          <div className={`mt-3`}>
            <a
              href={`/#`}
              onClick={() => {
                bootChannelTalk();
              }}
            >
              {lang === "ko" ? <>도움이 필요하신가요?</> : <>Need help?</>}
            </a>
          </div>
        </>
      ),
      showConfirmButton: true,
      confirmButtonText: lang === "ko" ? "선택" : "Select",
      showCancelButton: true,
      cancelButtonText: lang === "ko" ? "닫기" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_LAUNCH_PROMPT",
          payload: null,
        });
      }
    });
  };

  const sendMessageToPrimary = (message) => {
    /*
    Sends messages to the primary window of Slid Desktop
    */
    if (iframePort) {
      iframePort.postMessage(message);
      return true;
    } else {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_DESKTOP_IFRAME_DID_NOT_RECEIVE_PORT");
      });
      showGuideMessageForDesktopCaptureFailure();
      return false;
    }
  };

  const DESKTOP_showGuestModeAlert = () => {
    Sweetalert.fire({
      target: ".video-document-container",
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: lang === "ko" ? "로그인을 먼저 해주세요!" : "Please sign-in!",
      html:
        lang === "ko"
          ? `
              아래 '로그인' 버튼을 누르면 <br/>
              브라우저에서 로그인 창이 열립니다.
          `
          : `
              Click the button below to sign-in. <br/>
              Sign-in page will be opened in the browser.
          `,
      icon: "info",
      confirmButtonText: lang === "ko" ? "로그인" : "Sign-in",
      showCancelButton: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_OPEN_URL",
          payload: `${SLID_WEB_APP_URL}/desktop_auth`,
        });
        ReactSweetalert.fire({
          target: ".video-document-container",
          heightAuto: false,
          customClass: {
            container: "position-absolute",
          },
          title: lang === "ko" ? "새 창에서 로그인을 해주세요!" : "Sign-in in the browser!",
          html: (
            <>
              <p>
                {lang === "ko" ? (
                  <>
                    브라우저에서 로그인창이 뜨지 않으면, <br />
                    아래 주소를 직접 복사해 크롬 주소창에 붙여넣어 주세요.
                  </>
                ) : (
                  <>
                    If sign-in page is not opened, <br />
                    copy and paste the URL to the Chrome.
                  </>
                )}
              </p>
              <div>
                <InputGroup>
                  <FormControl ref={desktopAuthLinkInputRef} value={`${SLID_WEB_APP_URL}/desktop_auth`} readOnly={true} />
                  <Button
                    text="Copy"
                    size="small"
                    appearance="primary"
                    callback={(event) => {
                      onClickCopyBtn(event.target);
                    }}
                  />
                </InputGroup>
              </div>
              <div className={`mt-3`}>
                <a
                  href={`/#`}
                  onClick={() => {
                    bootChannelTalk();
                  }}
                >
                  {lang === "ko" ? <>도움이 필요하신가요?</> : <>Need help?</>}
                </a>
              </div>
            </>
          ),
          showConfirmButton: false,
          allowOutsideClick: false,
        });
      }
    });
  };

  const onClickCopyBtn = (buttonElement) => {
    if (!desktopAuthLinkInputRef.current) return;
    try {
      // copy to clipboard
      desktopAuthLinkInputRef.current.select();
      document.execCommand("copy");
      // Update copy button
      const copiedText = "Copied!";
      const originalText = buttonElement.innerText;
      buttonElement.innerText = copiedText;
      buttonElement.style.color = "#5A95F8";
      // reset
      setTimeout(() => {
        buttonElement.innerText = originalText;
        buttonElement.style.color = "";
      }, 3000);
    } catch (err) {
      // unable to copy
    }
  };

  const handleMouseMove = (options) => {
    const generateSelectedArea = () => {
      rect = new fabric.Rect({
        left: mouseX,
        top: mouseY,
        width: 0,
        height: 0,
        // @ts-ignore
        id: "selected-area",
        stroke: "#007bff",
        strokeWidth: 3,
        lineWidth: 3,
        strokeDashArray: [6, 3],
        fill: "rgba(255,255,255,0.3)",
        selectable: false,
        defaultCursor: "crosshair",
      });
      canvas.add(rect);
    };
    if (mouseDownEventsToIgnore > 0) {
      mouseDownEventsToIgnore--;
      return;
    }
    if (!shouldRedrawArea) {
      canvas.getObjects().forEach((object) => {
        if (object.id === "selected-area") {
          canvas.remove(object);
        }
      });
      document.querySelectorAll(".set-default-btn").forEach((btn) => btn.remove());
      document.querySelectorAll(".remove-selected-area-btn").forEach((btn) => btn.remove());
      // generate a new div on the fly
      generateSelectedArea();
      shouldRedrawArea = true;
      return;
    }
    const [newMouseX, newMouseY] = [
      options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left,
      options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top,
    ];
    [mouseX, mouseY] = [newMouseX, newMouseY];
    // check if we can resize
    if (mouseX <= 0) {
      return;
    } else if (mouseX > canvas.width) {
      return;
    }
    if (mouseY <= 0) {
      return;
    } else if (mouseY > canvas.height) {
      return;
    }
    let filteredWidth = Math.abs(mouseX - initX);
    let filteredHeight = Math.abs(mouseY - initY);
    let filteredLeft = rect.left;
    let filteredTop = rect.top;
    if (mouseX > initX) {
      filteredLeft = initX;
    } else {
      filteredLeft = mouseX;
    }
    if (mouseY < initY) {
      filteredTop = mouseY;
    } else {
      filteredTop = initY;
    }
    rect.set({
      width: filteredWidth,
      height: filteredHeight,
      left: filteredLeft,
      top: filteredTop,
    });
    canvas.renderAll();
  };

  const setCanvasForOneClickCapture = () => {
    const addSetDefaultButton = () => {
      const setDefaultBtn = document.createElement("button");
      setDefaultBtn.className = "set-default-btn";
      setDefaultBtn.type = "button";
      setDefaultBtn.innerText = t("ApplyChanges", { ns: "VideoCaptureButton" });
      setDefaultBtn.onclick = () => {
        setDefaultBtn.remove();
        setOneClickCaptureRect();
        dispatch(setIsCaptureAreaSetForOneClickCapture(false));
        document.querySelectorAll(".remove-selected-area-btn").forEach((element) => {
          element.remove();
        });
      };
      const canvasWrapper = document.querySelector("#canvas-wrapper");
      if (canvasWrapper) {
        canvasWrapper.appendChild(setDefaultBtn);
      }
      // Set button position after it is rendered since we can only know the with after it is rendered.
      const buttonWidth = setDefaultBtn.getBoundingClientRect().width;
      setDefaultBtn.style.left = `${rect.left + rect.width + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left - buttonWidth}px`;
      setDefaultBtn.style.top = `${rect.top + rect.height + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top + 10}px`;
    };
    const setOneClickCaptureRect = () => {
      const oneClickCaptureRect = {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      };
      dispatch(setCaptureRect(oneClickCaptureRect));
    };

    const addRemoveSelectedAreaButton = () => {
      const removeSelectedAreaButton = document.createElement("button");
      removeSelectedAreaButton.className = "remove-selected-area-btn";
      removeSelectedAreaButton.type = "button";
      removeSelectedAreaButton.innerText = t("ResetCaptureArea", { ns: "VideoCaptureButton" });
      removeSelectedAreaButton.onclick = () => {
        removeSelectedAreaButton.remove();
        resetOneClickCaptureRect();
        dispatch(setIsCaptureAreaSetForOneClickCapture(false));
        document.querySelectorAll(".set-default-btn").forEach((element) => {
          element.remove();
        });
      };
      const canvasWrapper = document.querySelector("#canvas-wrapper");
      if (canvasWrapper) {
        canvasWrapper.appendChild(removeSelectedAreaButton);
      }
      // Set button position after it is rendered since we can only know the with after it is rendered.
      const buttonWidth = removeSelectedAreaButton.getBoundingClientRect().width;

      const setDefaultButtonWidth = document.querySelector(".set-default-btn")?.getBoundingClientRect()?.width ?? 0;
      removeSelectedAreaButton.style.left = `${rect.left + rect.width + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left - setDefaultButtonWidth - buttonWidth - 10}px`;
      removeSelectedAreaButton.style.top = `${rect.top + rect.height + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top + 10}px`;
    };

    const resetOneClickCaptureRect = () => {
      const oneClickCaptureRect = {
        left: 0,
        top: 0,
        width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width - 3,
        height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height - 3,
      };
      dispatch(setCaptureRect(oneClickCaptureRect));
    };

    const handleMouseUpForOneClickCapture = () => {
      addSetDefaultButton();
      addRemoveSelectedAreaButton();
      canvas.__eventListeners["mouse:move"] = [];
      canvas.__eventListeners["mouse:up"] = [];
      shouldRedrawArea = false;
    };
    canvas = new fabric.Canvas("slid-video-one-click-capture-area", {
      width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width,
      height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height,
      defaultCursor: "crosshair",
    });
    shouldRedrawArea = false;
    canvas.selection = false;
    rect = new fabric.Rect({
      left: captureRect ? captureRect.left : 0,
      top: captureRect ? captureRect.top : 0,
      width: captureRect ? captureRect.width : videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width - 3,
      height: captureRect ? captureRect.height : videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height - 3,
      // @ts-ignore
      id: "selected-area",
      stroke: "#007bff",
      strokeWidth: 3,
      lineWidth: 3,
      strokeDashArray: [6, 3],
      fill: "rgba(255,255,255,0.3)",
      selectable: false,
      hoverCursor: "crosshair",
    });
    canvas.add(rect);
    addSetDefaultButton();
    addRemoveSelectedAreaButton();
    canvas.on("mouse:down", (options) => {
      // save the initial mouse position
      [initX, initY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      // save mouse position inside the window
      [mouseX, mouseY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      mouseDownEventsToIgnore = 5;
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:up", handleMouseUpForOneClickCapture);
    });
  };

  const renderFeatureButtonsBar = (width) => {
    if (applicationType === "web") {
      return <WebFeatureButtons />;
    } else if (applicationType === "extension") {
      return <ExtensionFeatureButtons />;
    } else if (applicationType === "desktop") {
      return <DesktopAppFeatureButtons />;
    }
  };

  const onMouseEnterHelpButton = (origin: string) => {
    if (!isHelpMenuOpen && !isHelpButtonHoveredOnce) {
      trackEvent({
        eventType: origin === "help-button" ? eventTypes.hover.HELP_MENU : eventTypes.hover.AI_SLIDDY_BUTTON,
        eventProperties: {
          from: getEventLocationMode(),
        },
      });
      setIsHelpButtonHoveredOnce(true);
    }
    dispatch(setIsHelpMenuOpen(true));
  };

  useEffect(() => {
    isDesktopCaptureAreaSetRef.current = isDesktopCaptureAreaSet;
  }, [isDesktopCaptureAreaSet]);

  useEffect(() => {
    sendAmplitudeData(`Enter vdocs page`);
    trackEvent({
      eventType: "Enter video note page",
      eventProperties: location.pathname.includes("new") ? { noteKey: "new" } : {},
    });

    dispatch(setEditorWrapperClassName("video-document-container"));

    setCheckIsSlidInstantLaunchedListener({
      responseHandler: () => {
        setIsSlidInstantLaunched(true);
      },
    });
    setIsExtensionClickedListener({
      responseHandler: () => {
        setIsSlidInstantLaunched(false);
      },
    });
  }, []);

  useEffect(() => {
    // Requirements:
    // 1) User data exists
    // 2) Extension mode
    // 3) User's first note
    // 4) Onboarding completed
    // 5) First note created within 24h of signup
    // 6) Korean language or user from South Korea
    const isOnboardingCompleted = getDefaultSettingCookie({ property: `isOnboardingCompleted` });
    if (userData && location.pathname.includes("new") && applicationType === "extension") {
      if ((lang === "ko" || userData?.country === "South Korea") && userData?.document_cnt === 0 && isOnboardingCompleted === "true") {
        setTimeout(() => {
          showCustomModal({ showModal, closeModal, history, customComponentContent: <OnboardingPromotionModal /> });
        }, 1000);
        setDefaultSettingCookie({
          property: `isOnboardingCompleted`,
          value: null,
        });
      }
    }
  }, [applicationType, lang, userData]);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = await isUserAuthenticated();
      if (applicationType === "desktop" && !isAuthenticated && !showGuestModeAlert) {
        DESKTOP_showGuestModeAlert();
        dispatch(setIsGuestModeAlertShown(true));
      }
    };
    checkAuthentication();
  }, [applicationType, showGuestModeAlert]);

  useEffect(() => {
    if (modalOn) {
      if (applicationType === "desktop") {
        dispatch(setIsSetCaptureAreaButtonClicked(true));
      } else if (applicationType === "extension") {
        sendHideVideoRequestToParentWindow();
      }
    } else {
      if (applicationType === "desktop") {
        dispatch(setIsSetCaptureAreaButtonClicked(false));
      } else if (applicationType === "extension") {
        const videoPlaceHolder = document.getElementById("video-placeholder");
        setTimeout(() => {
          sendVideoPlaceholderPositionToParentWindow({
            videoPlaceholderPosition: videoPlaceHolder?.getBoundingClientRect(),
          });
        }, 250);
      }
    }
  }, [modalOn, applicationType]);

  useEffect(() => {
    videoPlayerRefRef.current = videoPlayerRef;
  }, [videoPlayerRef]);

  useEffect(() => {
    loaderBlockKeysRef.current = loaderBlockKeys;
  }, [loaderBlockKeys]);

  useEffect(() => {
    if (isCaptureAreaSetForOneClickCapture) {
      setCanvasForOneClickCapture();
    }
  }, [isCaptureAreaSetForOneClickCapture]);

  useEffect(() => {
    if (iframePort) {
      if (userData?.clip_cnt === 0) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_SHOW_HOW_TO_SLID_DESKTOP",
          payload: null,
        });
      } else if (userData) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_CHECK_SHOULD_SHOW_HOW_TO_SLID_DESKTOP",
          payload: null,
        });
      }
    }
  }, [iframePort, userData]);

  useEffect(() => {
    if (isSlidInstantLaunched) {
      trackEvent({
        eventType: "Enter video note page with instant launch",
      });
    }
  }, [isSlidInstantLaunched]);

  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width }) => {
        return (
          <VideoDocumentContainer className={`video-document-container`}>
            <EditorNoteLoadingScreen />
            <ListContainer className={`${isListOpen ? "" : "d-none"}`} ref={listContainerRef}>
              <DocumentListView />
            </ListContainer>
            <VideoDocumentEditorHeader />
            <VideoDocumentBannerWrapper>
              <VideoDocumentEditorBanner width={width || 0} />
            </VideoDocumentBannerWrapper>
            <VideoNoteStudyChallengeInfo />
            <EditorContainer
              applicationType={applicationType}
              id={`editor-container`}
              slid-cy={`vdocs-editor-container`}
              isHorizontal={screenOrientation !== "vertical"}
              onClick={(event) => {
                if (isListOpen && listContainerRef.current && !(listContainerRef.current as any).contains(event.target)) {
                  dispatch(setIsListOpen(false));
                }
              }}
              ref={editorScrollWrapperRef}
            >
              <EditorVerticalScrollWrapper>
                {applicationType && (
                  <>
                    <PCEditorTitle width={width || 0} />
                    <PCEditor />
                  </>
                )}
              </EditorVerticalScrollWrapper>
            </EditorContainer>
            {screenOrientation !== "vertical" && (
              <>
                <VideoDocumentEditorSaveButton width={width || 0} />
                {applicationType === "extension" && isRealTimeTranscriptSupported && <RealTimeSmartLiveTextProgressInfoView />}
                {applicationType === "desktop" && isSTTSupported && <DesktopSmartLiveTextProgressInfoView />}
                {applicationType === "extension" && isRealTimeTranscriptSupported && <RealTimeWhisperAutoNotesMainUI />}
                {renderFeatureButtonsBar(width)}

                {width && width > 410 && !(userData?.payment === UserMembership.break) ? (
                  <BetaPateHelpButtonContainer onMouseEnter={() => onMouseEnterHelpButton("sliddy-button")}>
                    <SliddyIcon color={`white`} width={40} height={40} />
                  </BetaPateHelpButtonContainer>
                ) : (
                  width &&
                  width > 570 && (
                    <HelpButtonContainer onMouseEnter={() => onMouseEnterHelpButton("help-button")}>
                      <Icon icon={`help8`} color={`--gray13`} width={8} height={15} />
                    </HelpButtonContainer>
                  )
                )}
              </>
            )}
            {showImageMarkupPopup && <ImageMarkup />}
            {showImageCroppingModal && <ImageCroppingModal />}
            {isAutoNotesToggledOn && !isWhisperAutoNotesSupported && <AutoNotesMainUI width={width || 0} />}
            {showSliddyChat && <SlidGPT />}
            {isHelpMenuOpen && <HelpMenu isSliddyAvailable={!(userData?.payment === UserMembership.break) && screenOrientation !== "vertical"} />}
            {showSearchPopup && (
              <PopupLayer
                isMobile={isMobile}
                onClick={(event) => {
                  if (!(searchPopupRef.current as any).contains(event.target)) {
                    dispatch(setShowSearchPopup(false));
                  }
                }}
              >
                <PopupContainer ref={searchPopupRef}>
                  <DocumentSearch />
                </PopupContainer>
              </PopupLayer>
            )}
            {showDeletedDocumentsPopup && (
              <PopupLayer
                isMobile={isMobile}
                onClick={(event) => {
                  if (!(deletedDocumentsPopupRef.current as any).contains(event.target)) {
                    dispatch(setShowDeletedDocumentsPopup(false));
                  }
                }}
              >
                <PopupContainer ref={deletedDocumentsPopupRef}>
                  <DeletedDocuments />
                </PopupContainer>
              </PopupLayer>
            )}
            {showSharePopup && (
              <PopupLayer
                isMobile={isMobile}
                onClick={(event) => {
                  if (!(sharePopupRef.current as any).contains(event.target)) {
                    dispatch(setShowSharePopup(false));
                  }
                }}
                style={{ backgroundColor: `rgba(33, 37, 41, 0.5)` }}
              >
                <PopupContainer ref={sharePopupRef}>
                  <DocumentShare />
                </PopupContainer>
              </PopupLayer>
            )}
            {showDisableMediaRecordingModal && (
              <PopupLayer
                onClick={(event) => {
                  if (!(disableMediaRecordingModalRef.current as any).contains(event.target)) {
                    dispatch(setShowDisableMediaRecordingModal(false));
                  }
                }}
                style={{ backgroundColor: `rgba(33, 37, 41, 0.5)` }}
              >
                <PopupContainer ref={disableMediaRecordingModalRef}>
                  <DisableMediaRecordingModal />
                </PopupContainer>
              </PopupLayer>
            )}
          </VideoDocumentContainer>
        );
      }}
    </ReactResizeDetector>
  );
};

// Called when the experiment is displayed to the user.
emitter.addPlayListener((experimentName, variantName) => {
  sendAmplitudeData(`Enter vdocs onboarding`, {
    experiment: experimentName,
    type: variantName,
  });
});

const VideoDocumentContainer = styled.div`
  height: 100%;
  max-width: 100%;
  background-color: $bg-color-white;
  min-height: -webkit-fill-available;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;

  button {
    touch-action: manipulation;
  }
`;

const EditorContainer = styled.div<{ applicationType: string; isHorizontal: boolean }>`
  flex: 1 1 auto;

  ${(props) =>
    props.applicationType !== "desktop" &&
    css`
      @media screen and (max-width: 799px) {
        padding-top: 42px;
      }
    `}

  ${(props) =>
    props.applicationType === "desktop" &&
    css`
      padding-top: 24px;
    `}

  ${(props) =>
    props.isHorizontal &&
    css`
      overflow-y: auto;
      overflow-x: hidden;
    `}
`;

const BetaPateHelpButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 26px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HelpButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 32px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--gray4);
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }
`;

const EditorVerticalScrollWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

const ListContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
`;

const VideoDocumentBannerWrapper = styled.div`
  margin-top: 44px;
`;

export default VideoDocumentEditor;
