import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { fetchDocuments } from "redux/actions/vdocsActions";
import { sendAmplitudeData } from "utils/amplitude";
import { Icon, Typography11 } from "@slid/slid-ips";
import { useSaveDocumentAndCreateHistory } from "hooks/useSaveDocumentAndCreateHistory";

const VideoDocumentEditorSaveButton = ({ width }: { width: number }) => {
  const { t } = useTranslation();
  const { isEditorSaved } = useAppSelector((state) => state.vdocs);
  const { saveDocument } = useSaveDocumentAndCreateHistory();
  const dispatch = useAppDispatch();

  if (!width || width <= 570) return null;
  return (
    <SaveButtonWrapper
      onClick={() => {
        sendAmplitudeData(`Save note manually`, {
          type: "save button",
        });

        saveDocument();
        dispatch(fetchDocuments());
      }}
    >
      {width > 600 && (
        <SaveButtonIconWrapper>
          <Icon icon={`thickCheck16`} color={isEditorSaved ? `--gray9` : `--gray7`} />
        </SaveButtonIconWrapper>
      )}
      {isEditorSaved ? <Typography11 text={t("AutoSaved", { ns: "VideoNote" })} color={`--gray9`} /> : <Typography11 text={t("Saving", { ns: "VideoNote" })} color={`--gray7`} />}
    </SaveButtonWrapper>
  );
};

const SaveButtonIconWrapper = styled.div`
  margin-right: 1px;
`;

const SaveButtonWrapper = styled.div`
  padding: 6px 9px 6px 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 36px;
  border-radius: 4px;
  background-color: var(--gray1);
  cursor: pointer;
  z-index: 1;
`;

export default VideoDocumentEditorSaveButton;
