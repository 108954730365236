import { $createTextNode, IS_BOLD, IS_ITALIC, IS_STRIKETHROUGH, IS_UNDERLINE, IS_CODE, IS_SUBSCRIPT, IS_SUPERSCRIPT, IS_HIGHLIGHT, LexicalNode, TextFormatType } from "lexical";
import { $createLinkNode } from "@lexical/link";
type CustomTextFormatType = "bold" | "italic" | "strikethrough" | "underline" | "code" | "subscript" | "superscript" | "highlight";

const TEXT_TYPE_TO_FORMAT: Record<CustomTextFormatType, number> = {
  bold: IS_BOLD,
  italic: IS_ITALIC,
  strikethrough: IS_STRIKETHROUGH,
  underline: IS_UNDERLINE,
  code: IS_CODE,
  subscript: IS_SUBSCRIPT,
  superscript: IS_SUPERSCRIPT,
  highlight: IS_HIGHLIGHT,
};

function calculateFormat(formats: TextFormatType[]): number {
  return formats.reduce((acc, format) => acc | TEXT_TYPE_TO_FORMAT[format], 0);
}

function convertHTMLFormatToLexicalFormat(element: HTMLElement): { format: TextFormatType[]; style: string } {
  let newFormat: TextFormatType[] = [];
  let newStyle: string = "";

  switch (element.tagName?.toLowerCase()) {
    case "b":
      newFormat.push("bold");
      break;
    case "i":
      newFormat.push("italic");
      break;
    case "mark":
      newFormat.push("highlight");
      break;
    case "u":
      newFormat.push("underline");
      break;
    case "code":
      newFormat.push("code");
      break;
    case "span":
      if (element.hasAttribute("style")) {
        const style = element.getAttribute("style");
        if (style) {
          newStyle = style;
        }
      }
      break;
    case "font":
      if (element.hasAttribute("style") || element.hasAttribute("color")) {
        const style = element.getAttribute("style") || "";
        const color = element.getAttribute("color") || "";

        newStyle = `${color ? `color: ${color};` : ""} ${style}`.trim();
      }
      break;
    default:
      break;
  }

  return { format: newFormat, style: newStyle };
}

export const convertDomNodeToLexicalNode = (htmlString: string): LexicalNode[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const body = doc.body;

  const traverse = (node: Node, parentFormat: TextFormatType[] = [], parentStyle: string = ""): LexicalNode[] => {
    const nodes: LexicalNode[] = [];
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        const textNode = $createTextNode(child.textContent || "");
        const format = calculateFormat(parentFormat);
        textNode.setFormat(format);
        textNode.setStyle(parentStyle);
        nodes.push(textNode);
      } else if (child.nodeType === Node.ELEMENT_NODE && (child as HTMLElement).tagName?.toLowerCase() === "a") {
        const element = child as HTMLElement;
        let { format, style } = convertHTMLFormatToLexicalFormat(element);
        const linkNode = $createLinkNode(element.getAttribute("href") || "");
        const childNodes = traverse(element, format, style);
        childNodes.forEach((childNode) => linkNode.append(childNode));

        nodes.push(linkNode);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        const element = child as HTMLElement;

        let { format, style } = convertHTMLFormatToLexicalFormat(element);

        let newFormat = [...parentFormat, ...format];
        let newStyle = style ? `${style}; ${parentStyle}` : parentStyle;

        nodes.push(...traverse(child, newFormat, newStyle));
      }
    });

    return nodes;
  };

  return traverse(body);
};
