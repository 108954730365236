import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { ImageMarkupHeader } from "./ImageMarkupHeader";
import ImageMarkupTools from "./ImageMarkupTools";
import ImageMarkupContent from "./ImageMarkupContent";
import ImageMarkupController from "./ImageMarkupController";
import ReactResizeDetector from "react-resize-detector";
import { useLocation } from "react-router-dom";
import { useNewImageMarkup } from "../../hooks";
import { useMediaQuery } from "react-responsive";

const ImageMarkup = ({ showImageMarkupPopup }: { showImageMarkupPopup?: boolean }) => {
  const {
    canvasWrapperContainer,
    setCanvasSize,
    isBackgroundImageLoaded,
    handleUndo,
    handleRedo,
    handleReset,
    handleCancel,
    onSaveImageMarkup,
    keyDownHandler,
    canRedo,
    canUndo,
    canReset,
    focusCanvas,
    handleClickColorItem,
  } = useNewImageMarkup();
  const location = useLocation();
  const isVideoNote = location.pathname.split("/")[1] === "vdocs";
  const isMobileUI = useMediaQuery({ query: "(max-width:799px)" });

  useEffect(() => {
    if (showImageMarkupPopup) {
      document.addEventListener("keydown", (e) => {
        e.stopPropagation();
      });
    }

    return () => {
      document.removeEventListener("keydown", (e) => {
        e.stopPropagation();
      });
    };
  }, [showImageMarkupPopup]);

  const renderImageMarkupComponent = () => {
    return (
      <ReactResizeDetector handleWidth>
        {({ width, height }: any) => {
          return (
            <ImageMarkupWrapper id="image-markup" onKeyDown={keyDownHandler} isVideoNote={isVideoNote} width={width} isMobileUI={isMobileUI}>
              {(isVideoNote || isMobileUI) && <ImageMarkupHeader handleCancel={handleCancel} onSaveImageMarkup={onSaveImageMarkup} width={width} focusCanvas={focusCanvas} />}
              <ImageMarkupTools width={width} handleCancel={handleCancel} onSaveImageMarkup={onSaveImageMarkup} focusCanvas={focusCanvas} handleClickColorItem={handleClickColorItem} />
              <ImageMarkupContent canvasWrapperContainer={canvasWrapperContainer} setCanvasSize={setCanvasSize} isBackgroundImageLoaded={isBackgroundImageLoaded} markupModalHeight={height} />
              <ImageMarkupController handleUndo={handleUndo} handleRedo={handleRedo} handleReset={handleReset} canRedo={canRedo} canUndo={canUndo} canReset={canReset} focusCanvas={focusCanvas} />
            </ImageMarkupWrapper>
          );
        }}
      </ReactResizeDetector>
    );
  };

  if (isVideoNote || isMobileUI) {
    return renderImageMarkupComponent();
  } else {
    return (
      <Positioner>
        <CustomDim />
        {renderImageMarkupComponent()}
      </Positioner>
    );
  }
};

const ImageMarkupWrapper = styled.div<{ isVideoNote: boolean; width: number; isMobileUI: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--gray18);
  z-index: 999;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ isVideoNote, width, isMobileUI }) =>
    !(isVideoNote || isMobileUI) &&
    css`
      height: unset;
      width: calc(100% - 108px);
      max-width: 1000px;
      min-width: 560px;
      position: absolute;
      top: 67px;
      left: ${width > 708 ? "50%" : "54px"};
      transform: ${width > 708 ? "translateX(-50%)" : "none"};
      border-radius: 12px;
      overflow: hidden;

      @media screen and (max-height: 780px) {
        top: 10px;
      }

      @media screen and (min-height: 781px) {
        top: 50%;
        transform: ${width > 708 ? "translate(-50%, -50%)" : "translateY(-50%)"};
      }
    `}
  ${({ isVideoNote, isMobileUI }) =>
    isVideoNote &&
    isMobileUI &&
    css`
      position: fixed;
    `}
`;

const Positioner = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: scroll;
`;

const CustomDim = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 910px; // modal height + modal top position(76px) * 2
  padding-bottom: 152px; // modal top position(76px) * 2
  opacity: 0.8;
  background-color: var(--gray17);
`;

export default ImageMarkup;
