//auth 2.0
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Typography15, Typography13, Tooltip } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { setShowImageFullScreen } from "redux/actions/vdocsActions";
import "./ImageFullScreen.css";
import { useImageNodeStore } from "components/NewEditor/store/useImageNodeStore";
import { useModalStore } from "store/useModalStore";

const ImageFullScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { screenOrientation } = useAppSelector((state) => state.slidGlobal);
  const { selectedImageData } = useImageNodeStore();
  const { hideModal } = useModalStore();

  const [originalImageWidth, setOriginalImageWidth] = useState<number | null>(null);
  const [originalImageHeight, setOriginalImageHeight] = useState<number | null>(null);
  const [imageWidth, setImageWidth] = useState<number | null>(null);
  const [imageHeight, setImageHeight] = useState<number | null>(null);
  const [isImageBiggerThanWindow, setIsImageBiggerThanWindow] = useState<boolean>(false);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const ImageContainerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const zoomOutButtonRef = useRef<HTMLButtonElement>(null);
  const originalSizeButtonRef = useRef<HTMLButtonElement>(null);
  const zoomInButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // register keydown event and set image size when it's mounted
    const onKeyDown = (e: any) => {
      switch (e.key) {
        case "Backspace":
          e.stopPropagation();
          break;
        case " ":
          e.preventDefault();
          e.stopPropagation();
          hideModal();
          break;
        case "[":
          zoomOutButtonRef.current?.click();
          break;
        case "p":
          originalSizeButtonRef.current?.click();
          break;
        case "]":
          zoomInButtonRef.current?.click();
          break;
        default:
          break;
      }
    };

    // NOTE: prevent keydown event when image is not loaded
    if (isImageLoaded) {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isImageLoaded]);

  useEffect(() => {
    if (!imageRef.current) return;

    const handleImageLoad = () => {
      setOriginalImageWidth(imageRef.current!.offsetWidth);
      setOriginalImageHeight(imageRef.current!.offsetHeight);
      setImageWidth(imageRef.current!.offsetWidth);
      setImageHeight(imageRef.current!.offsetHeight);
    };

    const imgElement = imageRef.current;
    imgElement.addEventListener("load", handleImageLoad);

    return () => {
      imgElement.removeEventListener("load", handleImageLoad);
      setIsImageLoaded(false);
    };
  }, []);

  useEffect(() => {
    // set isDraggable true when the image size is bigger than inner window's width or height.
    if (!imageHeight || !imageWidth) return;
    if (imageHeight > window.innerHeight || imageWidth > window.innerWidth) {
      setIsImageBiggerThanWindow(true);
    } else {
      setIsImageBiggerThanWindow(false);
    }
  }, [imageHeight, imageWidth]);

  useEffect(() => {
    // make the image draggable if isImageBiggerThanWindow is true.

    let isDragging: boolean;
    let originLeft: number | undefined;
    let originTop: number | undefined;
    let originX: number | undefined;
    let originY: number | undefined;

    const mouseDownHandler = (e: any) => {
      isDragging = true;

      originX = e.clientX;
      originY = e.clientY;
      originLeft = ImageContainerRef.current?.offsetLeft;
      originTop = ImageContainerRef.current?.offsetTop;
    };

    const mouseUpHandler = () => {
      isDragging = false;
    };

    const mouseMoveHandler = (e: any) => {
      if (isDragging) {
        if (!originX || !originY || !originLeft || !originTop) return;

        const diffX = e.clientX - originX;
        const diffY = e.clientY - originY;

        if (!ImageContainerRef.current) return;
        ImageContainerRef.current.style.left = `${originLeft + diffX}px`;
        ImageContainerRef.current.style.top = `${originTop + diffY}px`;
      }
    };

    const resetPositionOfImageElement = () => {
      if (!ImageContainerRef.current) return;

      ImageContainerRef.current.style.left = "";
      ImageContainerRef.current.style.top = "";
    };

    const removeAllEventListeners = () => {
      ImageContainerRef.current?.removeEventListener("mousedown", mouseDownHandler);
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    if (isImageBiggerThanWindow) {
      removeAllEventListeners();
      ImageContainerRef.current?.addEventListener("mousedown", mouseDownHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      document.addEventListener("mousemove", mouseMoveHandler);
    } else {
      removeAllEventListeners();
      resetPositionOfImageElement();
    }

    return () => {
      removeAllEventListeners();
    };
  }, [isImageBiggerThanWindow]);

  const zoomOutHandler = () => {
    if (imageWidth && imageHeight && imageWidth > 500) {
      setImageWidth(imageWidth * 0.8);
      setImageHeight(imageHeight * 0.8);
    }
  };

  const zoomInHandler = () => {
    if (imageWidth && imageHeight && imageWidth < 3000) {
      setImageWidth(imageWidth * 1.2);
      setImageHeight(imageHeight * 1.2);
    }
  };

  return (
    <FullScreenContainer>
      <CloseButton
        onClick={() => {
          hideModal();
          dispatch(setShowImageFullScreen(false));
        }}
      >
        {/**
         * Tooltip is placed here because its position can be controlled when target element is not fixed position.
         */}
        <Tooltip title={"( space )"} placement={`bottom`} onShow={() => screenOrientation !== "vertical"} zIndex={999999}>
          <CloseButtonTextWrapper>
            <CloseButtonText text={t("Close", { ns: "EditorComponent" })} color={`--gray1`} />
          </CloseButtonTextWrapper>
        </Tooltip>
      </CloseButton>
      <ContentContainer
        onClick={() => {
          hideModal();

          dispatch(setShowImageFullScreen(false));
        }}
      >
        <ImageContainer ref={ImageContainerRef}>
          <Image
            src={selectedImageData?.src}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            isImageBiggerThanWindow={isImageBiggerThanWindow}
            onClick={(e) => e.stopPropagation()}
            draggable={`false`}
            ref={imageRef}
            onLoad={() => {
              setIsImageLoaded(true);
            }}
          />
        </ImageContainer>
      </ContentContainer>
      <ButtonContainer id={`button-container`}>
        <Tooltip placement={`top`} zIndex={999999} title={t("ZoomOutTooltip", { ns: "EditorComponent" })} description={"( [ )"} onShow={() => screenOrientation !== "vertical"}>
          <ZoomOutButton onClick={() => zoomOutHandler()} ref={zoomOutButtonRef}>
            <img src="/src/icons/IconMinus.svg" alt="Zoom out" />
          </ZoomOutButton>
        </Tooltip>
        <Tooltip placement={`top`} zIndex={999999} onShow={() => screenOrientation !== "vertical"} title={t("OriginalImageTooltip", { ns: "EditorComponent" })} description={"( p )"}>
          <OriginalSizeButton
            onClick={() => {
              setImageWidth(originalImageWidth);
              setImageHeight(originalImageHeight);
            }}
            ref={originalSizeButtonRef}
          >
            <OriginalSizeButtonText text={t("OriginalImage", { ns: "EditorComponent" })} color={`--gray1`} weight={700} />
          </OriginalSizeButton>
        </Tooltip>
        <Tooltip placement={`top`} zIndex={999999} onShow={() => screenOrientation !== "vertical"} description="( ] )" title={t("ZoomInTooltip", { ns: "EditorComponent" })}>
          <ZoomInButton onClick={() => zoomInHandler()} ref={zoomInButtonRef}>
            <img src="/src/icons/IconPlus.svg" alt="Zoom in" />
          </ZoomInButton>
        </Tooltip>
      </ButtonContainer>
    </FullScreenContainer>
  );
};

const SettingButton = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  /* e of reset button  */
  background: #000;

  &:hover {
    background: var(--gray15);
  }
`;

const FullScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999998;
`;

const CloseButton = styled(SettingButton)`
  position: fixed;
  z-index: 999999;
  top: 20px;
  right: 20px;
  border-radius: 4px;
  padding: 0;
`;

const CloseButtonTextWrapper = styled.div`
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButtonText = styled(Typography15)`
  && {
    z-index: 999999;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const Image = styled.img<{ imageWidth: number | null; imageHeight: number | null; isImageBiggerThanWindow: boolean }>`
  width: ${({ imageWidth }) => (imageWidth ? `${imageWidth}px` : `90%`)};
  height: auto;
  cursor: ${({ isImageBiggerThanWindow }) => (isImageBiggerThanWindow ? `grab` : ``)};
  transition:
    width 500ms ease,
    height 500ms ease;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 24px;
  cursor: pointer;
  min-height: 52px;
  margin-top: 12px;
  z-index: 999999;
`;

const ZoomOutButton = styled(SettingButton)`
  width: 52px;
  height: 100%;
  min-height: 52px;
  border-radius: 12px 0px 0px 12px;
`;

const OriginalSizeButton = styled(SettingButton)`
  width: 64px;
  height: 100%;
  min-height: 52px;
  border-right: 1px solid var(--gray13);
  border-left: 1px solid var(--gray13);

  &:focus {
    border-right: 1px solid var(--gray13);
    border-left: 1px solid var(--gray13);
  }
`;

const OriginalSizeButtonText = styled(Typography13)``;

const ZoomInButton = styled(SettingButton)`
  width: 52px;
  height: 100%;
  min-height: 52px;
  border-radius: 0px 12px 12px 0px;
`;

export default ImageFullScreen;
