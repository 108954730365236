import { Typography15 } from "@slid/slid-ips";
import React, { useEffect, useRef, useState, memo } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import styled from "styled-components";
import { createDocument, registerClip, setCroppingImageBlockData, setEditImageCropArea, setImageCropArea, setImageCroppingModalImageSource } from "redux/actions/vdocsActions";
import { uploadBase64Image } from "utils/aws/s3Interface";
import { useTranslation } from "react-i18next";
import "react-advanced-cropper/dist/style.css";
import { Cropper, ImageRestriction } from "react-advanced-cropper";
import LoadingScreenContent from "../editorLoadingStates/LoadingScreenContent";
import { trackEvent } from "utils/eventTracking";
import "./ImageCroppingModal.css";
import { useMediaQuery } from "react-responsive";
import { useImageNodeStore } from "components/NewEditor/store/useImageNodeStore";
import { $isImageNode } from "components/NewEditor/nodes/SlidImageNode/ImageNode";
import { $getNodeByKey } from "lexical";
import useEditorStore from "store/useEditorStore";

const ImageCroppingModal = memo(() => {
  const dispatch = useAppDispatch();
  const { currentDocument, imageCropArea, imageCroppingModalImageSource, croppingImageBlockData } = useAppSelector((state) => state.vdocs);
  const cropperRef = useRef<any>(null);
  const dummyInputRef = useRef<any>(null);
  const { t } = useTranslation("VideoNote");
  const [isCropAreaMoved, setIsCropAreaMoved] = useState<boolean>(false);
  const isMobileUI = useMediaQuery({ query: "(max-width:799px)" });
  const { selectedImageNodeKey, setSelectedImageNodeKey, setShowImageCroppingModal } = useImageNodeStore();
  const lexicalEditorRef = useEditorStore((state) => state.lexicalEditorRef);

  useEffect(() => {
    // register keydown event and set image size when it's mounted
    const onKeyDown = (e: any) => {
      switch (e.key) {
        case " ":
          e.preventDefault();
          e.stopPropagation();
          closeImageCroppingModal();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      closeImageCroppingModal();
    };
  }, []);

  useEffect(() => {
    if (dummyInputRef.current) {
      dummyInputRef.current.focus();
    }
  }, [dummyInputRef.current]);

  const closeImageCroppingModal = () => {
    dispatch(setEditImageCropArea(false));
    dispatch(setImageCroppingModalImageSource(null));
    setShowImageCroppingModal(false);
    dispatch(setCroppingImageBlockData(null));
    dispatch(setImageCropArea(null));
    setSelectedImageNodeKey("");
  };

  const onSaveCroppedImage = async () => {
    const imageElement: any = cropperRef?.current;
    const imageURL = imageElement.getCanvas().toDataURL();
    const cropDataResponse = imageElement.getCoordinates();
    const cropData: { x: number; y: number; width: number; height: number } = {
      width: Math.floor(cropDataResponse.width),
      height: Math.floor(cropDataResponse.height),
      x: Math.floor(cropDataResponse.left),
      y: Math.floor(cropDataResponse.top),
    };

    if (cropData.x === imageCropArea?.x && cropData.y === imageCropArea?.y && cropData.width === imageCropArea?.width && cropData.height === imageCropArea?.height) {
      return closeImageCroppingModal();
    }

    let currentDocumentKey: string;

    if (currentDocument) {
      currentDocumentKey = currentDocument["document_key"];
    } else {
      const createdDocument: any = await dispatch(
        createDocument({
          origin: window.location.pathname.split("/")[1] === "vdocs" ? "vdocs" : "plain docs",
        })
      );
      //@ts-ignore
      if (createDocument.error_message) return;
      currentDocumentKey = createdDocument["document_key"];
    }

    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: imageURL,
    });
    if (!uploadedImageSrc) return;

    const clipRegisterResponse: any = await dispatch(
      registerClip({
        imgSrc: uploadedImageSrc,
        documentKey: currentDocumentKey,
        clipTimestamp: croppingImageBlockData?.timestamp,
        videoKey: croppingImageBlockData?.videoInfo?.videoKey,
        clipEndPoint: "capture-clip",
      })
    );
    if (clipRegisterResponse.error_message) return;
    dispatch(setImageCropArea(cropData));

    lexicalEditorRef.current?.update(() => {
      const currentImageBlock = $getNodeByKey(selectedImageNodeKey ?? "");
      if (!currentImageBlock) return;
      if ($isImageNode(currentImageBlock)) {
        currentImageBlock.updateData({
          imageCropArea: cropData,
          src: uploadedImageSrc,
          fullSizeImageSrcForCropping: imageCroppingModalImageSource,
          clipKey: clipRegisterResponse["clip_key"],
        });
      }
    });
    closeImageCroppingModal();
  };

  return (
    <ModalLayer isMobile={isMobileUI}>
      {/* Dummy input for space shortcut feature of CloseButton */}
      <input ref={dummyInputRef} readOnly style={{ height: "0px", opacity: "0" }} />
      <CloseButton
        onClick={() => {
          trackEvent({ eventType: "Click CLOSE in capture area resizing", eventProperties: { from: "crop image" } });
          closeImageCroppingModal();
        }}
      >
        <CloseButtonText text={t("ImageCroppingModalResettingCancel")} color={`--gray1`} />
      </CloseButton>
      {imageCroppingModalImageSource === "loading" ? (
        <ImageLoadingContainer>
          <LoadingScreenContent loadingText={t("ImageCroppingModalLoadingText")} textColor="--gray3" />
        </ImageLoadingContainer>
      ) : (
        <>
          <ImageCroppingModalContainer>
            <Cropper
              src={imageCroppingModalImageSource}
              stencilProps={{
                handlers: {
                  eastNorth: true,
                  north: true,
                  westNorth: true,
                  west: true,
                  westSouth: true,
                  south: true,
                  eastSouth: true,
                  east: true,
                },
              }}
              className=""
              ref={cropperRef}
              defaultPosition={{
                left: imageCropArea ? imageCropArea.x : 0,
                top: imageCropArea ? imageCropArea.y : 0,
              }}
              defaultSize={({ imageSize, visibleArea }) => {
                return {
                  width: imageCropArea ? imageCropArea.width : (visibleArea || imageSize).width,
                  height: imageCropArea ? imageCropArea.height : (visibleArea || imageSize).height,
                };
              }}
              style={{ maxWidth: "80%" }}
              imageRestriction={ImageRestriction.fitArea}
              onError={(e) => {
                // NOTE : When CORS error happens, we need to reload the image.
                dispatch(setImageCroppingModalImageSource(`${imageCroppingModalImageSource}?cache=${Date.now().toString()}`));
              }}
              onInteractionEnd={() => {
                setIsCropAreaMoved(true);
              }}
            />
          </ImageCroppingModalContainer>
        </>
      )}
      <ButtonContainer>
        {imageCroppingModalImageSource && (
          <>
            <ButtonTextContainer
              onClick={() => {
                trackEvent({
                  eventType: "Click RESET AREA in capture area resizing",
                  eventProperties: {
                    from: "crop image",
                  },
                });
                cropperRef.current.reset();
              }}
              isDisabled={!isCropAreaMoved}
            >
              <Typography15 weight={700} color={isCropAreaMoved ? "--gray2" : "--gray13"} text={t("ImageCroppingModalResetArea")} />
            </ButtonTextContainer>
            <VerticalLine></VerticalLine>
            <ButtonTextContainer
              onClick={() => {
                trackEvent({
                  eventType: "Click APPLY ONCE in capture area resizing",
                  eventProperties: {
                    from: "crop image",
                  },
                });
                onSaveCroppedImage();
              }}
              isDisabled={false}
            >
              <Typography15 weight={700} color="--blue6" text={t("ImageCroppingModalApplyEditing")} />
            </ButtonTextContainer>
          </>
        )}
      </ButtonContainer>
    </ModalLayer>
  );
});

export default ImageCroppingModal;

const ModalLayer = styled.div<{ isMobile?: boolean }>`
  position: ${(props) => (props.isMobile ? "fixed" : "absolute")};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 13, 14, 0.95);
  backdrop-filter: blur(4px);
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const ImageCroppingModalContainer = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0px 32px;
  margin: 12px 0px 52px 0px;
`;
const CloseButton = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  /* e of reset button  */

  background: #000;

  &:hover {
    background: var(--gray15);
  }

  position: fixed;
  height: 40px;
  padding: 0 15px;
  top: 20px;
  right: 20px;
  border-radius: 4px;
  color: white;
  z-index: 999999;
`;

const CloseButtonText = styled(Typography15)`
  && {
    z-index: 999999;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: var(--gray17);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ButtonTextContainer = styled.div<{ isDisabled: boolean }>`
  width: auto;
  height: auto;
  padding: 12px 20px;
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};

  :active,
  :hover {
    border-radius: 8px;
    background-color: ${(props) => (props.isDisabled ? "default" : "var(--gray15)")};
  }
`;

const VerticalLine = styled.div`
  height: 24px;
  border-right: 1px solid var(--gray9);
`;
