import { $applyNodeReplacement, DecoratorNode, DOMExportOutput, type EditorConfig, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from "lexical";
import React from "react";
import { Suspense } from "react";
const SlidLinkPreviewComponent = React.lazy(() => import("./SlidLinkPreviewComponent"));

export type SlidLinkPreviewProps = {
  link: string;
  meta: {
    description: string;
    image: {
      url: string;
    };
    title: string;
    link: string;
  };
};

export type SerializedSlidLinkPreviewNode = Spread<SlidLinkPreviewProps, SerializedLexicalNode>;

export class SlidLinkPreviewNode extends DecoratorNode<JSX.Element> {
  __link: string;
  __meta: {
    description: string;
    image: {
      url: string;
    };
    title: string;
    link: string;
  };

  constructor(
    link: string,
    meta: {
      description: string;
      image: {
        url: string;
      };
      title: string;
      link: string;
    },
    key?: NodeKey
  ) {
    super(key);
    this.__link = link;
    this.__meta = meta;
  }

  static getType() {
    return "slid-link-preview";
  }

  static clone(node: SlidLinkPreviewNode) {
    return new SlidLinkPreviewNode(node.__link, node.__meta, node.__key);
  }

  static importJSON(serializedNode: SerializedSlidLinkPreviewNode) {
    const { link, meta } = serializedNode;
    const node = $createSlidLinkPreviewNode({ link, meta });
    return node;
  }

  exportJSON() {
    return {
      link: this.__link,
      meta: this.__meta,
      type: "slid-link-preview",
      version: 1,
    };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");
    const theme = _config.theme;
    const className = theme.linkPreview;
    if (className !== undefined) {
      element.className = className;
    }
    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    return { element };
  }

  updateDOM(): boolean {
    return false;
  }

  getTextContent(): string {
    return this.__meta.link;
  }

  setText(link: string): void {
    const writable = this.getWritable();
    writable.__link = link;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <SlidLinkPreviewComponent link={this.__link} meta={this.__meta} nodeKey={this.getKey()} />
      </Suspense>
    );
  }
}

export function $createSlidLinkPreviewNode({ link, meta }: SlidLinkPreviewProps) {
  const slidLinkPreviewNode = new SlidLinkPreviewNode(link, meta);
  slidLinkPreviewNode.setText(link);
  return $applyNodeReplacement(slidLinkPreviewNode);
}

export function $isSlidLinkPreviewNode(node: LexicalNode | null | undefined): node is SlidLinkPreviewNode {
  return node instanceof SlidLinkPreviewNode;
}
