import React from "react";
import SnipCaptureButtonUI from "../VideoCaptureButton/ui/SnipCaptureButtonUI";
import useWebExtensionCapture from "./utils/useWebExtensionCapture";
import { CaptureButtonContainer } from "../VideoCaptureButton/style/buttonStyles";
import OneClickCaptureButtonUI from "../VideoCaptureButton/ui/OneClickCaptureButtonUI";
import { trackEvent } from "utils/eventTracking";
import { useAppDispatch, useAppSelector } from "hooks";
import { setIsCaptureAreaSetForOneClickCapture } from "redux/actions/vdocsActions";

const WebFeatureButtons = () => {
  const dispatch = useAppDispatch();
  const { isWebCaptureAvailable } = useAppSelector((state) => state.vdocs);
  const { manualCapture, showSelectAreaPopup } = useWebExtensionCapture();

  const onClickSettingButton = (e) => {
    if (!isWebCaptureAvailable) return;

    e.stopPropagation();
    trackEvent({
      eventType: "Click capture setting button",
    });
    dispatch(setIsCaptureAreaSetForOneClickCapture(true));
  };

  const onClickCaptureButton = () => {
    manualCapture();
  };

  return (
    <CaptureButtonContainer>
      <SnipCaptureButtonUI onClick={showSelectAreaPopup} />
      <OneClickCaptureButtonUI onClickCaptureButton={onClickCaptureButton} onClickSettingButton={onClickSettingButton} />
    </CaptureButtonContainer>
  );
};

export default WebFeatureButtons;
