import { $applyNodeReplacement, DecoratorNode, DOMExportOutput, type EditorConfig, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from "lexical";
import React from "react";
import { Suspense } from "react";
const SlidVideoComponent = React.lazy(() => import("./SlidVideoComponent"));

export type VideoData = {
  videoUrl: string;
  posterUrl: string;
  isLoading: boolean;
  isRecording: boolean;
};

export type SerializedSlidVideoNode = Spread<VideoData, SerializedLexicalNode>;

export class SlidVideoNode extends DecoratorNode<JSX.Element> {
  __videoData: {
    videoUrl: string;
    posterUrl: string;
    isLoading: boolean;
    isRecording: boolean;
  };

  constructor({ videoUrl, posterUrl, isLoading, isRecording }: { videoUrl: string; posterUrl: string; isLoading: boolean; isRecording: boolean }, key?: NodeKey) {
    super(key);
    this.__videoData = { videoUrl, posterUrl, isLoading, isRecording };
  }

  static getType() {
    return "slid-video";
  }

  static clone(node: SlidVideoNode) {
    return new SlidVideoNode(node.__videoData, node.__key);
  }

  static importJSON(serializedNode: SerializedSlidVideoNode) {
    const { videoUrl, posterUrl } = serializedNode;
    const node = $createSlidVideoNode(videoUrl, posterUrl);
    return node;
  }

  exportJSON() {
    return {
      videoUrl: this.__videoData.videoUrl,
      posterUrl: this.__videoData.posterUrl,
      type: "slid-video",
      version: 1,
    };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");
    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    return { element };
  }

  updateDOM(): boolean {
    // If the inline property changes, replace the element
    return false;
  }

  updateVideoData(newData: Partial<VideoData>) {
    const writableNode = this.getWritable();
    if (writableNode.__videoData) {
      Object.assign(writableNode.__videoData, newData);
    } else {
      Object.assign(writableNode, newData);
    }
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <SlidVideoComponent
          videoUrl={this.__videoData.videoUrl}
          posterUrl={this.__videoData.posterUrl}
          isLoading={this.__videoData.isLoading}
          isRecording={this.__videoData.isRecording}
          nodeKey={this.getKey()}
        />
      </Suspense>
    );
  }
}

export function $createSlidVideoRecordingNode() {
  const slidVideoRecordingNode = new SlidVideoNode({ videoUrl: "", posterUrl: "", isLoading: false, isRecording: true });
  return $applyNodeReplacement(slidVideoRecordingNode);
}

export function $createSlidVideoLoaderNode() {
  const slidVideoLoaderNode = new SlidVideoNode({ videoUrl: "", posterUrl: "", isLoading: true, isRecording: false });
  return $applyNodeReplacement(slidVideoLoaderNode);
}

export function $createSlidVideoNode(videoUrl: string, posterUrl: string) {
  const slidVideoNode = new SlidVideoNode({ videoUrl, posterUrl, isLoading: false, isRecording: false });
  return $applyNodeReplacement(slidVideoNode);
}

export function $isSlidVideoNode(node: LexicalNode | null | undefined): node is SlidVideoNode {
  return node instanceof SlidVideoNode;
}
