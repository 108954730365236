import { create } from "zustand";

interface OneClickCaptureStore {
  isCapturing: boolean;
  setIsCapturing: (isCapturing: boolean) => void;
}

const useOneClickCaptureStore = create<OneClickCaptureStore>()((set) => ({
  isCapturing: false,
  setIsCapturing: (isCapturing) => set({ isCapturing }),
}));

export default useOneClickCaptureStore;
