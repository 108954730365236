import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const TextColorIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 32} height={height ? height : 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="color">
        <path id="Vector 247" d="M10 22L16 8L22 22" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 248" d="M12.5 16.5H19.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </SvgWrapper>
  );
};
