import React from "react";
import { Icon } from "@slid/slid-ips";
import { Tooltip } from "@slid/slid-ips";
import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isMacOs } from "react-device-detect";
import { ButtonText, CaptureButton } from "../style/buttonStyles";
import { sendAmplitudeData } from "utils/amplitude";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import useOneClickCaptureStore from "../utils/useOneClickCaptureStore";

const OneClickCaptureButtonUI = ({ onClickCaptureButton, onClickSettingButton }: { onClickCaptureButton: (e) => void; onClickSettingButton: (e) => void }) => {
  const { t } = useTranslation();
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { isDesktopCaptureAreaSet } = useAppSelector((state) => state.vdocs);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { isCapturing } = useOneClickCaptureStore();

  return (
    <CaptureButtonPositioner>
      <CaptureButton
        onClick={(e) => {
          if (isCapturing) return;
          sendAmplitudeData(`SLID_1_CAPTURE_IMAGE`);
          if (applicationType) {
            // Sometimes applicationType is null when the event is occurred in desktop
            trackEvent({
              eventType: "Click 1-click capture",
            });
          }
          if (!confirmPrivilege(SlidFeatures.oneClickCapture)) return showInsufficientPrivilegeModal();

          onClickCaptureButton(e);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        onMouseUp={(event) => {
          event.preventDefault();
        }}
        onContextMenu={(event) => {
          event.preventDefault();
        }}
      >
        <Tooltip
          title={
            applicationType === "desktop"
              ? isDesktopCaptureAreaSet
                ? // when button is blue
                  t("1ClickCapture", { ns: "VideoCaptureButton" })
                : // when button is grey
                  t("SetCaptureArea", { ns: "VideoCaptureButton" })
              : // etc
                t("1ClickCapture", { ns: "VideoCaptureButton" })
          }
          description={
            applicationType === "desktop"
              ? isDesktopCaptureAreaSet
                ? // when button is blue
                  isMacOs
                  ? "(Cmd + /)"
                  : "(Alt + /)"
                : // when button is grey
                  ""
              : // etc
                isMacOs
                ? "(Cmd + /)"
                : "(Alt + /)"
          }
          placement={"top"}
        >
          <CaptureButtonOverlayArea></CaptureButtonOverlayArea>
        </Tooltip>
        <SettingButtonContainer onClick={onClickSettingButton}>
          <Tooltip title={t("SetCaptureArea", { ns: "VideoCaptureButton" })} placement={"top"}>
            <Icon icon={`chevronDown20`} color={`--gray17`} />
          </Tooltip>
        </SettingButtonContainer>
        <Icon icon={`1Click28`} color={`--gray17`} />
        <ButtonText>{t("1Click", { ns: "VideoNote" })}</ButtonText>
      </CaptureButton>
    </CaptureButtonPositioner>
  );
};

export const SettingButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 6px;
  width: 22px;
  height: 24px;
  border-radius: 8px;

  &:hover {
    background-color: var(--gray5);
  }
`;

const CaptureButtonPositioner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CaptureButtonOverlayArea = styled.div`
  position: absolute;
  left: 0px;
  top: 0;
  width: 80px;
  height: 52px;
`;

export default OneClickCaptureButtonUI;
