import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const ItalicIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 32} height={height ? height : 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="italic">
        <path id="Vector 233" d="M14 10H20.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 234" d="M10 22H16.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 235" d="M13 22L17.5 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </SvgWrapper>
  );
};
