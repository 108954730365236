import React, { useEffect, useRef, useState } from "react";
import { KeyboardEvent as ReactKeyboardEvent } from "react";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import useEditorStore from "store/useEditorStore";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { setVideoMetadataResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import USER_PRIVILEGES, { SlidFeatures } from "utils/privilegeManager";
import env from "config/env";
import { useSaveDocumentAndCreateHistory } from "hooks/useSaveDocumentAndCreateHistory";
import { $createSlidParagraphNode } from "../nodes/SlidParagraphNode";
import { $createTextNode, $getSelection } from "lexical";
import { $createRangeSelection, $getRoot, $setSelection } from "lexical";
import { sendVideoMetadataRequestToParentWindow } from "utils/extensionInterface/sendToExtension";

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

const PCEditorTitle = ({ width }: { width: number }) => {
  const location = useLocation();
  const { t } = useTranslation(["EditorComponent", "SweetAlertModals"]);
  const { isReadOnly, currentDocument } = useAppSelector((state) => state.vdocs);
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { editorDocumentTitle, setEditorDocumentTitle, currentDocumentKey, lexicalEditorRef } = useEditorStore();
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { debouncedSaveDocument, debouncedTryCreateDocumentHistory } = useSaveDocumentAndCreateHistory();
  const { isAutoNotesSupported } = useAppSelector((state) => state.autoNotes);

  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const titleTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const currentDocumentRef = useRef(currentDocument);
  const isComposing = useRef(false);
  const isDemoMode = document.location.href === `${SLID_WEB_APP_URL}/demo`;
  const isAuthRequired = document.location.href === `${SLID_WEB_APP_URL}/auth-required`;
  const isVideoNote = location.pathname.includes("/vdocs");

  useEffect(() => {
    if (!USER_PRIVILEGES.has(SlidFeatures.noteTitleUpdate)) return;
    if (applicationType !== "extension" || !isAutoNotesSupported) {
      return;
    }

    if (!currentDocument || (currentDocument && !currentDocument.title)) {
      sendVideoMetadataRequestToParentWindow();
    }
  }, [currentDocument?.document_key, applicationType, isAutoNotesSupported]);

  useEffect(() => {
    setVideoMetadataResponseListener({
      responseHandler: (data: { videoTitle: string | null; videoDescription: string | null } | null) => {
        const videoTitle = data?.videoTitle;
        if (videoTitle) {
          setEditorDocumentTitle(videoTitle);
        }
      },
    });
  }, []);

  useEffect(() => {
    currentDocumentRef.current = currentDocument;
  }, [currentDocument]);

  useEffect(() => {
    setEditorDocumentTitle(currentDocumentRef.current?.title || "");
  }, [currentDocumentKey]);

  useEffect(() => {
    if (!titleTextAreaRef.current) return;
    // For auto resizing the height of title textarea
    titleTextAreaRef.current.style.height = isMobile && applicationType !== "desktop" ? `36px` : `51px`;

    setTimeout(() => {
      if (!titleTextAreaRef.current) return;
      titleTextAreaRef.current.style.height = `${titleTextAreaRef.current?.scrollHeight}px`;
    }, 100);
  }, [editorDocumentTitle, width]);

  const onKeyDownHandler = (event: ReactKeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (!USER_PRIVILEGES.has(SlidFeatures.noteEditing) && !isDemoMode) return;
      if (!lexicalEditorRef.current) return;

      const inputElement = titleTextAreaRef.current;
      if (!inputElement) return;
      if (isComposing.current) return;

      const cursorPosition = inputElement.selectionStart ?? editorDocumentTitle.length;

      const beforeText = editorDocumentTitle.slice(0, cursorPosition);
      const afterText = editorDocumentTitle.slice(cursorPosition);

      setEditorDocumentTitle(beforeText);
      lexicalEditorRef.current.update(() => {
        const root = $getRoot();
        const firstChild = root.getFirstChild();
        const newParagraph = $createSlidParagraphNode();
        newParagraph.append($createTextNode(afterText));
        if (firstChild) {
          firstChild.insertBefore(newParagraph);
        } else {
          root.append(newParagraph);
        }

        const selection = $createRangeSelection();
        selection.anchor.set(newParagraph.getKey(), 0, "element");
        selection.focus.set(newParagraph.getKey(), 0, "element");
        $setSelection(selection);
      });
    }
  };

  const onCompositionStartHandler = () => {
    isComposing.current = true;
  };

  const onCompositionEndHandler = () => {
    isComposing.current = false;
  };

  return (
    <Wrapper>
      <EditorTitle
        isVideoNote={isVideoNote}
        isMobile={isMobile && applicationType !== "desktop"}
        isReadOnly={isReadOnly}
        onClick={(e) => {
          e.preventDefault();
          if (!confirmPrivilege(SlidFeatures.noteTitleUpdate) && !isDemoMode) return showInsufficientPrivilegeModal();
        }}
      >
        <EditorTitleTextArea
          isMobile={isMobile && applicationType !== "desktop"}
          autoComplete={`off`}
          ref={titleTextAreaRef}
          readOnly={isReadOnly}
          placeholder={t("TitleText", { ns: "EditorComponent" })}
          value={editorDocumentTitle}
          data-testid={`editor-title`}
          onChange={(event) => {
            if (!USER_PRIVILEGES.has(SlidFeatures.noteTitleUpdate) && !isDemoMode) return;
            event.target.value = event.target.value.replace(/[\r\n\v]+/g, "");
            setEditorDocumentTitle(event.target.value);
            if (isDemoMode || isAuthRequired) return;
            debouncedSaveDocument(true);
            debouncedTryCreateDocumentHistory();
          }}
          onKeyDown={onKeyDownHandler}
          disabled={isReadOnly}
          onCompositionStart={onCompositionStartHandler}
          onCompositionEnd={onCompositionEndHandler}
        />
      </EditorTitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
`;

const EditorTitle = styled.h1<{ isMobile: boolean; isReadOnly: boolean; isVideoNote: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? `2.4rem` : `3.4rem`)};
  width: ${({ isVideoNote }) => (isVideoNote ? "90%" : "87%")};
  max-width: 760px;
  padding: 0 28px 16px;
  display: flex;
  align-items: center;
`;

const EditorTitleTextArea = styled.textarea<{ isMobile: boolean }>`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? `36px` : `51px`)};
  padding: 0;
  border: 0;
  outline: 0;
  resize: none;
  font-weight: 700;
  line-height: 1.5;
  -webkit-appearance: none;
  background: none;
  color: var(--gray17);
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::placeholder {
    font-weight: 700;
    color: var(--gray4);
  }
`;

export default PCEditorTitle;
