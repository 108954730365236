import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { trackEvent } from "utils/eventTracking";
import { Typography17, Button, Typography24 } from "@slid/slid-ips";
import styled from "styled-components";
import { sendMessageToPrimary } from "utils/utils";
import { useAppSelector } from "hooks";
import ModalPortal from "./ModalPortal";
import BaseModalContainer from "./BaseModalContainer";
import { useModalStore } from "store/useModalStore";

const ScaleFactorChangeModal = memo(() => {
  const { t } = useTranslation();
  const { hideModal } = useModalStore();
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { iframePort } = useAppSelector((state) => state.vdocs);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

  useEffect(() => {
    sendMessageToPrimary({
      message: { type: "IFRAME_TO_PRIMARY_openDisplaySettings", payload: null },
      port: iframePort,
    });
  }, [videoLoaded]);

  useEffect(() => {
    trackEvent({
      eventType: "Visit desktop scale issue modal",
    });
  }, []);

  return (
    <ModalPortal target="body">
      <BaseModalContainer closable={false}>
        <OnboardingBodyWrapper data-testid={`onboarding-popup-body`}>
          <OnboardingPopupWrapper data-testid={`onboarding-popup-paragraph`}>
            <VideoWrapper>
              <LoadingSpinnerWrapper visible={!videoLoaded}>
                <Spinner className="spinner-border mt-3" role="status" />
                <Typography17 text={t("VideoLoadingMessage", { ns: "SlidOnboarding" })} color={"--gray5"} marginTop={"12px"} />
              </LoadingSpinnerWrapper>
              <OnboardingVideo
                id="video-step1"
                visible={videoLoaded}
                src={`https://slid-public-assets.s3.us-west-1.amazonaws.com/desktop_scale-guide_video_${lang}.webm`}
                autoPlay
                loop
                muted
                onCanPlay={() => {
                  setVideoLoaded(true);
                }}
              />
            </VideoWrapper>

            <GuideTextWrapper data-testid={`onboarding-popup-text`}>
              {/* class name for cypress */}
              <Typography17 text={t("ScaleFactorModalVideoDetail", { ns: "Desktop" })} color="--gray7" weight={700} />
              <div style={{ marginTop: "16px" }}>
                <Typography24 text={t("ScaleFactorModalVideoTitle1", { ns: "Desktop" })} color="--gray17" weight={700} style={{ display: "inline" }} />
                <Typography24 text={t("ScaleFactorModalVideoTitle2", { ns: "Desktop" })} color="--blue8" weight={700} style={{ display: "inline" }} />
                <Typography24 text={t("ScaleFactorModalVideoTitle3", { ns: "Desktop" })} color="--gray17" weight={700} style={{ display: "inline" }} />
              </div>

              <div style={{ marginTop: "16px" }}>
                <Typography17 text={t("ScaleFactorModalDetail1", { ns: "Desktop" })} color="--gray15" weight={400} style={{ display: "inline" }} />
                <Typography17 text={t("ScaleFactorModalDetail2", { ns: "Desktop" })} color="--gray15" weight={700} style={{ display: "inline" }} />
                <Typography17 text={t("ScaleFactorModalDetail3", { ns: "Desktop" })} color="--gray15" weight={400} style={{ display: "inline" }} />
              </div>
              <ButtonWrapper>
                <Button
                  appearance="secondary"
                  size="medium"
                  text={t("ScaleFactorModalVideoSecondary", { ns: "Desktop" })}
                  callback={() => {
                    trackEvent({
                      eventType: "Click CLOSE desktop in scale issue modal",
                    });
                    hideModal();
                  }}
                  data-testid={`onboarding-popup-back-button`}
                />
                <Button
                  appearance="primary"
                  size="medium"
                  text={t("ScaleFactorModalVideoPrimary", { ns: "Desktop" })}
                  callback={() => {
                    sendMessageToPrimary({
                      message: { type: "IFRAME_TO_PRIMARY_clickDoneButtonInScaleModal", payload: null },
                      port: iframePort,
                    });
                    hideModal();
                  }}
                  data-testid={`onboarding-popup-next-button`}
                />
              </ButtonWrapper>
            </GuideTextWrapper>
          </OnboardingPopupWrapper>
        </OnboardingBodyWrapper>
      </BaseModalContainer>
    </ModalPortal>
  );
});

export default ScaleFactorChangeModal;

interface VideoProps {
  visible: boolean;
}

export const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 34px 0px 48px;
`;

export const SlidLogo = styled.img`
  width: 67px;
  cursor: pointer;
`;

export const NavigationOptionButton = styled.div`
  cursor: pointer;
`;

export const OnboardingBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OnboardingPopupWrapper = styled.div`
  max-width: 472px;
  border-radius: 8px;
  box-shadow: var(--boxShadow3);
  margin-top: 28px;
  margin-bottom: 32px;
  background-color: var(--gray1);
  .keep__all {
    word-break: keep-all;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 472px;
  height: 303px;
  border-radius: 8px 8px 0px 0px;
`;

export const Spinner = styled.div`
  color: var(--gray4);
  width: 32px;
  height: 32px;
`;

export const LoadingSpinnerWrapper = styled.div<VideoProps>`
  position: absolute;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--gray17);
  opacity: 0.9;
  width: 472px;
  height: 295px;
  border-radius: 8px 8px 0px 0px;
`;

export const OnboardingVideo = styled.video<VideoProps>`
  display: ${({ visible }) => (visible ? "inline" : "none")};
  width: 472px;
  border-radius: 8px 8px 0px 0px;
`;

export const GuideTextWrapper = styled.div`
  padding: 36px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
  flex-wrap: wrap;
  gap: 8px;
`;
