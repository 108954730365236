// auth 2.0
import env from "config/env";
import Cookies from "js-cookie";

const SLID_WEB_SITE_HOST = env.end_point_url.slid_web_site_host;
const SLID_WEB_APP_HOST = env.end_point_url.slid_web_app_host;

export const setDefaultSettingCookie = ({ property, value, expiresDate = 365 }) => {
  const SlidCustomSettingCookieName = `SlidCustomSetting`;
  const currentCookie = Cookies.get(SlidCustomSettingCookieName) ? JSON.parse(Cookies.get(SlidCustomSettingCookieName)) : {};

  currentCookie[property] = value;

  const expires = expiresDate instanceof Date ? expiresDate : new Date(Date.now() + 1000 * 60 * 60 * 24 * expiresDate);

  Cookies.set(SlidCustomSettingCookieName, currentCookie, {
    expires,
    domain: `${env.currentEnv === "production" ? "." + SLID_WEB_SITE_HOST : SLID_WEB_SITE_HOST}`,
    path: "/",
    secure: true,
    sameSite: "none",
  });
};

export const getDefaultSettingCookie = ({ property }) => {
  const SlidCustomSettingCookieName = `SlidCustomSetting`;
  const currentCookie = Cookies.get(SlidCustomSettingCookieName) ? JSON.parse(Cookies.get(SlidCustomSettingCookieName)) : {};
  return currentCookie[property];
};

export const setCookie = ({ property, value, day }) => {
  Cookies.set(property, value, {
    domain: `${env.currentEnv === "production" ? "." + SLID_WEB_SITE_HOST : SLID_WEB_SITE_HOST}`,
    path: "/",
    // httpOnly: false && env.currentEnv === "production",
    secure: true,
    expires: day,
    sameSite: "none",
  });
};

export const getCookie = ({ cookieName }) => {
  return Cookies.get(cookieName) ? Cookies.get(cookieName) : {};
};

export const removeCognitoCookies = () => {
  const allCookies = Cookies.get();

  Object.keys(allCookies).forEach((cookie) => {
    const reg = /CognitoIdentityServiceProvider/g;
    if (cookie.match(reg)) {
      Cookies.remove(cookie, { path: "/", domain: `${SLID_WEB_APP_HOST}` });
      Cookies.remove(cookie, { path: "/", domain: `${SLID_WEB_SITE_HOST}` });
    }
  });
};
