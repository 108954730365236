import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ButtonText, CaptureButtonContainer, CaptureButton } from "components/VideoCaptureButton/style/buttonStyles";
import { Button, Icon, useModal, Tooltip } from "@slid/slid-ips";
import { OneClickIcon } from "components/icons/OneClickIcon";
import SnipCaptureButtonUI from "components/VideoCaptureButton/ui/SnipCaptureButtonUI";
import OneClickCaptureButtonUI from "components/VideoCaptureButton/ui/OneClickCaptureButtonUI";
import DesktopSmartLiveTextVideoNoteButton from "components/DesktopSmartLiveText/DesktopSmartLiveTextVideoNoteButton";
import { useAppDispatch, useAppSelector } from "hooks";
import { isMacOs } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ModalType, useModalStore } from "store/useModalStore";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { initEventTracking, trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import { sendMessageToPrimary } from "utils/utils";
import { registerClip, setIsDesktopCaptureAreaSet, setSelectableProcesses } from "redux/actions/vdocsActions";
import { setIsDesktopCaptureAreaSetting } from "redux/actions/vdocsActions";
import { uploadBase64Image } from "utils/aws/s3Interface";
import { showErrorOnDesktopModal, showFailToLoadWindowModal, showStopCapturingOnDesktopModal } from "utils/modal";
import { useHistory } from "react-router-dom";
import DesktopCaptureAreaSettingModal from "components/VideoDocument/VideoDocumentEditor/DesktopCaptureAreaSettingModal";
import { getCurrentDocumentKey } from "utils/editor/util";
import useEditorStore from "store/useEditorStore";
import { sendRemoveSnipCaptureAreaRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { setIsSTTToggledOn, setShowSmartLiveTextView, setStopDesktopSTT } from "redux/actions/sttActions";
import { sendAmplitudeData } from "utils/amplitude";
import useOneClickCaptureStore from "components/VideoCaptureButton/utils/useOneClickCaptureStore";
import { INSERT_IMAGE_COMMAND } from "components/NewEditor/plugins/ImagesPlugin";

const DefaultDesktopCaptureButtons = ({ openShowSelectWindowModal, showSelectAreaPopup }: { openShowSelectWindowModal: () => void; showSelectAreaPopup: () => void }) => {
  const { t } = useTranslation();
  const { isSetCaptureAreaButtonClicked, isDesktopCaptureAreaSet, iframePort } = useAppSelector((state) => state.vdocs);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const isDesktopCaptureAreaSetRef = useRef(false);

  useEffect(() => {
    isDesktopCaptureAreaSetRef.current = isDesktopCaptureAreaSet;
  }, [isDesktopCaptureAreaSet]);

  return (
    <DesktopCaptureBarContainer>
      <Tooltip title={t("StartSnipCaptureButtonTooltip", { ns: "Desktop" })} placement={`top`} description={isMacOs ? "(Option + A)" : "(Alt + A)"}>
        <div>
          <DesktopSnipCaptureButton
            appearance="line"
            size="medium"
            isDisabled={isSetCaptureAreaButtonClicked || isDesktopCaptureAreaSet}
            isLoading={!iframePort}
            icon={<Icon icon={`snip28`} height={24} width={24} color={"--gray9"} />}
            text={t("StartSnipCaptureButton", { ns: "Desktop" })}
            color={`--gray9`}
            style={{ borderColor: `var(--gray4)`, backgroundColor: "var(--white)" }}
            callback={() => {
              trackEvent({ eventType: "Click START SNIP CAPTURING in video note" });
              if (!confirmPrivilege(SlidFeatures.snipCapture)) return showInsufficientPrivilegeModal();
              showSelectAreaPopup();
            }}
          />
        </div>
      </Tooltip>
      <Tooltip title={t("StartCaptureButtonTooltip", { ns: "Desktop" })} placement={`top`} description={isMacOs ? "(Cmd + /)" : "(Alt + /)"}>
        <div>
          <SetCaptureAreaButton
            appearance="primary"
            size="medium"
            isDisabled={isSetCaptureAreaButtonClicked || isDesktopCaptureAreaSet}
            isLoading={!iframePort}
            icon={<OneClickIcon height={24} width={24} color={isSetCaptureAreaButtonClicked || isDesktopCaptureAreaSet ? "var(--gray5)" : "var(--white)"} />}
            text={t("StartCaptureButton", { ns: "Desktop" })}
            callback={() => {
              trackEvent({ eventType: "Click START CAPTURING in video note" });
              if (!confirmPrivilege(SlidFeatures.oneClickCapture)) return showInsufficientPrivilegeModal();
              openShowSelectWindowModal();
            }}
          />
        </div>
      </Tooltip>
    </DesktopCaptureBarContainer>
  );
};

const SetCaptureWindowButton = ({ openShowSelectWindowModal }: { openShowSelectWindowModal: () => void }) => {
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { t } = useTranslation();
  const { isCapturing } = useOneClickCaptureStore();

  return (
    <CaptureButtonPositioner>
      <CaptureButton
        onClick={() => {
          if (isCapturing) return;
          openShowSelectWindowModal();
        }}
        onContextMenu={(event) => {
          event.preventDefault();
        }}
      >
        <Tooltip title={lang === "ko" ? "캡처 앱 변경" : "Set capture app"} placement={"top"}>
          <CaptureButtonOverlayArea></CaptureButtonOverlayArea>
        </Tooltip>

        <img src="/src/icons/icon_select_app.svg" alt="" />
        <ButtonText>{t("SetCaptureWindow", { ns: "VideoNote" })}</ButtonText>
      </CaptureButton>
    </CaptureButtonPositioner>
  );
};

const DesktopAppFeatureButtons = () => {
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { isDesktopCaptureAreaSet, iframePort, isSTTSupported, showGuestModeAlert, editorLastActiveBlockPosition, currentVideo } = useAppSelector((state) => state.vdocs);
  const { isSTTToggledOn } = useAppSelector((state) => state.sttReducer);

  const dispatch = useAppDispatch();
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { showModal, closeModal } = useModal();
  const { showModal: showModalStore } = useModalStore();
  const isDesktopCaptureAreaSetRef = useRef(false);
  const history = useHistory();
  const [shouldShowScaleFactorModal, setShouldShowScaleFactorModal] = useState(false);
  const lexicalEditorRef = useEditorStore((state) => state.lexicalEditorRef);
  const editorLastActiveBlockPositionRef = useRef<string | null>(null);
  const { isCapturing, setIsCapturing } = useOneClickCaptureStore();
  useEffect(() => {
    document.addEventListener("keydown", setShortcutListener);

    return () => {
      document.removeEventListener("keydown", setShortcutListener);
    };
  }, []);

  useEffect(() => {
    isDesktopCaptureAreaSetRef.current = isDesktopCaptureAreaSet;
  }, [isDesktopCaptureAreaSet]);

  const setShortcutListener = (event) => {
    const code = event.code;
    const states = {
      alt: event.altKey,
      ctrl: event.ctrlKey,
      meta: event.metaKey,
      shift: event.shiftKey,
    };
    switch (code) {
      // short cut for manual capture (Cmd + / or Alt + /)
      case "Slash":
        if (states.alt || states.meta) {
          event.preventDefault();
          if (isCapturing) return;
          onClickOneClickCaptureButton();
          sendAmplitudeData(`Use shortcut`, {
            type: `one-click capture`,
          });
        }
        break;

      default:
        return;
    }
  };

  const openShowSelectWindowModal = () => {
    if (shouldShowScaleFactorModal) {
      showModalStore({ type: ModalType.DESKTOP_SCALE_FACTOR_CHANGE });
      return;
    }
    // Turn off STT if it's on
    if (isSTTToggledOn) {
      dispatch(setIsSTTToggledOn(false));
      dispatch(setStopDesktopSTT());
      dispatch(setShowSmartLiveTextView(false));
    }
    showModalStore({ type: ModalType.DESKTOP_SELECT_WINDOW });
  };

  useEffect(() => {
    editorLastActiveBlockPositionRef.current = editorLastActiveBlockPosition;
  }, [editorLastActiveBlockPosition]);

  const getImageBlockDataWithoutTimestamp = async (imgBase64, currentDocumentKey, fullImageBase64 = null) => {
    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: imgBase64,
    });
    if (!uploadedImageSrc || typeof uploadedImageSrc !== "string") return;

    const clipRegisterResponse: any = await dispatch(
      registerClip({
        imgSrc: uploadedImageSrc,
        clipTimestamp: null,
        documentKey: currentDocumentKey,
        videoKey: currentVideo?.videoKey || null,
        clipEndPoint: "capture-clip",
      })
    );
    if (!clipRegisterResponse) return;

    let imgObj = {
      src: uploadedImageSrc,
      fullSizeImageSrcForCropping: uploadedImageSrc, // For supporting image cropping in desktop app
      documentKey: currentDocumentKey,
      clipKey: clipRegisterResponse["clip_key"],
      type: "manualCapture",
    };

    return imgObj;
  };

  const captureResponseHandlerWithoutTimestamp = async (imgBase64: string) => {
    const insertCapturedImage = async (currentDocumentKey) => {
      setIsCapturing(true);

      lexicalEditorRef.current?.update(() => {
        const imageData = {
          src: imgBase64,
          sourceType: "manualCapture",
          clipKey: "",
          originalImageSrc: imgBase64,
          isUploaded: false,
          setClipData: async () => {
            return await getImageBlockDataWithoutTimestamp(imgBase64, currentDocumentKey);
          },
        };

        lexicalEditorRef.current?.dispatchCommand(INSERT_IMAGE_COMMAND, imageData);
      });

      setIsCapturing(false);
      sendRemoveSnipCaptureAreaRequestToParentWindow();
    };
    if (!lexicalEditorRef.current) {
      setIsCapturing(false);
      return;
    }

    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return;
    history.replace(`./${currentDocumentKey}`);
    insertCapturedImage(currentDocumentKey);
  };

  const showSelectAreaPopup = () => {
    trackEvent({
      eventType: "Click snip capture",
      eventProperties: {
        depth: applicationType === "desktop" ? (isDesktopCaptureAreaSetRef.current ? 2 : 1) : undefined,
      },
    });
    if (!confirmPrivilege(SlidFeatures.snipCapture)) return showInsufficientPrivilegeModal();

    const didSendCaptureMessageToPrimary = sendMessageToPrimary({ message: { type: "IFRAME_TO_PRIMARY_DRAG_AND_DROP_CAPTURE", payload: null }, port: iframePort });
    if (!didSendCaptureMessageToPrimary) {
      showModalStore({
        type: ModalType.DESKTOP_GUIDE_TO_RESTART,
      });
      return;
    }
  };

  const onClickSnipCaptureButton = () => {
    showSelectAreaPopup();
  };

  const onClickOneClickCaptureButton = () => {
    if (showGuestModeAlert) return;
    // here the button is blue
    if (isDesktopCaptureAreaSetRef.current) {
      const didSendMessageToPrimary = sendMessageToPrimary({ message: { type: "IFRAME_TO_PRIMARY_CAPTURE", payload: null }, port: iframePort });
      if (didSendMessageToPrimary) {
        setIsCapturing(true);
      } else {
        showModalStore({
          type: ModalType.DESKTOP_GUIDE_TO_RESTART,
        });
      }
    } else {
      openShowSelectWindowModal();
    }
  };

  const onClickSettingButton = (e) => {
    e.stopPropagation();
    trackEvent({
      eventType: "Click capture setting button",
    });
    sendMessageToPrimary({ message: { type: "IFRAME_TO_PRIMARY_START_SETTING_CAPTURE_AREA", payload: null }, port: iframePort });
  };

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      /*
      Listen to the messages from the Primary window of Slid Desktop
      */
      switch (event.data.type) {
        case "PRIMARY_TO_IFRAME_ACTIVATE_CAPTURE_BUTTON":
          dispatch(setIsDesktopCaptureAreaSetting(false));
          dispatch(setIsDesktopCaptureAreaSet(true));
          break;
        case "PRIMARY_TO_IFRAME_SPIN_CAPTURE_BUTTON":
          setIsCapturing(true);
          break;
        case "PRIMARY_TO_IFRAME_STOP_SPINNING_CAPTURE_BUTTON":
          setIsCapturing(false);
          break;
        case "PRIMARY_TO_IFRAME_DEACTIVATE_CAPTURE_BUTTON":
          dispatch(setIsDesktopCaptureAreaSet(false));
          break;
        case "PRIMARY_TO_IFRAME_SEND_IMAGE":
          const imgBase64 = event.data.payload;
          captureResponseHandlerWithoutTimestamp(imgBase64);
          break;
        case "PRIMARY_TO_IFRAME_CAPTURE_FAILED":
          showModalStore({
            type: ModalType.DESKTOP_GUIDE_TO_RESTART,
          });
          setIsCapturing(false);
          break;
        case "PRIMARY_TO_IFRAME_SEND_SELECTABLE_PROCESSES":
          const sources = event.data.payload;
          dispatch(setSelectableProcesses(sources));
          break;
        case "PRIMARY_TO_IFRAME_CANCEL_SETTING_CAPTURE_AREA_FIRST":
          dispatch(setIsDesktopCaptureAreaSetting(false));
          break;
        case "PRIMARY_TO_IFRAME_SELECTED_PROCESS_UNAVAILABLE":
          dispatch(setIsDesktopCaptureAreaSet(false));
          showFailToLoadWindowModal(
            {
              showModal,
              closeModal,
              history,
            },
            openShowSelectWindowModal
          );
          break;
        case "PRIMARY_TO_IFRAME_SELECTED_PROCESS_TERMINATED":
          dispatch(setIsDesktopCaptureAreaSet(false));
          break;
        case "PRIMARY_TO_IFRAME_SEND_ADI":
          initEventTracking(event.data.payload);
          break;
        case "PRIMARY_TO_IFRAME_handleError":
          dispatch(setIsDesktopCaptureAreaSet(false));
          showErrorOnDesktopModal({
            showModal,
            closeModal,
          });
          break;
        case "PRIMARY_TO_IFRAME_showStopCapturingOnDesktopModal":
          showStopCapturingOnDesktopModal({
            showModal,
            closeModal,
            dispatch,
          });
          break;
        case "PRIMARY_TO_IFRAME_sendShouldShowScaleFactorModal":
          setShouldShowScaleFactorModal(event.data.payload);
          break;
        case "PRIMARY_TO_IFRAME_trackToggleNSnippersByShortcut":
          trackEvent({
            eventType: "Use shortcut",
            eventProperties: {
              type: `snip capture`,
              isCaptureAreaFixed: isDesktopCaptureAreaSetRef.current,
            },
          });
          break;
        default:
          return;
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  return (
    <>
      <DesktopCaptureAreaSettingModal openShowSelectWindowModal={openShowSelectWindowModal} />
      {isDesktopCaptureAreaSet ? (
        <CaptureButtonContainer>
          <SnipCaptureButtonUI onClick={onClickSnipCaptureButton} />
          <OneClickCaptureButtonUI onClickCaptureButton={onClickOneClickCaptureButton} onClickSettingButton={onClickSettingButton} />
          {isSTTSupported && <DesktopSmartLiveTextVideoNoteButton />}
          <SetCaptureWindowButton openShowSelectWindowModal={openShowSelectWindowModal} />
        </CaptureButtonContainer>
      ) : (
        <DefaultDesktopCaptureButtons openShowSelectWindowModal={openShowSelectWindowModal} showSelectAreaPopup={showSelectAreaPopup} />
      )}
    </>
  );
};

const DesktopCaptureBarContainer = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  gap: 10px;
  bottom: 20px;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  z-index: 1;
`;

const DesktopSnipCaptureButton = styled(Button)`
  && {
    display: flex;
    z-index: 1;
    filter: drop-shadow(var(--boxShadow1));

    :hover {
      background-color: var(--gray4) !important; // Without important, inline style (white background) is prioritized.
      -webkit-transition: background-color 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out;
    }
  }
`;

const SetCaptureAreaButton = styled(Button)`
  display: flex;
  z-index: 1;
  filter: drop-shadow(var(--boxShadow1));
`;

const CaptureButtonPositioner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CaptureButtonOverlayArea = styled.div`
  position: absolute;
  left: 0px;
  top: 0;
  width: 80px;
  height: 52px;
`;

export default DesktopAppFeatureButtons;
