import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { KEY_DOWN_COMMAND, COMMAND_PRIORITY_LOW, $getSelection, TextNode, $getNodeByKey } from "lexical";
import useEditorStore from "store/useEditorStore";
import { debounce } from "lodash";
import { SlidImageNode } from "components/NewEditor/nodes/SlidImageNode/ImageNode";
import { TableNode } from "@lexical/table";
import { HorizontalRuleNode } from "components/NewEditor/nodes/HorizontalRuleNode";
import { SlidLinkPreviewNode } from "components/NewEditor/nodes/SlidLinkPreviewNode";
import { EquationNode } from "components/NewEditor/nodes/EquationNode/EquationNode";
import { CodeNode } from "@lexical/code";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import { useSLTButton } from "hooks/whisperSLTRealTime/useSLTButton";
import { useAppSelector } from "hooks";
import { useDesktopSLTButton } from "hooks/desktopSLT/useDesktopSLTButton";
import { SlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { SlidHeadingNode } from "components/NewEditor/nodes/SlidHeadingNode";
import { ListItemNode } from "@lexical/list";

const HEADER_HEIGHT = 56;

function ScrollControlPlugin() {
  const [editor] = useLexicalComposerContext();
  const { editorScrollWrapperRef } = useEditorStore();
  const { isSTTActive, isManualAddingMode } = useWhisperSLTStore();
  const { SLTButtonStatus } = useSLTButton();
  const { isSTTActive: desktopIsSTTActive, isManualAddingMode: desktopIsManualAddingMode } = useAppSelector((state) => state.sttReducer);
  const { SLTButtonStatus: desktopSLTButtonStatus } = useDesktopSLTButton();
  const applicationType = useAppSelector((state) => state.slidGlobal.applicationType);
  const { isAutoNotesActive, isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);
  const isExtensionSLTActiveRef = useRef(false);
  const isExtensionAutoNoteActiveRef = useRef(false);
  const isDesktopSLTActiveRef = useRef(false);
  const isImageNodeCreatedRef = useRef(false);

  useEffect(() => {
    if (isSTTActive && !isManualAddingMode && SLTButtonStatus === "recording" && applicationType === "extension") {
      isExtensionSLTActiveRef.current = true;
    } else {
      isExtensionSLTActiveRef.current = false;
    }
  }, [isSTTActive, isManualAddingMode, SLTButtonStatus, applicationType]);

  useEffect(() => {
    if (isAutoNotesActive && isAutoNotesToggledOn && applicationType === "extension") {
      isExtensionAutoNoteActiveRef.current = true;
    } else {
      isExtensionAutoNoteActiveRef.current = false;
    }
  }, [isAutoNotesActive, isAutoNotesToggledOn, applicationType]);

  useEffect(() => {
    if (desktopIsSTTActive && !desktopIsManualAddingMode && desktopSLTButtonStatus === "recording" && applicationType === "desktop") {
      isDesktopSLTActiveRef.current = true;
    } else {
      isDesktopSLTActiveRef.current = false;
    }
  }, [desktopIsSTTActive, desktopIsManualAddingMode, desktopSLTButtonStatus, applicationType]);

  useEffect(() => {
    const removeImageNodeListener = editor.registerMutationListener(SlidImageNode, (mutations) => {
      mutations.forEach((mutation, key) => {
        if (mutation === "created") {
          isImageNodeCreatedRef.current = true;
        }
      });
    });

    const removeTableNodeListener = editor.registerMutationListener(TableNode, (mutations) => {
      mutations.forEach((mutation, key) => {
        if (mutation === "created") {
          handleScroll();
        }
      });
    });

    const removeLinkPreviewNodeListener = editor.registerMutationListener(SlidLinkPreviewNode, (mutations) => {
      mutations.forEach((mutation, key) => {
        if (mutation === "created") {
          handleScroll();
        }
      });
    });

    const removeCodeNodeListener = editor.registerMutationListener(CodeNode, (mutations) => {
      mutations.forEach((mutation, key) => {
        if (mutation === "created") {
          handleScroll();
        }
      });
    });

    const removeParagraphNodeListener = editor.registerMutationListener(SlidParagraphNode, (mutations) => {
      mutations.forEach((mutation, key) => {
        if (mutation === "created") {
          if (!isImageNodeCreatedRef.current) {
            handleScroll();
          } else {
            // NOTE: When image node is created, we also create a paragraph node below, we need to handle the scroll here.
            // Since the image is loaded after handleScroll on paragraph node is triggered it scrolls to wrong y position, we need to delay the scroll.
            isImageNodeCreatedRef.current = false;
            setTimeout(() => {
              handleScroll();
            }, 200);
          }
        }
      });
    });

    const removeHeadingNodeListener = editor.registerMutationListener(SlidHeadingNode, (mutations) => {
      mutations.forEach((mutation, key) => {
        if (mutation === "created") {
          handleScroll();
        }
      });
    });

    const removeListNodeListener = editor.registerMutationListener(ListItemNode, (mutations) => {
      mutations.forEach((mutation, key) => {
        if (mutation === "created") {
          handleScroll();
        }
      });
    });

    return () => {
      removeImageNodeListener();
      removeTableNodeListener();
      removeLinkPreviewNodeListener();
      removeCodeNodeListener();
      removeParagraphNodeListener();
      removeHeadingNodeListener();
      removeListNodeListener();
    };
  }, [editor]);

  useEffect(() => {
    const unregister = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key === "Backspace") {
          debouncedHandleScroll();
          return false;
        }
        return false;
      },
      COMMAND_PRIORITY_LOW
    );

    return () => {
      unregister();
    };
  }, [editor]);

  const debouncedHandleScroll = debounce(handleScroll, 100);

  function handleScroll() {
    editor.read(() => {
      const container = editorScrollWrapperRef.current;
      if (!container) return;
      const scrollPosition = container.scrollTop;
      const containerHeight = container.clientHeight;
      const currentNodeKey = $getSelection()?.getNodes()[0]?.getKey();
      if (!currentNodeKey) return;
      const currentNodeElement = editor.getElementByKey(currentNodeKey);
      const currentNodeBottom = (currentNodeElement?.getBoundingClientRect().bottom as number) - HEADER_HEIGHT;
      const currentNodeAbsoluteBottom = currentNodeBottom + scrollPosition;
      const nativeSelection = window.getSelection() || null;

      let nativeSelectionTop = 0;
      // NOTE: when keyboard event is triggered on non-text node, set nativeSelectionTop to 0
      if (nativeSelection && nativeSelection.rangeCount > 0) {
        nativeSelectionTop = nativeSelection.getRangeAt(0).getBoundingClientRect().top;
      }
      const nativeSelectionAbsoluteTop = nativeSelectionTop + scrollPosition;

      const upperThreshold = containerHeight * 0.15;
      const lowerThreshold = isExtensionSLTActiveRef.current || isExtensionAutoNoteActiveRef.current || isDesktopSLTActiveRef.current ? containerHeight * 0.7 : containerHeight * 0.85;

      if (nativeSelectionTop === 0) {
        // when cursor is at an empty line, get the yPos of the node instead
        if (currentNodeBottom < upperThreshold) {
          container.scrollTo({ top: currentNodeAbsoluteBottom - upperThreshold });
        } else if (currentNodeBottom > lowerThreshold) {
          container.scrollTo({ top: currentNodeAbsoluteBottom - lowerThreshold });
        }
      } else {
        if (nativeSelectionTop < upperThreshold) {
          container.scrollTo({ top: nativeSelectionAbsoluteTop - upperThreshold });
        } else if (nativeSelectionTop > lowerThreshold) {
          container.scrollTo({ top: nativeSelectionAbsoluteTop - lowerThreshold });
        }
      }
    });
  }

  return null;
}

export default ScrollControlPlugin;
