/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from "react";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import SmartLiveTextModeToggleButton from "./SmartLiveTextModeToggleButton";
import DesktopSmartLiveTextViewHeader from "./DesktopSmartLiveTextViewHeader";
import DesktopSmartLiveTextViewBody from "./DesktopSmartLiveTextViewBody";
import { useInitializeSLTDesktop } from "hooks/desktopSLT/useInitializeSLTDesktop";
import useSLTData from "hooks/desktopSLT/useSLTData";

export interface TranscriptBlock {
  text: string;
  timeStamp?: string;
  isFinal: boolean;
  isTyping: boolean;
  isWhisperTranscribed?: boolean;
  elementId?: string;
  id?: string;
}

const DesktopSmartLiveTextView = memo(() => {
  useInitializeSLTDesktop();
  useSLTData();
  const { showSmartLiveTextView } = useAppSelector((state) => state.sttReducer);

  return (
    <TranscriptContainer showSmartLiveTextView={showSmartLiveTextView}>
      {showSmartLiveTextView && (
        <>
          <DesktopSmartLiveTextViewHeader />
          <SmartLiveTextModeToggleButton />
          <DesktopSmartLiveTextViewBody />
        </>
      )}
    </TranscriptContainer>
  );
});

const TranscriptContainer = styled.div<{ showSmartLiveTextView: boolean }>`
  border-left: ${({ showSmartLiveTextView }) => (showSmartLiveTextView ? "1px solid var(--gray17)" : "0")};
  height: 100%;
  max-width: 400px;
  min-width: ${({ showSmartLiveTextView }) => (showSmartLiveTextView ? "400px" : "0")};
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--gray19);

  * {
    &::selection {
      background: rgba(27, 100, 218, 0.6) !important;
    }
  }
`;

export default DesktopSmartLiveTextView;
