import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon, Typography13, Typography15 } from "@slid/slid-ips";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { setShowSearchModal } from "redux/actions/searchActions";
import { useSearch } from "hooks";
import HighlightText from "./HighlightText";
import { SlidFolderDocument } from "types/document";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

interface SearchFolderItemProps {
  folder: SlidFolderDocument;
}

const SearchFolderItem = ({ folder }: SearchFolderItemProps) => {
  const { t } = useTranslation("EditorComponent");
  const dispatch = useAppDispatch();
  const { searchKeywords } = useAppSelector((state) => state.search);
  const { getSearchFolderPathMap } = useSearch();
  const [folderPathMap, setFolderPathMap] = useState("");

  useEffect(() => {
    let folderPathMap = getSearchFolderPathMap();
    setFolderPathMap(folderPathMap[folder.document_key]?.pathText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <ResultItemWrapper
      id={folder.document_key}
      to={`/docs?folder=${folder.document_key}`}
      onClick={() => {
        trackEvent({
          eventType: eventTypes.click.FOLDER_IN_SEARCH_RESULTS,
          eventProperties: {
            location: "search modal",
            from: "My Notes",
          },
        });
        dispatch(setShowSearchModal(false));
      }}
    >
      <IconWrapper>
        <Icon icon="filledFolder24" color="--gray7" width={20} height={20} />
      </IconWrapper>
      <ResultItemContentWrapper>
        {!folder.title ? (
          <Typography15 text={t("Untitled")} color={"--gray9"} weight={700} />
        ) : searchKeywords ? (
          <HighlightText highLightText={searchKeywords} contents={folder.title} type={"title"} />
        ) : (
          <Typography15 text={folder.title} color={"--gray9"} weight={700} />
        )}
        <Typography13 text={folderPathMap} color={"--gray9"} />
      </ResultItemContentWrapper>
    </ResultItemWrapper>
  );
};

export const ResultItemWrapper = styled(Link)`
  width: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 24px;
  gap: 12px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: var(--gray3);
    text-decoration: none;
  }

  &:active {
    background-color: var(--gray2);
  }
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
`;

export const ResultItemContentWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default SearchFolderItem;
