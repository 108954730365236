import { LexicalNode, NodeKey, ParagraphNode, SerializedParagraphNode, Spread } from "lexical";

export type LegacySlidParagraph = {
  type: "paragraph";
  id: string;
  data: {
    text: string;
  };
};

export type SerializedSlidParagraphNode = Spread<LegacySlidParagraph, SerializedParagraphNode>;

export class SlidParagraphNode extends ParagraphNode {
  constructor(key?: NodeKey) {
    super(key);
  }

  static getType() {
    return "slid-paragraph";
  }

  static clone(node: SlidParagraphNode) {
    return new SlidParagraphNode(node.__key);
  }

  static importJSON(serializedNode: SerializedSlidParagraphNode) {
    const node = $createSlidParagraphNode();
    node.setTextFormat(serializedNode.textFormat);
    node.setTextStyle(serializedNode.textStyle);
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);

    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      textFormat: this.getTextFormat(),
      textStyle: this.getTextStyle(),
      type: "slid-paragraph",
      version: 1,
    };
  }
}

export function $createSlidParagraphNode() {
  return new SlidParagraphNode();
}

export function $isSlidParagraphNode(node: LexicalNode | null | undefined): node is SlidParagraphNode {
  return node instanceof SlidParagraphNode;
}
