import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button, Link, Typography17, Typography34, Typography60, useModal } from "@slid/slid-ips";
import env from "config/env";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

export const OnboardingPromotionModal = () => {
  const { t } = useTranslation("Modal");
  const { closeModal } = useModal();

  useEffect(() => {
    trackEvent({
      eventType: eventTypes.show.TUTORIAL_COMPLETED_DISCOUNT_MODAL,
    });
  }, []);

  return (
    <ModalContainer>
      <TextContainer>
        <Typography60 weight={700} color="--gray17" text={"🥳"} textAlign="center" />
        <Typography34 weight={700} color="--gray17" text={t("OnboardingPromotionModal.title", { keySeparator: "." })} textAlign="center" />
        <Typography17 weight={400} color="--gray15" text={t("OnboardingPromotionModal.description", { keySeparator: "." })} />
      </TextContainer>
      <Link
        linkText={t("OnboardingPromotionModal.linkText", { keySeparator: "." })}
        marginTop="48px"
        newTab={true}
        href={`${SLID_WEB_APP_URL}/promotions?type=TutorialEvent`}
        onClick={() => {
          trackEvent({
            eventType: eventTypes.click.GET_DISCOUNT_WITH_THIS_LINK_IN_TUTORIAL_COMPLETED_DISCOUNT_MODAL,
          });
        }}
        style={{ cursor: "pointer" }}
      />

      <Button
        appearance="primary"
        size="large"
        text={t("OnboardingPromotionModal.primaryButton", { keySeparator: "." })}
        marginTop="24px"
        callback={() => {
          closeModal();
        }}
      />
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  width: 464px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 36px;

  background-color: var(--gray1);
  box-shadow: var(--boxShadow3);
  border-radius: 8px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
