import { useEffect, useState, useRef } from "react";
import { fabric } from "fabric";
import "fabric-history";
import { MarkupType, MarkupSizeType, DrawingModeType } from "types/editor";
import { useTranslation } from "react-i18next";
import { setActiveMarkupObject, setMarkupColor, setMarkupHighlighterSize, setMarkupPenSize, setMarkupTool, setShowHighlighterColorGrid } from "redux/actions/editorActions";
import { useUpdateEffect } from "utils/customHook/customHook";
import { showImageMarkupCancelModal, showImageMarkupResetModal } from "utils/modal/alertModal";
import { useModal } from "@slid/slid-ips";
import * as Sentry from "@sentry/browser";
import { updateClip } from "redux/actions/vdocsActions";
import { uploadBase64Image } from "utils/aws/s3Interface";
import { alertImageMarkupNotSaved } from "components/alerts";
import { sendAmplitudeData } from "utils/amplitude";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector, useImageMarkup } from "hooks";
import { ModalType, useModalStore } from "store/useModalStore";
import { useMediaQuery } from "react-responsive";
import { $isImageNode, ImagePayload, SlidImageNode } from "components/NewEditor/nodes/SlidImageNode/ImageNode";
import { useImageNodeStore } from "components/NewEditor/store/useImageNodeStore";
import { useSaveDocumentAndCreateHistory } from "./useSaveDocumentAndCreateHistory";
import useEditorStore from "store/useEditorStore";
import { $getNodeByKey } from "lexical";

const KEY_SPACE = 32;
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_ENTER = 13;
const KEY_Z = 90;

interface markupCanvasRectProps {
  originWidth: number;
  originHeight: number;
  width: number;
  height: number;
}

interface Props {
  src: string;
  markupImageSrc?: string;
  blockIndex: number;
  saveDocument: () => void;
  clipKey?: string;
  fullSizeImageSrcForCropping: string;
  originalImageSrc: string;
  imageCropArea?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

const STATIC_TOOLBAR_HEIGHT = 296;

export const useNewImageMarkup = () => {
  const { selectedImageData: imageData, setShowImageMarkupPopup, selectedImageNodeKey } = useImageNodeStore();
  const { saveDocument } = useSaveDocumentAndCreateHistory();
  const { currentDocument, editorWrapperClassName } = useAppSelector((state) => state.vdocs);
  const { markupTool, markupColor, markupHighlighterSize, markupPenSize, markupPenMode, markupHighlighterMode, activeMarkupObject, showHighlighterColorGrid } = useAppSelector((state) => state.editor);
  const dispatch = useAppDispatch();
  const { showModal, closeModal } = useModal();
  const { showModal: showGlobalModal, hideModal } = useModalStore();
  const isMobileUI = useMediaQuery({ query: "(max-width: 799px)" });
  const lexicalEditorRef = useEditorStore((state) => state.lexicalEditorRef);
  const [isTextEditingMode, setIsTextEditingMode] = useState(false);
  const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [canReset, setCanReset] = useState(false);
  const [isConfirmResetButtonClicked, setIsConfirmResetButtonClicked] = useState(false);
  const [isPreviousMarkupExist, setIsPreviousMarkupExist] = useState(false);
  const location = useLocation();
  const isVideoNote = location.pathname.split("/")[1] === "vdocs";

  const { t } = useTranslation(["EditorComponent", "Modal"]);

  const markupCanvasInstance = useRef<any>(null);
  const canvasWrapperContainer = useRef<HTMLDivElement>(null);
  const markupCanvasRect = useRef<markupCanvasRectProps | null>(null);

  // set canvas instance
  useEffect(() => {
    const configMarkupCanvas = () => {
      markupCanvasInstance.current = new fabric.Canvas("markup-canvas", {
        isDrawingMode: true,
        backgroundColor: "const(--gray18)",
        enableRetinaScaling: true,
        defaultCursor: `url("/src/design/assets/default_markup_cursor.svg"), auto`,
        selection: false,
        imageSmoothingEnabled: false,
      });
      markupCanvasInstance.current.freeDrawingBrush.color = "";
      markupCanvasInstance.current.transparentCorners = false;
      markupCanvasInstance.current.cornerColor = "const(--blue3)";

      if (canvasWrapperContainer.current) canvasWrapperContainer.current.tabIndex = -1;

      markupCanvasInstance.current.on("history:append", updateHistoryStates);
      markupCanvasInstance.current.on("history:undo", updateHistoryStates);
      markupCanvasInstance.current.on("history:redo", updateHistoryStates);
      markupCanvasInstance.current.on("history:clear", updateHistoryStates);

      markupCanvasInstance.current.on("object:added", handleAddObject);
      markupCanvasInstance.current.on("selection:created", handleSelectObject);
      markupCanvasInstance.current.on("selection:updated", handleSelectObject);
      markupCanvasInstance.current.on("selection:cleared", () => {
        dispatch(setShowHighlighterColorGrid(false));
      });
    };

    const updateHistoryStates = () => {
      const currentBackgroundImg = markupCanvasInstance.current.backgroundImage && markupCanvasInstance.current.backgroundImage["_originalElement"].currentSrc;
      setCanReset(currentBackgroundImg !== imageData?.src || markupCanvasInstance.current.historyUndo.length > 0);
      setCanUndo(markupCanvasInstance.current.historyUndo.length > 0);
      setCanRedo(markupCanvasInstance.current.historyRedo.length);
    };

    const handleAddObject = (object: any) => {
      object.target.set({
        transparentCorners: false,
        cornerSize: 10,
        borderColor: "#C1E1FC",
        cornerColor: "#C1E1FC",
        evented: object.target.type === "i-text",
      });
      markupCanvasInstance.current.discardActiveObject().renderAll();
      // send highlighter object to back when it is added
      if (object.target?.stroke?.includes("0.3)")) {
        markupCanvasInstance.current.sendToBack(object.target);
      }
    };

    const handleSelectObject = (obj: any) => {
      const activeObject = obj.selected[0];
      dispatch(setActiveMarkupObject(activeObject));
      switch (activeObject.type) {
        case "i-text":
          dispatch(setShowHighlighterColorGrid(activeObject.fill.includes("0.3)")));
          break;
        case "line":
        case "path":
          dispatch(setShowHighlighterColorGrid(activeObject.stroke?.includes("0.3)")));
          break;
        default:
          break;
      }
    };

    // disable scrolling on background
    document.body.style.overflow = "hidden";
    configMarkupCanvas();

    return () => {
      document.body.style.removeProperty("overflow");
      markupCanvasInstance.current = null;
      dispatch(setMarkupHighlighterSize(MarkupSizeType.Medium));
      dispatch(setMarkupPenSize(MarkupSizeType.Thin));
      dispatch(setMarkupTool(MarkupType.Pen));
      setShowImageMarkupPopup(false);
      dispatch(
        setMarkupColor({
          pen: "rgba(236, 76, 58, 1)",
          highlighter: "rgba(253, 129, 255, 1)",
          text: "rgba(68, 125, 247, 1)",
        })
      );
      dispatch(setShowHighlighterColorGrid(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set canvas listeners
  useEffect(() => {
    let line;
    let isLineDrawingMode;
    let isLineDraggingMode;

    const addTextbox = (pointer: { x: number; y: number }) => {
      const textbox = new fabric.IText(t("EnterText"), {
        width: 100,
        padding: 10,
        top: pointer.y,
        left: pointer.x,
        fontSize: 18,
        textAlign: "center",
        fontFamily: "SlidTextStyle",
        charSpacing: 50,
        fill: markupColor.text.replace(",0.3)", ",1)"),
      });

      textbox.on("added", () => {
        setIsTextEditingMode(true);
        textbox.selectAll();
        textbox.enterEditing();
        textbox.hiddenTextarea?.focus();
      });

      textbox.on("editing:exited", () => {
        setIsTextEditingMode(false);
      });

      markupCanvasInstance.current.add(textbox);
      markupCanvasInstance.current.setActiveObject(textbox);
    };

    const addLine = (event: any) => {
      isLineDrawingMode = true;
      if (line && !isLineDraggingMode) {
        isLineDrawingMode = false;
        line.setCoords();
        line = null;
        return;
      }
      const pointer = markupCanvasInstance.current.getPointer(event.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];

      if (isLineDrawingMode) {
        line = new fabric.Line(points, {
          strokeWidth: getBrushSize(),
          stroke: markupTool === MarkupType.Highlighter ? markupColor.highlighter.replace("1)", "0.3)") : markupColor.pen,
          strokeLineCap: "round",
          strokeLineJoin: "round",
          originX: "center",
          originY: "center",
          hasBorders: false,
          hasControls: false,
        });

        markupCanvasInstance.current.add(line);
      }
    };

    const handleMouseDown = (event: any) => {
      // prevent adding object when clicking on the object
      if (!event.target) {
        dispatch(setActiveMarkupObject(null));
      }
      if (event.target || isTextEditingMode || activeMarkupObject) return;
      switch (markupTool) {
        case MarkupType.Pen:
          if (markupPenMode !== DrawingModeType.Line) return;
          addLine(event);
          break;
        case MarkupType.Highlighter:
          if (markupHighlighterMode !== DrawingModeType.Line) return;
          addLine(event);
          break;
        case MarkupType.Text:
          addTextbox(event.pointer);
          break;
        default:
          break;
      }
    };

    const handleMouseMove = (event: any) => {
      if (!markupCanvasInstance.current || !isLineDrawingMode || !line) return;

      isLineDraggingMode = true;
      const pointer = markupCanvasInstance.current.getPointer(event.e);
      line.set({ x2: pointer.x, y2: pointer.y });
      markupCanvasInstance.current.renderAll();
    };

    const handleMouseUp = () => {
      if (line && isLineDraggingMode && isLineDrawingMode) {
        isLineDrawingMode = false;
        isLineDraggingMode = false;
        line.setCoords();
        line = null;
        return;
      }
    };

    const handleSelect = (obj: any) => {
      if (markupTool !== MarkupType.Eraser) return;
      const activeObject = obj.selected[0];
      markupCanvasInstance.current.remove(activeObject);
    };

    markupCanvasInstance.current.on("selection:created", handleSelect);
    markupCanvasInstance.current.on("mouse:down", handleMouseDown);
    markupCanvasInstance.current.on("mouse:move", handleMouseMove);
    markupCanvasInstance.current.on("mouse:up", handleMouseUp);

    return () => {
      if (!markupCanvasInstance.current) return;
      markupCanvasInstance.current.off("selection:created", handleSelect);
      markupCanvasInstance.current.off("mouse:down", handleMouseDown);
      markupCanvasInstance.current.off("mouse:move", handleMouseMove);
      markupCanvasInstance.current.off("mouse:up", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupTool, isTextEditingMode, markupPenMode, markupHighlighterMode, markupColor, activeMarkupObject, markupPenSize, markupHighlighterSize]);

  // set brush size and color depends on markup tool
  useEffect(() => {
    const changeAction = () => {
      switch (markupTool) {
        case MarkupType.Select:
          markupCanvasInstance.current.isDrawingMode = false;
          markupCanvasInstance.current.selection = true;
          break;
        case MarkupType.Eraser:
          markupCanvasInstance.current.discardActiveObject().renderAll();
          markupCanvasInstance.current.isDrawingMode = false;
          markupCanvasInstance.current.selection = false;
          break;
        case MarkupType.Pen:
          markupCanvasInstance.current.freeDrawingBrush = new fabric.PencilBrush(markupCanvasInstance.current);
          markupCanvasInstance.current.isDrawingMode = markupPenMode !== DrawingModeType.Line;
          markupCanvasInstance.current.selection = false;
          break;
        case MarkupType.Highlighter:
          markupCanvasInstance.current.freeDrawingBrush = new fabric.PencilBrush(markupCanvasInstance.current);
          markupCanvasInstance.current.isDrawingMode = markupHighlighterMode !== DrawingModeType.Line;
          markupCanvasInstance.current.selection = false;
          break;
        default:
          markupCanvasInstance.current.isDrawingMode = false;
          markupCanvasInstance.current.selection = false;
          break;
      }
    };

    const updateEvented = () => {
      if (markupTool === MarkupType.Select || markupTool === MarkupType.Text || markupTool === MarkupType.Eraser) {
        markupCanvasInstance.current.getObjects().forEach((obj: any) => {
          obj.set({
            evented: true,
            hasBorders: true,
            hasControls: true,
          });
        });
      } else {
        markupCanvasInstance.current.getObjects().forEach((obj: any) => {
          obj.set({
            evented: false,
            hasBorders: false,
            hasControls: false,
          });
        });
      }
    };

    if (markupCanvasInstance.current) {
      updateEvented();
      changeAction();
      const brushWidth = getBrushSize();

      markupCanvasInstance.current.freeDrawingBrush.width = brushWidth;

      const newMarkupColor = markupTool === MarkupType.Highlighter ? markupColor.highlighter.replace("1)", "0.3)") : markupTool === MarkupType.Pen ? markupColor.pen : "";
      markupCanvasInstance.current.freeDrawingBrush.color = newMarkupColor;

      if (markupCanvasInstance.current.isDrawingMode) {
        markupCanvasInstance.current.discardActiveObject().renderAll();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupTool, markupColor, markupPenMode, markupHighlighterMode, markupPenSize, markupHighlighterSize]);

  useUpdateEffect(() => {
    if (!markupCanvasInstance.current) return;
    dispatch(setActiveMarkupObject(null));
  }, [markupTool]);

  // set cursor depends on markup tool
  // TODO: refactor this
  useEffect(() => {
    if (!markupCanvasInstance.current) return;
    if (markupTool === MarkupType.Pen) {
      if (markupPenSize === MarkupSizeType.Thin) {
        markupCanvasInstance.current.freeDrawingCursor = `url("/src/design/assets/pen_thin_cursor.svg") 3 2, auto`;
        markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/pen_thin_cursor.svg") 3 2, auto`;
      } else if (markupPenSize === MarkupSizeType.Medium) {
        markupCanvasInstance.current.freeDrawingCursor = `url("/src/design/assets/pen_medium_cursor.svg") 3 3, auto`;
        markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/pen_medium_cursor.svg") 3 3, auto`;
      } else {
        markupCanvasInstance.current.freeDrawingCursor = `url("/src/design/assets/pen_thick_cursor.svg") 5 5, auto`;
        markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/pen_thick_cursor.svg") 5 5, auto`;
      }
    } else if (markupTool === MarkupType.Highlighter) {
      if (markupHighlighterSize === MarkupSizeType.Thin) {
        markupCanvasInstance.current.freeDrawingCursor = `url("/src/design/assets/highlighter_thin_cursor.svg") 3 6, auto`;
        markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/highlighter_thin_cursor.svg") 3 6, auto`;
      } else if (markupHighlighterSize === MarkupSizeType.Medium) {
        markupCanvasInstance.current.freeDrawingCursor = `url("/src/design/assets/highlighter_medium_cursor.svg") 5 7, auto`;
        markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/highlighter_medium_cursor.svg") 5 7, auto`;
      } else {
        markupCanvasInstance.current.freeDrawingCursor = `url("/src/design/assets/highlighter_thick_cursor.svg") 7 9, auto`;
        markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/highlighter_thick_cursor.svg") 7 9, auto`;
      }
    } else if (markupTool === MarkupType.Eraser) {
      markupCanvasInstance.current.hoverCursor = "pointer";
      markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/default_markup_cursor.svg"), auto`;
    } else {
      markupCanvasInstance.current.freeDrawingCursor = `url("/src/design/assets/default_markup_cursor.svg"), auto`;
      markupCanvasInstance.current.defaultCursor = `url("/src/design/assets/default_markup_cursor.svg"), auto`;
    }
  }, [markupTool, markupPenSize, markupHighlighterSize]);

  useEffect(() => {
    if (!isConfirmResetButtonClicked) return;
    renderNewBackgroundImage(imageData?.originalImageSrc ?? "");
  }, [isConfirmResetButtonClicked]);

  const handleClickColorItem = (color: string) => {
    const activeObj = markupCanvasInstance.current.getActiveObject();
    if (activeObj) {
      switch (activeObj.type) {
        case "path":
        case "line":
          if (showHighlighterColorGrid) {
            // adjust opacity when active object is highlighter
            activeObj.set({ stroke: color.replace("1)", "0.3)") });
          } else {
            activeObj.set({ stroke: color });
          }
          break;
        case "i-text":
          activeObj.set({ fill: color });
          break;
        default:
          activeObj.set({ stroke: color });
          break;
      }
    }
    markupCanvasInstance.current.renderAll();
    dispatch(
      setMarkupColor({
        ...markupColor,
        [markupTool]: color,
      })
    );
  };

  const getBrushSize = () => {
    if (markupTool === MarkupType.Pen) {
      switch (markupPenSize) {
        case MarkupSizeType.Thin:
          return 3;
        case MarkupSizeType.Medium:
          return 6;
        default:
          return 10;
      }
    } else if (markupTool === MarkupType.Highlighter) {
      switch (markupHighlighterSize) {
        case MarkupSizeType.Thin:
          return 10;
        case MarkupSizeType.Medium:
          return 16;
        default:
          return 20;
      }
    } else {
      return 10;
    }
  };

  const calculateCanvasSize = ({
    effectiveWidth,
    effectiveHeight,
    canvasContainerHeight,
    canvasContainerWidth,
  }: {
    effectiveWidth: number;
    effectiveHeight: number;
    canvasContainerHeight: number;
    canvasContainerWidth: number;
  }) => {
    let canvasWidth;
    let canvasHeight;

    if (effectiveWidth > effectiveHeight && canvasContainerWidth > effectiveHeight) {
      canvasWidth = canvasContainerWidth;
      canvasHeight = (canvasContainerWidth * effectiveHeight) / effectiveWidth;

      if (canvasHeight > canvasContainerHeight) {
        canvasHeight = canvasContainerHeight;
        canvasWidth = (canvasHeight * effectiveWidth) / effectiveHeight;
      }
    } else {
      const fixedHeight = window.innerHeight - STATIC_TOOLBAR_HEIGHT;
      canvasHeight = fixedHeight;
      canvasWidth = (fixedHeight * effectiveWidth) / effectiveHeight;

      if (canvasWidth > canvasContainerWidth) {
        canvasWidth = canvasContainerWidth;
        canvasHeight = (canvasContainerWidth * effectiveHeight) / effectiveWidth;
      }
    }

    return [canvasWidth, canvasHeight];
  };

  const setCanvasSize = ({ canvasContainerWidth, canvasContainerHeight }: { canvasContainerWidth: number; canvasContainerHeight: number }) => {
    const imgElement = document.createElement("img");
    imgElement.onload = (event) => {
      if (!markupCanvasInstance.current) return;

      const imgElement = event.target as HTMLImageElement;

      // Determine the width and height to use
      const effectiveWidth = imageData?.imageCropArea ? imageData.imageCropArea.width : imgElement.naturalWidth;
      const effectiveHeight = imageData?.imageCropArea ? imageData.imageCropArea.height : imgElement.naturalHeight;

      const [canvasWidth, canvasHeight] = calculateCanvasSize({
        effectiveWidth,
        effectiveHeight,
        canvasContainerHeight,
        canvasContainerWidth,
      });

      const zoomScale = markupCanvasRect.current ? canvasWidth / markupCanvasRect.current.originWidth : 1;
      if (!markupCanvasRect.current) {
        markupCanvasRect.current = {
          originWidth: canvasWidth,
          originHeight: canvasHeight,
          width: canvasWidth,
          height: canvasHeight,
        };
        // If markup img exist, use it for the background image
        const backgroundImageSrc = imageData?.src;

        setCanReset(!!imageData?.markupImageSrc);
        setIsPreviousMarkupExist(!!imageData?.markupImageSrc);
        backgroundImageSrc && renderNewBackgroundImage(backgroundImageSrc);
      } else {
        markupCanvasRect.current.width = canvasWidth;
        markupCanvasRect.current.height = canvasHeight;
      }

      if (zoomScale) {
        markupCanvasInstance.current.setZoom(zoomScale);
      }
      markupCanvasInstance.current.setWidth(canvasWidth);
      markupCanvasInstance.current.setHeight(canvasHeight);
      markupCanvasInstance.current.calcOffset();
    };
    imgElement.src = imageData?.src ?? "";
  };

  const renderNewBackgroundImage = (backgroundImageSrc: string) => {
    if (isConfirmResetButtonClicked && imageData?.imageCropArea) {
      fabric.Image.fromURL(`${backgroundImageSrc}?cache=${Date.now()}`, (img: any) => {
        if (!markupCanvasInstance.current || !canvasWrapperContainer.current) return;
        if (!markupCanvasRect.current) return;
        const effectiveWidth = img.width;
        const effectiveHeight = img.height;

        const [canvasWidth, canvasHeight] = calculateCanvasSize({
          effectiveWidth,
          effectiveHeight,
          canvasContainerHeight: canvasWrapperContainer.current?.getBoundingClientRect().height,
          canvasContainerWidth: canvasWrapperContainer.current?.getBoundingClientRect().width,
        });

        markupCanvasRect.current.width = canvasWidth;
        markupCanvasRect.current.height = canvasHeight;

        img.scaleToWidth(canvasWidth);
        img.scaleToHeight(canvasHeight);
        markupCanvasInstance.current.setWidth(canvasWidth);
        markupCanvasInstance.current.setHeight(canvasHeight);
        markupCanvasInstance.current.calcOffset();

        markupCanvasInstance.current.setBackgroundImage(img, () => {
          setIsBackgroundImageLoaded(true);
          markupCanvasInstance.current.renderAll();
          img.set({ erasable: false });
        });
      });
    } else {
      fabric.Image.fromURL(
        `${backgroundImageSrc}?cache=${Date.now()}`,
        (img: any) => {
          if (!markupCanvasRect.current) return;
          if (!markupCanvasInstance.current) return;
          img.scaleToWidth(markupCanvasRect.current.originWidth);
          img.scaleToHeight(markupCanvasRect.current.originHeight);
          markupCanvasInstance.current.setBackgroundImage(img, () => {
            setIsBackgroundImageLoaded(true);
            markupCanvasInstance.current.renderAll();
            img.set({ erasable: false });
          });
        },
        {
          crossOrigin: "anonymous",
        }
      );
    }
  };

  const onRemoveImageMarkup = async () => {
    lexicalEditorRef.current?.update(() => {
      const currentImageBlock = $getNodeByKey(selectedImageNodeKey ?? "");
      if (!currentImageBlock || !imageData) return;
      if (currentImageBlock.getType() === "image") {
        (currentImageBlock as SlidImageNode).updateData({
          markupImageSrc: undefined,
          src: imageData?.originalImageSrc ? imageData.originalImageSrc : imageData.fullSizeImageSrcForCropping,
          imageCropArea: undefined,
          fullSizeImageSrcForCropping: undefined,
        });
      }
    });
    await saveDocument();
    if (imageData?.clipKey) {
      dispatch(
        updateClip({
          data: {
            clip_key: imageData.clipKey,
            document_key: imageData.documentKey ? imageData.documentKey : currentDocument["document_key"],
            video_key: imageData?.videoInfo ? imageData.videoInfo.videoKey : undefined,
            img_src: imageData.originalImageSrc ? imageData.originalImageSrc : imageData.fullSizeImageSrcForCropping,
            img_markup_src: undefined,
            imageCropArea: undefined,
          },
        })
      );
    }
  };

  const adjustObjectPosition = (object) => {
    if (!markupCanvasRect.current) return object;
    if (imageData?.imageCropArea) {
      object.left += imageData.imageCropArea.x;
      object.top += imageData.imageCropArea.y;
    }

    return object;
  };

  const mergeMarkupWithOriginalImage = async (markupDataURL: string, originalImageSrc: string) => {
    if (!imageData?.imageCropArea) return markupDataURL;
    const originalImage = await new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = originalImageSrc;
      img.onload = () => resolve(img);
    });

    const markupImage = await new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = markupDataURL;
      img.onload = () => resolve(img);
    });

    const canvas = document.createElement("canvas");
    canvas.width = originalImage.naturalWidth;
    canvas.height = originalImage.naturalHeight;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    ctx.drawImage(originalImage, 0, 0);

    ctx.drawImage(markupImage, imageData.imageCropArea.x, imageData.imageCropArea.y, imageData.imageCropArea.width, imageData.imageCropArea.height);

    return canvas.toDataURL("image/png");
  };

  const onSaveImageMarkup = async (saveBtn: HTMLButtonElement) => {
    saveBtn.disabled = true;
    showGlobalModal({ type: ModalType.LOADING, target: isVideoNote && !isMobileUI ? ".video-document-container" : "body", text: "save" });
    if (!markupCanvasInstance.current) return;
    if (markupCanvasInstance.current.getObjects().length === 0 && isConfirmResetButtonClicked) {
      // when user confirmed to reset the markup image and there is no markup object, remove the markup image
      await onRemoveImageMarkup();
      setIsConfirmResetButtonClicked(false);
      saveBtn.disabled = false;
      hideModal();
      setShowImageMarkupPopup(false);
    } else {
      const base64 = markupCanvasInstance.current.toDataURL({
        type: "png",
        quality: 1,
        enableRetinaScaling: true,
      });

      // save markup image for showing in the note
      const markupImageSrc = await uploadBase64Image({
        path: `capture_markup_images/${currentDocument["document_key"]}`,
        base64: base64,
      });

      let markupMergedFullImageSrc = markupImageSrc;

      if (imageData?.imageCropArea) {
        // Adjust markup position based on the original image
        markupCanvasInstance.current.getObjects().forEach(adjustObjectPosition);

        if (imageData.fullSizeImageSrcForCropping) {
          const mergedImageDataURL = await mergeMarkupWithOriginalImage(markupImageSrc, imageData.fullSizeImageSrcForCropping);
          // Now upload the mergedImageDataURL
          markupMergedFullImageSrc = await uploadBase64Image({
            path: `capture_images/${currentDocument["document_key"]}`,
            base64: mergedImageDataURL,
          });
        }
      }

      if (!markupImageSrc) {
        saveBtn.disabled = false;
        hideModal();
        alertImageMarkupNotSaved(editorWrapperClassName);
        return;
      }

      // const editorContent = await editorInstance.save();
      // if (!editorContent) {
      //   saveBtn.disabled = false;
      //   hideModal();
      //   alertImageMarkupNotSaved(editorWrapperClassName);
      //   Sentry.withScope((scope) => {
      //     scope.setLevel("error");
      //     Sentry.captureMessage("SLID_WEB_IMAGE_MARKUP_SAVE_ERROR");
      //   });
      //   return;
      // }

      lexicalEditorRef.current?.update(() => {
        const currentImageBlock = $getNodeByKey(selectedImageNodeKey ?? "");
        dispatch(
          updateClip({
            data: {
              clip_key: imageData?.clipKey,
              document_key: imageData?.documentKey ? imageData.documentKey : currentDocument["document_key"],
              video_key: imageData?.videoInfo ? imageData.videoInfo.videoKey : undefined,
              img_src: markupImageSrc,
              img_markup_src: markupMergedFullImageSrc,
            },
          })
        );
        if (!currentImageBlock || !imageData) return;
        if ($isImageNode(currentImageBlock)) {
          if (!imageData.fullSizeImageSrcForCropping) {
            currentImageBlock.updateData({
              fullSizeImageSrcForCropping: imageData.fullSizeImageSrcForCropping,
            });
          }
          currentImageBlock.updateData({
            markupImageSrc: markupMergedFullImageSrc,
            src: markupImageSrc,
          });
        }
      });

      saveDocument();
      sendAmplitudeData(`Save image markup`);
      setShowImageMarkupPopup(false);
      saveBtn.disabled = false;
      hideModal();
    }
  };

  const handleRedo = () => {
    markupCanvasInstance.current.redo();
  };

  const handleUndo = () => {
    if (markupCanvasInstance.current.historyUndo.length === 1) {
      markupCanvasInstance.current.undo();
      renderNewBackgroundImage(isPreviousMarkupExist && imageData?.markupImageSrc ? imageData?.markupImageSrc : (imageData?.src ?? ""));
      isPreviousMarkupExist && setCanReset(true);
    } else {
      markupCanvasInstance.current.undo();
    }
  };

  const handleResetConfirm = () => {
    markupCanvasInstance.current.remove(...markupCanvasInstance.current.getObjects().concat());
    setIsConfirmResetButtonClicked(true);
    markupCanvasInstance.current.clearHistory();
    setIsPreviousMarkupExist(false);
    closeModal();
  };

  const handleCancelConfirm = () => {
    handleResetConfirm();
    closeModal();
    setShowImageMarkupPopup(false);
  };

  const handleReset = () => {
    trackEvent({
      eventType: eventTypes.show.RESET_MODAL_IN_IMAGE_MARKUP_VIEW,
      eventProperties: {
        location: isVideoNote ? "Video note page" : "My notes",
      },
    });
    showImageMarkupResetModal({ showModal, closeModal, handleResetConfirm, focusCanvas, isMobileUI });
  };

  const focusCanvas = () => {
    // to enable keyboard shortcut
    if (canvasWrapperContainer.current) {
      canvasWrapperContainer.current.focus();
    }
  };

  const removeDrawingElem = () => {
    if (markupCanvasInstance.current.isDrawingMode || isTextEditingMode) return;
    const activeObj = markupCanvasInstance.current.getActiveObject();
    if (activeObj._objects) {
      activeObj._objects.forEach((activeObj: any) => {
        markupCanvasInstance.current.remove(activeObj);
      });
      markupCanvasInstance.current.discardActiveObject().renderAll();
    } else {
      markupCanvasInstance.current.remove(activeObj);
    }
  };

  const handleCancel = () => {
    if (markupCanvasInstance.current.getObjects().length === 0) {
      setShowImageMarkupPopup(false);
    } else {
      trackEvent({
        eventType: eventTypes.show.CANCEL_MODAL_IN_IMAGE_MARKUP_VIEW,
        eventProperties: {
          location: isVideoNote ? "Video note page" : "My notes",
        },
      });
      showImageMarkupCancelModal({ showModal, closeModal, handleCancelConfirm, focusCanvas, isMobileUI });
    }
  };

  const keyDownHandler: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const cmdPressed = e.metaKey || e.ctrlKey;

    switch (e.keyCode) {
      case KEY_ENTER:
        e.stopPropagation();
        break;
      case KEY_BACKSPACE:
        e.stopPropagation();
        removeDrawingElem();
        break;
      case KEY_SPACE:
        e.stopPropagation();
        break;
      case KEY_DELETE:
        removeDrawingElem();
        break;
      case KEY_Z:
        if (cmdPressed && markupCanvasInstance.current) {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          if (e.shiftKey) {
            handleRedo();
          } else {
            handleUndo();
          }
        }
        break;
      default:
        return;
    }
  };

  return {
    canvasWrapperContainer,
    setCanvasSize,
    isBackgroundImageLoaded,
    handleUndo,
    handleRedo,
    canRedo,
    canUndo,
    canReset,
    handleReset,
    removeDrawingElem,
    handleCancel,
    onSaveImageMarkup,
    keyDownHandler,
    focusCanvas,
    handleClickColorItem,
  };
};
