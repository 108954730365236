import React, { useState, useCallback } from "react";
import { useAppSelector } from "hooks";
import styled from "styled-components";
import { Typography13, Typography11, Icon } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./SmartLiveTextBox.css";
import { trackEvent } from "utils/eventTracking";
import { TranscriptBlock } from "types/smartLiveText";
import { useManualSmartLiveText } from "hooks/useManualSmartLiveText";

interface SmartLiveTextBoxProps extends TranscriptBlock {
  isLastData?: boolean;
  isTextAddedOnce?: boolean;
  onAddToNoteButtonInteraction?: (isOnceClicked: boolean) => void;
  index: number;
}

const DesktopSmartLiveTextBox = ({ isLastData, isTextAddedOnce, onAddToNoteButtonInteraction, index, ...data }: SmartLiveTextBoxProps) => {
  const { t } = useTranslation("VideoNote");
  const { insertTranscriptTextToEditor } = useManualSmartLiveText();
  const { isSTTActive, smartLiveTextLanguage, isSTTv2Supported, isSTTToggledOn, isManualAddingMode } = useAppSelector((state) => state.sttReducer);
  const [isAddToNoteClicked, setIsAddToNoteClicked] = useState<boolean>(false);
  const [isAddToNoteButtonHovered, setIsAddToNoteButtonHovered] = useState<boolean>(false);

  const getTextBoxClassname = () => {
    //TODO: do this for the muted video too...
    if (isLastData && !isSTTActive) {
      return `pseudo-waiting-${i18next.language === "ko" ? "ko" : "en"} `;
    } else if (isLastData && isSTTActive) {
      return `pseudo-typing-${i18next.language === "ko" ? "ko" : "en"}`;
    } else if (!isSTTToggledOn) {
      return "";
    } else {
      return "";
    }
  };

  const resetButtonStyles = () => {
    setTimeout(() => {
      setIsAddToNoteClicked(false);
    }, 1000);
  };

  const renderAddToNoteButton = useCallback(() => {
    const button = (
      <AddToNoteButton
        onMouseEnter={() => {
          if (!isAddToNoteButtonHovered) {
            trackEvent({ eventType: "Hover LIVETEXT ADD TO NOTE in video note page" });
            setIsAddToNoteButtonHovered(true);
          }
        }}
        onClick={() => {
          if (isAddToNoteClicked) return;
          trackEvent({ eventType: "Click LIVETEXT ADD TO NOTE in video note page" });
          insertTranscriptTextToEditor({ ...data }).then((result: Boolean) => {
            if (result) {
              resetButtonStyles();
            } else {
              //TODO: add sentry message for add-to-note failure
              resetButtonStyles();
            }
          });
          setIsAddToNoteClicked(true);
          onAddToNoteButtonInteraction && onAddToNoteButtonInteraction(true);
        }}
        isAddToNoteClicked={isAddToNoteClicked}
        isTextAddedOnce={isTextAddedOnce ?? false}
      >
        <Icon icon={!isAddToNoteClicked ? "copy20" : "thickCheck16"} color={isAddToNoteClicked ? "--gray17" : isTextAddedOnce ? "--gray9" : "--blue6"} width={16} height={16} />
        {!isTextAddedOnce && <Typography11 text={t(!isAddToNoteClicked ? "AddToNote" : "Added")} color={!isAddToNoteClicked ? "--blue6" : "--gray17"} weight={400} />}
        {isTextAddedOnce && <Typography11 text={t(!isAddToNoteClicked ? "AddAgain" : "Added")} color={!isAddToNoteClicked ? "--gray9" : "--gray17"} weight={400} />}
      </AddToNoteButton>
    );

    if (isLastData) return <></>;
    if (smartLiveTextLanguage !== "한국어") {
      return button;
    }
    if (data?.isWhisperTranscribed || data.isFinal || !isLastData) return button;
    if (!isSTTActive || !isSTTv2Supported) return button;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, smartLiveTextLanguage, isSTTActive]);

  const getTypoColor = useCallback(() => {
    if (isSTTv2Supported && smartLiveTextLanguage === "한국어") {
      if (data?.isWhisperTranscribed || !isSTTActive || data.isFinal || !isLastData) return "--gray5";
      else return "--gray9";
    } else {
      if (isLastData) return "--gray9";
      else return "--gray5";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, smartLiveTextLanguage, isSTTActive]);

  return (
    <TextBox>
      {isManualAddingMode && renderAddToNoteButton()}
      <Typography13 text={data && data.text} color={getTypoColor()} weight={400} className={getTextBoxClassname()} />
    </TextBox>
  );
};

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  margin-bottom: 12px;
  gap: 8px;

  background: var(--gray18);
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AddToNoteButton = styled.div<{ isAddToNoteClicked: boolean; isTextAddedOnce: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  width: fit-content;
  user-select: none;
  border: 1px solid ${({ isTextAddedOnce, isAddToNoteClicked }) => (!isTextAddedOnce || isAddToNoteClicked ? "var(--blue6)" : "var(--gray9)")};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ isAddToNoteClicked }) => (isAddToNoteClicked ? "var(--blue6)" : "initial")};

  &:hover {
    background: ${({ isAddToNoteClicked }) => (isAddToNoteClicked ? "var(--blue6)" : "rgba(255, 255, 255, 0.08)")};
  }

  &:active {
    background: rgba(255, 255, 255, 0.04);
  }
`;

export default DesktopSmartLiveTextBox;
