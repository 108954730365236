//auth 2.0
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { registerClip } from "redux/actions/vdocsActions";
import { alertPDFTooLarge, alertUploadingPDF, alertUploadingPDFFailed } from "components/alerts";
import { useAppDispatch, useAppSelector } from "hooks";
import Sweetalert from "sweetalert2";
import { uploadBase64Image } from "utils/aws/s3Interface/globalOptimizedS3Interface";
import { trackEvent } from "utils/eventTracking";
import * as Sentry from "@sentry/browser";
import { useSaveDocumentAndCreateHistory } from "hooks/useSaveDocumentAndCreateHistory";
import useEditorStore from "store/useEditorStore";
import { $getRoot } from "lexical";
import { $createImageNode, ClipData } from "../nodes/SlidImageNode/ImageNode";

declare global {
  interface Window {
    pdfjsLib: any;
  }
}

const PDFJS = window.pdfjsLib;

const PDFFileUploader = React.forwardRef<HTMLInputElement | null>(() => {
  const dispatch = useAppDispatch();
  const { currentDocument } = useAppSelector((state) => state.vdocs);
  const { saveDocument } = useSaveDocumentAndCreateHistory();
  const { fileUploadInputRef, lexicalEditorRef } = useEditorStore();
  const [pdf, setPdf] = useState<any>();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const pdfCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!pdf) return;

    const convertPdfPagesToImgBlocks = async () => {
      let pdfPageNum = pdf._pdfInfo.numPages;

      for (let i = 1; i <= pdfPageNum; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 2 });
        const render_context = {
          canvasContext: pdfCanvasRef.current?.getContext("2d"),
          viewport: viewport,
        };
        setWidth(viewport.width);
        setHeight(viewport.height);
        await page.render(render_context).promise;
        const imgData = pdfCanvasRef.current?.toDataURL("image/png") as string;

        lexicalEditorRef.current?.update(() => {
          const root = $getRoot();
          root.append(
            $createImageNode({
              originalImageSrc: imgData,
              src: imgData,
              isUploaded: false,
              sourceType: "fileUpload",
              documentKey: currentDocument["document_key"],
              setClipData: async () => {
                return await pdf_getImageBlockData(imgData, currentDocument["document_key"]);
              },
            })
          );
        });
      }

      await saveDocument();
    };

    const pdf_getImageBlockData = async (imgData: string, currentDocumentKey: string): Promise<ClipData | undefined> => {
      const uploadedImageSrc = await uploadBase64Image({
        path: `image_upload/${currentDocumentKey}`,
        base64: imgData,
      });

      if (!uploadedImageSrc) {
        return;
      }

      const clipRegisterResponse: any = await dispatch(
        // @ts-ignore
        registerClip({
          imgSrc: uploadedImageSrc,
          documentKey: currentDocumentKey,
          clipEndPoint: "upload-clip",
        })
      );

      if (clipRegisterResponse.error_message) return;

      const imgObj = {
        src: uploadedImageSrc,
        documentKey: currentDocumentKey,
        clipKey: clipRegisterResponse["clip_key"],
        type: "pdfUpload",
      };
      return imgObj;
    };

    lexicalEditorRef.current?.focus();

    convertPdfPagesToImgBlocks();
    Sweetalert.close();

    trackEvent({
      eventType: "Finish uploading pdf",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf]);

  const onUploadPdfFile = async (event: any) => {
    if (!currentDocument) await saveDocument();

    try {
      const file = event.target.files[0];
      alertUploadingPDF();
      const uri = URL.createObjectURL(file);
      var _PDF_DOC = await PDFJS.getDocument({ url: uri }).promise;
      if (_PDF_DOC._pdfInfo.numPages > 200) {
        alertPDFTooLarge();
        return;
      }
      setPdf(_PDF_DOC);
    } catch (error) {
      alertUploadingPDFFailed();
      console.log(error);

      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("error_msg", error);
        Sentry.captureMessage("Pdf upload error");
      });
    }
  };

  return (
    <>
      <VirtualInputForFileUpload
        ref={fileUploadInputRef}
        type={`file`}
        accept={`.pdf`}
        onChange={(event) => {
          // If the file is selected, check the file size and handle pdf file
          onUploadPdfFile(event);
        }}
      />
      <VirtualImageCanvas ref={pdfCanvasRef} width={width} height={height}></VirtualImageCanvas>
    </>
  );
});

export default PDFFileUploader;

const VirtualInputForFileUpload = styled.input`
  visibility: hidden;
`;

const VirtualImageCanvas = styled.canvas`
  display: none;
`;
