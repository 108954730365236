import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const UnderlineIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 32} height={height ? height : 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="underline">
        <path id="Vector" d="M10 23H22" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path
          id="Vector_2"
          d="M21 10V14.6471C21 16.0667 20.4732 17.4283 19.5355 18.4322C18.5979 19.436 17.3261 20 16 20C14.6739 20 13.4021 19.436 12.4645 18.4322C11.5268 17.4283 11 16.0667 11 14.6471V10"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgWrapper>
  );
};
