// auth 2.0
import env from "config/env";
import { saveAs } from "file-saver";
import {
  Document as Docx_Document,
  Packer as Docx_Packer,
  Paragraph as Docx_Paragraph,
  // TextRun as Docx_TextRun,
  Media as Docx_Media,
  HeadingLevel as Docx_HeadingLevel,
  HyperlinkType,
  BorderStyle as Docx_BorderStyle,
  AlignmentType,
  Table as Docx_Table,
  TableRow as Docx_TableRow,
  TableCell as Docx_TableCell,
  WidthType,
  VerticalAlign,
  HeadingLevel,
} from "docx";

import json2md from "json2md";
import TurndownService from "turndown";

import JSZip from "jszip";

const trundownService = new TurndownService();
const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

export const exportToWord = async ({ currentContent, title, documentKey, callBack }) => {
  // Create document
  const doc = new Docx_Document({
    hyperlinks: {
      docsLink: {
        link: `${SLID_WEB_APP_URL}/docs/${documentKey}`,
        text: "Click to open Slid docs",
        type: HyperlinkType.EXTERNAL,
      },
    },
    numbering: {
      config: [
        {
          reference: "slid-numbering-rule",
          levels: [
            {
              level: 0,
              format: "decimal",
              text: "%1.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 300, hanging: 300 },
                },
              },
            },
            {
              level: 1,
              format: "lowerLetter",
              text: "%2.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 600, hanging: 300 },
                },
              },
            },
            {
              level: 2,
              format: "lowerRoman",
              text: "%3.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 900, hanging: 300 },
                },
              },
            },
            {
              level: 3,
              format: "decimal",
              text: "%4.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 1200, hanging: 300 },
                },
              },
            },
            {
              level: 4,
              format: "lowerLetter",
              text: "%5.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 1500, hanging: 300 },
                },
              },
            },
            {
              level: 5,
              format: "lowerRoman",
              text: "%6.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 1800, hanging: 300 },
                },
              },
            },
            {
              level: 6,
              format: "decimal",
              text: "%7.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 2100, hanging: 300 },
                },
              },
            },
            {
              level: 7,
              format: "lowerLetter",
              text: "%8.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 2400, hanging: 300 },
                },
              },
            },
            {
              level: 8,
              format: "lowerRoman",
              text: "%9.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 2700, hanging: 300 },
                },
              },
            },
          ],
        },
      ],
    },
  });

  const loadImage = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", (err) => reject(err));
      img.src = url;
    });

  const docxBlocks = [];

  for (let i = 0; i < currentContent.blocks.length; i++) {
    const block = currentContent.blocks[i];

    function strip(html) {
      html = html.replaceAll("<br>", "\n");
      html = html.replaceAll("<br/>", "\n");
      let doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    }

    switch (block.type) {
      case "image":
        const downloadImgSrc = block.data.markupImageSrc ? block.data.markupImageSrc : block.data.src;
        if (!downloadImgSrc) break;

        const loadedImage = await loadImage(downloadImgSrc);

        const blob = await fetch(downloadImgSrc + `?rand=${Date.now()}`).then((r) => r.blob());

        // full width 600
        // full height 930
        let adjustedWidth;
        let adjustedHeight;

        if (loadedImage.naturalWidth > loadedImage.naturalHeight) {
          adjustedWidth = 600;
          adjustedHeight = (600 * loadedImage.naturalHeight) / loadedImage.naturalWidth;
        } else {
          adjustedWidth = (337.5 * loadedImage.naturalWidth) / loadedImage.naturalHeight;
          adjustedHeight = 337.5;
        }

        docxBlocks.push(new Docx_Paragraph(Docx_Media.addImage(doc, blob, adjustedWidth, adjustedHeight)));

        // to create space in word document between adjacent images
        docxBlocks.push(
          new Docx_Paragraph({
            break: true,
          })
        );

        break;

      case "paragraph":
        docxBlocks.push(
          new Docx_Paragraph({
            text: strip(block.data.text),
            spacing: {
              after: 200,
            },
            break: true,
          })
        );
        break;

      case "header":
        docxBlocks.push(
          new Docx_Paragraph({
            text: strip(block.data.text),
            heading: Docx_HeadingLevel[`HEADING_${block.data.level - 1}`],
            spacing: {
              after: 100,
            },
            break: true,
          })
        );
        break;

      case "header1":
        docxBlocks.push(
          new Docx_Paragraph({
            text: strip(block.data.text),
            heading: Docx_HeadingLevel[`HEADING_${block.data.level - 1}`],
            spacing: {
              after: 100,
            },
            break: true,
          })
        );
        break;

      case "header2":
        docxBlocks.push(
          new Docx_Paragraph({
            text: strip(block.data.text),
            heading: Docx_HeadingLevel[`HEADING_${block.data.level - 1}`],
            spacing: {
              after: 100,
            },
            break: true,
          })
        );
        break;

      case "header3":
        docxBlocks.push(
          new Docx_Paragraph({
            text: strip(block.data.text),
            heading: Docx_HeadingLevel[`HEADING_${block.data.level - 1}`],
            spacing: {
              after: 100,
            },
            break: true,
          })
        );
        break;

      case "list":
        block.data.items.forEach((item, index) => {
          docxBlocks.push(
            new Docx_Paragraph({
              text: strip(item),
              bullet: {
                level: 0,
              },
            })
          );
        });
        break;

      case "nestedList":
        let level = 0;

        const addListContentToParagraph = (items, level) => {
          items.forEach((item, index) => {
            docxBlocks.push(
              new Docx_Paragraph({
                text: strip(item.content),
                bullet: {
                  level: level,
                },
              })
            );

            addListContentToParagraph(item.items, level + 1);
          });
        };

        addListContentToParagraph(block.data.items, level);

        break;

      case "blockList":
        block.data.items.forEach((item, index) => {
          docxBlocks.push(
            new Docx_Paragraph({
              text: strip(item),
              bullet: {
                level: block.data.indentLevel,
              },
            })
          );
        });

        break;

      case "unorderedList":
        block.data.items.forEach(({ text }, index) => {
          docxBlocks.push(
            new Docx_Paragraph({
              text: strip(text),
              bullet: {
                level: block.data.indentLevel,
              },
            })
          );
        });

        break;

      case "orderedList":
        block.data.items.forEach(({ text }, index) => {
          docxBlocks.push(
            new Docx_Paragraph({
              text: strip(text),
              numbering: {
                level: block.data.indentLevel,
                reference: "slid-numbering-rule",
              },
            })
          );
        });

        break;

      case "checklist":
        block.data.items.forEach(({ text }, index) => {
          docxBlocks.push(
            new Docx_Paragraph({
              text: strip(text),
              bullet: {
                level: 0,
              },
            })
          );
        });
        break;

      case "blockChecklist":
        block.data.items.forEach(({ text, checked }, index) => {
          docxBlocks.push(
            new Docx_Paragraph({
              text: strip(text),
              bullet: {
                level: block.data.indentLevel,
              },
            })
          );
        });
        break;

      case "Math":
        const latexImgSrc = encodeURI(`https://latex.codecogs.com/png.latex?\\dpi{300}${strip(block.data.math)}`);

        const loadedLatexImage = await loadImage(latexImgSrc);

        const latexBlob = await fetch(latexImgSrc).then((r) => r.blob());

        // full width 600
        // full height 930
        let adjustedWidthForLatex;
        let adjustedHeightForLatex;

        if (loadedLatexImage.naturalWidth > loadedLatexImage.naturalHeight) {
          adjustedWidthForLatex = 200;
          adjustedHeightForLatex = (200 * loadedLatexImage.naturalHeight) / loadedLatexImage.naturalWidth;
        } else {
          adjustedWidthForLatex = (100 * loadedLatexImage.naturalWidth) / loadedLatexImage.naturalHeight;
          adjustedHeightForLatex = 100;
        }

        docxBlocks.push(new Docx_Paragraph(Docx_Media.addImage(doc, latexBlob, adjustedWidthForLatex, adjustedHeightForLatex)));
        break;

      case "video":
        const downloadPosterSrc = block.data.posterUrl;

        const loadedPosterImage = await loadImage(downloadPosterSrc);

        const blobForPoster = await fetch(downloadPosterSrc + `?rand=${Date.now()}`).then((r) => r.blob());

        // full width 600
        // full height 930
        let adjustedWidthForPoster;
        let adjustedHeightForPoster;

        if (loadedPosterImage.naturalWidth > loadedPosterImage.naturalHeight) {
          adjustedWidthForPoster = 600;
          adjustedHeightForPoster = (600 * loadedPosterImage.naturalHeight) / loadedPosterImage.naturalWidth;
        } else {
          adjustedWidthForPoster = (337.5 * loadedPosterImage.naturalWidth) / loadedPosterImage.naturalHeight;
          adjustedHeightForPoster = 337.5;
        }

        const videoPosterImage = Docx_Media.addImage(doc, blobForPoster, adjustedWidthForPoster, adjustedHeightForPoster);
        docxBlocks.push(
          new Docx_Paragraph({
            // children: [new HyperlinkRef("docsLink"), videoPosterImage],
            children: [videoPosterImage],
          })
        );

        break;

      case "codeTool":
        docxBlocks.push(
          new Docx_Paragraph({
            text: block.data.code,
            spacing: {
              after: 200,
            },
            break: true,
          })
        );
        break;

      case "codeMirrorTool":
        docxBlocks.push(
          new Docx_Paragraph({
            text: block.data.code,
            spacing: {
              after: 200,
            },
            break: true,
          })
        );
        break;

      case "divider":
        const dividerBlock = new Docx_Paragraph({
          text: "",
          border: {
            top: {
              color: "auto",
              space: 1,
              value: Docx_BorderStyle.SINGLE,
              size: 6,
            },
          },
        });
        docxBlocks.push(dividerBlock);
        break;

      case "audio":
        const audioBlock = new Docx_Paragraph({
          text: block.data.src,
          break: true,
        });
        docxBlocks.push(audioBlock);
        break;

      case "table":
        const rows = block.data.content;
        const hasHeading = block.data.withHeadings;

        function makeTableCells(row, isHeading = false) {
          if (row.length === 0) {
            return [
              new Docx_TableCell({
                width: {
                  size: 200,
                  type: WidthType.PERCENTAGE,
                },
                children: [
                  new Docx_Paragraph({
                    text: "",
                    break: true,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
            ];
          }

          return row.map(
            (item) =>
              new Docx_TableCell({
                width: {
                  size: 200 / row.length,
                  type: WidthType.PERCENTAGE,
                },
                children: [
                  new Docx_Paragraph({
                    ...(isHeading && { heading: HeadingLevel.HEADING_3 }),
                    ...{
                      text: strip(item),
                      break: true,
                    },
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              })
          );
        }

        function makeTableRow(row, isHeading) {
          return new Docx_TableRow({ children: makeTableCells(row, isHeading), tableHeader: isHeading });
        }

        function makeTable(rowArray, hasHeading) {
          return new Docx_Table({
            rows: rowArray.map((row, ind) => makeTableRow(row, hasHeading && ind === 0)),
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
          });
        }

        const tableBlock = makeTable(rows, hasHeading);

        docxBlocks.push(tableBlock);

        //NOTE: push in empty paragraph for spacing to create space between adjacent tables in word document so that they dont appear as one table
        docxBlocks.push(
          new Docx_Paragraph({
            break: true,
          })
        );
        break;
      case "linkTool":
        if (block.data.meta?.link)
          docxBlocks.push(
            new Docx_Paragraph({
              text: block.data.meta.link,
              spacing: {
                after: 200,
              },
              break: true,
            })
          );
        break;
      default:
        break;
    }
  }

  doc.addSection({
    properties: {},
    children: docxBlocks,
  });

  Docx_Packer.toBlob(doc).then((blob) => {
    saveAs(blob, title ? `${title}.docx` : `Untitled.docx`);
    callBack();
  });
};

// add custom markdown
json2md.converters.checkBox = (items) => {
  // For LEGACY checklist
  const checkBoxes = [];
  items.forEach((item) => {
    checkBoxes.push(`- [${item.checked ? "x" : " "}] ${trundownService.turndown(item.text)}`);
  });
  return checkBoxes.join("\n");
};
json2md.converters.blockCheckBox = ({ items, indentLevel }) => {
  const checkBoxes = [];
  const indent = "  ";

  items.forEach((item) => {
    checkBoxes.push(`${indent.repeat(indentLevel)} - [${item.checked ? "x" : " "}] ${trundownService.turndown(item.text)}`);
  });
  return checkBoxes.join("\n");
};

json2md.converters.iframe = (item) => {
  return `<iframe src="${item.src}"></iframe>`;
};

json2md.converters.video = (item) => {
  // Notion automatically converts the iframe into video tag.
  return `<iframe src="${item.videoUrl}"/></iframe>`;
};

json2md.converters.notionVideo = (item) => {
  // TODO: find way to embed video tag directly to Notion
  return `[Embed video with link] → ${item.videoUrl}`;
};

json2md.converters.latex = (item) => {
  return `$$${item.latexContent}$$`;
};

json2md.converters.timestampImg = (item) => {
  return `[![${item.title}](${item.source})](${item.vdocsUrl})`;
};

json2md.converters.nestedUl = (items) => {
  const ulBlocks = [];
  let level = 0;
  const indent = "  ";
  const addListContentToMarkdownJsonObjects = (items, level) => {
    items.forEach((item, index) => {
      ulBlocks.push(indent.repeat(level) + "- " + trundownService.turndown(item.content));

      addListContentToMarkdownJsonObjects(item.items, level + 1);
    });
  };
  addListContentToMarkdownJsonObjects(items, level);

  return ulBlocks.join("\n");
};

json2md.converters.nestedOl = (items) => {
  const olBlocks = [];
  let level = 0;
  const indent = "   ";
  const addListContentToMarkdownJsonObjects = (items, level) => {
    items.forEach((item, index) => {
      olBlocks.push(indent.repeat(level) + `${index + 1}. ` + trundownService.turndown(item.content));

      addListContentToMarkdownJsonObjects(item.items, level + 1);
    });
  };
  addListContentToMarkdownJsonObjects(items, level);

  return olBlocks.join("\n");
};

json2md.converters.blockList = ({ items, indentLevel }) => {
  // For LEGACY blockList
  const ulBlocks = [];
  const indent = "  ";
  const addListContentToMarkdownJsonObjects = (items) => {
    items.forEach((item, index) => {
      ulBlocks.push(indent.repeat(indentLevel) + "- " + trundownService.turndown(item));
    });
  };
  addListContentToMarkdownJsonObjects(items);

  return ulBlocks.join("\n");
};

json2md.converters.unorderedList = ({ items, indentLevel }) => {
  const ulBlocks = [];
  const indent = "  ";
  const addListContentToMarkdownJsonObjects = (items) => {
    items.forEach(({ text }, index) => {
      ulBlocks.push(indent.repeat(indentLevel) + "- " + trundownService.turndown(text));
    });
  };
  addListContentToMarkdownJsonObjects(items);

  return ulBlocks.join("\n");
};

json2md.converters.orderedList = ({ items, indentLevel, numberForMarkdownDownloading }) => {
  const olBlocks = [];
  const indent = "    ";
  const addListContentToMarkdownJsonObjects = (items) => {
    items.forEach(({ text }, index) => {
      olBlocks.push(indent.repeat(indentLevel) + `${numberForMarkdownDownloading}. ` + trundownService.turndown(text));
    });
  };
  addListContentToMarkdownJsonObjects(items);

  return olBlocks.join("\n");
};

json2md.converters.divider = (item) => {
  return item;
};

json2md.converters.audio = (item) => {
  // TODO: find way to embed audio tag directly to Notion
  return `[Embed audio with link] → ${item.src}`;
};

//NOTE: used to download markdown to to the user's device
export const exportToMarkdown = async ({ currentContent, title, documentKey }) => {
  const markdownJsonObjects = [
    {
      link: {
        title: `→ Open in Slid`,
        source: `${SLID_WEB_APP_URL}/docs/${documentKey}`,
      },
    },
    // {
    //     iframe: {
    //         src: `${SLID_WEB_APP_URL}/vdocs/${documentKey}`,
    //     }
    // },
    {
      p: `---`,
    },
  ];

  //NOTE:  define the data that  would be provided to the json-converters
  currentContent.blocks.forEach((block) => {
    switch (block.type) {
      case "image":
        const downloadImgSrc = block.data.markupImageSrc ? block.data.markupImageSrc : block.data.src;

        const timestampImg = {
          title: `${title ? title : "Untitled"} image`,
          source: downloadImgSrc,
        };

        if (block.data.videoInfo) {
          timestampImg.vdocsUrl = `${SLID_WEB_APP_URL}/vdocs/${documentKey}?v=${block.data.videoInfo.videoKey}&start=${block.data.timestamp ? block.data.timestamp : 0}`;
        }

        markdownJsonObjects.push({
          timestampImg: timestampImg,
        });
        return;

      case "paragraph":
        markdownJsonObjects.push({
          p: block.data.text === "" ? "‏‏‎ ‎" : trundownService.turndown(block.data.text),
        });
        return;

      case "header":
        const headerObject = {};
        headerObject[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(headerObject);
        return;

      case "header1":
        const header1Object = {};
        header1Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header1Object);
        return;

      case "header2":
        const header2Object = {};
        header2Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header2Object);
        return;

      case "header3":
        const header3Object = {};
        header3Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header3Object);
        return;

      case "list":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            ul: block.data.items.map((item) => trundownService.turndown(item)),
          });
        } else {
          markdownJsonObjects.push({
            ol: block.data.items.map((item) => trundownService.turndown(item)),
          });
        }
        return;

      case "nestedList":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            nestedUl: block.data.items,
          });
        } else {
          markdownJsonObjects.push({
            nestedOl: block.data.items,
          });
        }

        return;

      case "blockList":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            blockList: { items: block.data.items, indentLevel: block.data.indentLevel },
          });
        } else {
          markdownJsonObjects.push({
            blockList: { items: block.data.items, indentLevel: block.data.indentLevel },
          });
        }

        return;

      case "unorderedList":
        markdownJsonObjects.push({
          unorderedList: { items: block.data.items, indentLevel: block.data.indentLevel },
        });

        return;

      case "orderedList":
        markdownJsonObjects.push({
          orderedList: { items: block.data.items, indentLevel: block.data.indentLevel, numberForMarkdownDownloading: block.data.numberForMarkdownDownloading },
        });

        return;

      case "checklist":
        markdownJsonObjects.push({
          checkBox: block.data.items,
        });
        return;

      case "blockChecklist":
        markdownJsonObjects.push({
          blockCheckBox: { items: block.data.items, indentLevel: block.data.indentLevel },
        });
        return;

      case "video":
        markdownJsonObjects.push({
          video: {
            title: `${title} clip thumbnail`,
            posterUrl: block.data.posterUrl,
            link: `${SLID_WEB_APP_URL}/docs/${documentKey}`,
            videoUrl: block.data.videoUrl,
          },
        });
        return;

      case "Math":
        markdownJsonObjects.push({
          latex: {
            latexContent: `${block.data.math}`,
          },
        });
        return;

      case "codeTool":
        markdownJsonObjects.push({
          code: {
            content: block.data.code.split("\n"),
          },
        });
        return;

      case "codeMirrorTool":
        markdownJsonObjects.push({
          code: {
            content: block.data.code.split("\n"),
            language: block.data.language,
          },
        });
        return;

      case "divider":
        markdownJsonObjects.push({
          divider: "---",
        });
        return;
      case "audio":
        markdownJsonObjects.push({
          audio: {
            link: `${SLID_WEB_APP_URL}/docs/${documentKey}`,
            src: block.data.src,
          },
        });
        return;
      case "table":
        const tableContent = [...block.data.content];
        const tableObject = { table: { headers: [], rows: [] } };

        if (block.data.withHeadings) {
          tableObject.table.headers = tableContent.shift();
        } else {
          tableObject.table.headers = new Array(tableContent[0].length).fill(" ");
        }
        if (tableContent.length) {
          tableObject["table"]["rows"] = tableContent;
        } else {
          tableObject.table.rows = [new Array(tableObject.table.headers.length).fill(` `)];
        }

        markdownJsonObjects.push(tableObject);
        return;
      case "linkTool":
        if (block.data.meta?.link)
          markdownJsonObjects.push({
            link: { title: block.data.meta.title ? block.data.meta.title : "", source: block.data.meta.link ? block.data.meta.link : "" },
          });

        return;
      default:
        return;
    }
  });

  const markdownResult = json2md(markdownJsonObjects);
  const cleanedMarkdown = cleanMarkdown(markdownResult);

  var blob = new Blob([cleanedMarkdown], {
    type: "text/markdown;charset=utf-8",
  });

  saveAs(blob, title ? `${title}.md` : "Untitled.md");
};

//NOTE: used to copy to clip board and then paste to notion
export const convertToMarkdown = async ({ currentContent, title, documentKey, lang }) => {
  const markdownJsonObjects = [
    {
      h1: title,
    },
    {
      link: {
        title: lang === "ko" ? `→ 슬리드로 보기` : `→ View in Slid`,
        source: `${SLID_WEB_APP_URL}/docs/${documentKey}`,
      },
    },
    // {
    //     iframe: {
    //         src: `${SLID_WEB_APP_URL}/vdocs/${documentKey}`,
    //     }
    // },
    {
      p: `---`,
    },
  ];

  currentContent.blocks.forEach((block) => {
    switch (block.type) {
      case "image":
        const downloadImgSrc = block.data.markupImageSrc ? block.data.markupImageSrc : block.data.src;

        const timestampImg = {
          title: `${title ? title : "Untitled"} image`,
          source: downloadImgSrc,
        };

        if (block.data.videoInfo) {
          timestampImg.vdocsUrl = `${SLID_WEB_APP_URL}/vdocs/${documentKey}?v=${block.data.videoInfo.videoKey}&start=${block.data.timestamp ? block.data.timestamp : 0}`;
        }

        markdownJsonObjects.push({
          timestampImg: timestampImg,
        });
        return;

      case "paragraph":
        markdownJsonObjects.push({
          p: block.data.text === "" ? "‏‏‎ ‎" : trundownService.turndown(block.data.text),
        });
        return;

      case "header":
        const headerObject = {};
        headerObject[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(headerObject);
        return;

      case "header1":
        const header1Object = {};
        header1Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header1Object);
        return;

      case "header2":
        const header2Object = {};
        header2Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header2Object);
        return;

      case "header3":
        const header3Object = {};
        header3Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header3Object);
        return;

      case "list":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            ul: block.data.items.map((item) => trundownService.turndown(item)),
          });
        } else {
          markdownJsonObjects.push({
            ol: block.data.items.map((item) => trundownService.turndown(item)),
          });
        }
        return;

      case "nestedList":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            nestedUl: block.data.items,
          });
        } else {
          markdownJsonObjects.push({
            nestedOl: block.data.items,
          });
        }

        return;

      case "blockList":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            blockList: { items: block.data.items, indentLevel: block.data.indentLevel },
          });
        } else {
          markdownJsonObjects.push({
            blockList: { items: block.data.items, indentLevel: block.data.indentLevel },
          });
        }

        return;

      case "unorderedList":
        markdownJsonObjects.push({
          unorderedList: { items: block.data.items, indentLevel: block.data.indentLevel },
        });

        return;

      case "orderedList":
        markdownJsonObjects.push({
          orderedList: { items: block.data.items, indentLevel: block.data.indentLevel, numberForMarkdownDownloading: block.data.numberForMarkdownDownloading },
        });

        return;

      case "checklist":
        markdownJsonObjects.push({
          checkBox: block.data.items,
        });
        return;

      case "blockChecklist":
        markdownJsonObjects.push({
          blockCheckBox: { items: block.data.items, indentLevel: block.data.indentLevel },
        });
        return;

      case "video":
        markdownJsonObjects.push({
          notionVideo: {
            title: `${title} clip thumbnail`,
            posterUrl: block.data.posterUrl,
            link: `${SLID_WEB_APP_URL}/docs/${documentKey}`,
            videoUrl: block.data.videoUrl,
          },
        });
        return;

      case "Math":
        markdownJsonObjects.push({
          latex: {
            latexContent: `${block.data.math}`,
          },
        });
        return;

      case "codeTool":
        markdownJsonObjects.push({
          code: {
            content: block.data.code.split("\n"),
          },
        });
        return;

      case "codeMirrorTool":
        markdownJsonObjects.push({
          code: {
            content: block.data.code.split("\n"),
            language: block.data.language,
          },
        });
        return;

      case "divider":
        markdownJsonObjects.push({
          divider: "---",
        });
        return;
      case "audio":
        markdownJsonObjects.push({
          audio: {
            link: `${SLID_WEB_APP_URL}/docs/${documentKey}`,
            src: block.data.src,
          },
        });
        return;

      case "table":
        const tableContent = [...block.data.content];
        const tableObject = { table: { headers: [], rows: [] } };
        if (block.data.withHeadings) {
          tableObject.table.headers = tableContent.shift();
        } else {
          tableObject.table.headers = [...Array(tableContent[0].length).keys()].map((ind) => `${lang === "ko" ? "제목" : "Heading"} ${ind + 1}`);
        }

        if (tableContent.length) {
          tableObject["table"]["rows"] = tableContent;
        } else {
          tableObject.table.rows = [new Array(tableObject.table.headers.length).fill(`...`)];
        }

        markdownJsonObjects.push(tableObject);
        return;

      case "linkTool":
        if (block.data.meta?.link)
          markdownJsonObjects.push({
            link: { title: block.data.meta.title ? block.data.meta.title : "", source: block.data.meta.link ? block.data.meta.link : "" },
          });
        return;
      default:
        return;
    }
  });
  const markdownResult = json2md(markdownJsonObjects);
  const cleanedMarkdown = cleanMarkdown(markdownResult);
  return cleanedMarkdown;
};

export const exportToImage = async ({ currentContent, title, callBack }) => {
  const imageBlocks = currentContent.blocks.filter((block) => {
    return block.type === "image";
  });

  let zip = new JSZip();
  let folder = zip.folder(title);

  for (let i = 0; i < imageBlocks.length; i++) {
    const imageBlob = await fetch(imageBlocks[i].data.markupImageSrc ? imageBlocks[i].data.markupImageSrc + `?rand=${Date.now()}` : imageBlocks[i].data.src + `?rand=${Date.now()}`).then((response) =>
      response.blob()
    );
    const imageFile = new File([imageBlob], "filename.jpg");
    folder.file(`${title}_${i}.png`, imageFile);
  }

  zip
    .generateAsync({ type: "blob" })
    .then((content) => {
      saveAs(content, `${title}.zip`);
      callBack();
    })
    .catch(() => {
      callBack();
    });
};

export const convertForAutoNotesLLMAsMarkdown = ({ currentContent, title, documentKey, lang }) => {
  const markdownJsonObjects = [
    {
      h1: title,
    },
  ];

  currentContent.blocks.forEach((block) => {
    switch (block.type) {
      case "image":
        //  we should indicated that this is an image captured with Slid but is not visible to the LLM.
        markdownJsonObjects.push({
          p: "![Image captured by USER but is not exposed to LLM]",
        });

        //NOTE: the idea here is to get the base64 image and pass it to the LLM. This will be done in future versions.
        // const downloadImgSrc = block.data.markupImageSrc ? block.data.markupImageSrc : block.data.src;

        //NOTE: if the internet is slow, this step will be really really slow as well.
        // Fetch the image as a blob
        // fetch(downloadImgSrc)
        //   .then((response) => response.blob())
        //   .then((blob) => {
        //     // Create a FileReader to convert the blob to base64
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     reader.onloadend = () => {
        //       const base64data = reader.result;

        //       console.log("base64data - ", base64data);

        //       // Push the base64 image string to markdownJsonObjects
        //       markdownJsonObjects.push({
        //         p: `![Image](${base64data})`,
        //       });

        //       // If there's video info, add the video URL with timestamp
        //       if (block.data.videoInfo) {
        //         const timestampImg = {
        //           title: `${title ? title : "Untitled"} image`,
        //           source: base64data,
        //           vdocsUrl: `${SLID_WEB_APP_URL}/vdocs/${documentKey}?v=${block.data.videoInfo.videoKey}&start=${block.data.timestamp ? block.data.timestamp : 0}`,
        //         };

        //         markdownJsonObjects.push({
        //           timestampImg: timestampImg,
        //         });
        //       }
        //   };
        // })
        // .catch((error) => console.error("Error fetching or converting image:", error));

        return;

      case "paragraph":
        markdownJsonObjects.push({
          p: block.data.text === "" ? "‏‏‎ ‎" : trundownService.turndown(block.data.text),
        });
        return;

      case "header":
        const headerObject = {};
        headerObject[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(headerObject);
        return;

      case "header1":
        const header1Object = {};
        header1Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header1Object);
        return;

      case "header2":
        const header2Object = {};
        header2Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header2Object);
        return;

      case "header3":
        const header3Object = {};
        header3Object[`h${block.data.level - 1}`] = trundownService.turndown(block.data.text);
        markdownJsonObjects.push(header3Object);
        return;

      case "list":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            ul: block.data.items.map((item) => trundownService.turndown(item)),
          });
        } else {
          markdownJsonObjects.push({
            ol: block.data.items.map((item) => trundownService.turndown(item)),
          });
        }
        return;

      case "nestedList":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            nestedUl: block.data.items,
          });
        } else {
          markdownJsonObjects.push({
            nestedOl: block.data.items,
          });
        }

        return;

      case "blockList":
        if (block.data.style === "unordered") {
          markdownJsonObjects.push({
            blockList: { items: block.data.items, indentLevel: block.data.indentLevel },
          });
        } else {
          markdownJsonObjects.push({
            blockList: { items: block.data.items, indentLevel: block.data.indentLevel },
          });
        }

        return;

      case "unorderedList":
        markdownJsonObjects.push({
          unorderedList: { items: block.data.items, indentLevel: block.data.indentLevel },
        });

        return;

      case "orderedList":
        markdownJsonObjects.push({
          orderedList: { items: block.data.items, indentLevel: block.data.indentLevel, numberForMarkdownDownloading: block.data.numberForMarkdownDownloading },
        });

        return;

      case "checklist":
        markdownJsonObjects.push({
          checkBox: block.data.items,
        });
        return;

      case "blockChecklist":
        markdownJsonObjects.push({
          blockCheckBox: { items: block.data.items, indentLevel: block.data.indentLevel },
        });
        return;

      case "video":
        markdownJsonObjects.push({
          p: "![Video captured by SLID but not visible to LLM]",
        });
        return;

      case "Math":
        markdownJsonObjects.push({
          latex: {
            latexContent: `${block.data.math}`,
          },
        });
        return;

      case "codeTool":
        markdownJsonObjects.push({
          p: "![The text result below is a result of Optical character recognition on an image in the study notes. The OCR result is presented in a code block. It might not be code. It has been added so that the LLM can see what is in image.]",
        });
        markdownJsonObjects.push({
          code: {
            content: block.data.code.split("\n"),
          },
        });
        return;

      case "codeMirrorTool":
        markdownJsonObjects.push({
          code: {
            content: block.data.code.split("\n"),
            language: block.data.language,
          },
        });
        return;

      case "divider":
        markdownJsonObjects.push({
          divider: "---",
        });
        return;
      case "audio":
        return;

      case "table":
        const tableContent = [...block.data.content];
        const tableObject = { table: { headers: [], rows: [] } };
        if (block.data.withHeadings) {
          tableObject.table.headers = tableContent.shift();
        } else {
          tableObject.table.headers = [...Array(tableContent[0].length).keys()].map((ind) => `${lang === "ko" ? "제목" : "Heading"} ${ind + 1}`);
        }

        if (tableContent.length) {
          tableObject["table"]["rows"] = tableContent;
        } else {
          tableObject.table.rows = [new Array(tableObject.table.headers.length).fill(`...`)];
        }

        markdownJsonObjects.push(tableObject);
        return;

      case "linkTool":
        if (block.data.meta?.link)
          markdownJsonObjects.push({
            link: { title: block.data.meta.title ? block.data.meta.title : "", source: block.data.meta.link ? block.data.meta.link : "" },
          });
        return;
      default:
        return;
    }
  });
  const resultMarkdown = json2md(markdownJsonObjects);
  return cleanMarkdown(resultMarkdown);
};

export const cleanMarkdown = (markdown) => {
  const htmlRegex = /<[^>]*>?/gm;
  const cleanedMarkdown = markdown.replace(htmlRegex, "");
  return cleanedMarkdown;
};
