import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $createTextNode, $getRoot, $nodesOfType, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand, LexicalNode } from "lexical";
import { mergeRegister } from "@lexical/utils";
import { $createSlidAutoNoteNode, SlidAutoNoteNode } from "components/NewEditor/nodes/SlidMobileAutoNoteNode";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { $createSlidParagraphNode, $isSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { PLAYGROUND_TRANSFORMERS } from "../MarkdownTransformers";

export const INSERT_AUTO_NOTE_LOADING_BLOCK_COMMAND: LexicalCommand<{ blockID: string }> = createCommand();

export const INSERT_AUTO_NOTE_RESULT_BLOCK_COMMAND: LexicalCommand<{ blockID: string | null; result: string }> = createCommand();

export const REMOVE_AUTO_NOTE_BLOCK_BY_BLOCK_ID_COMMAND: LexicalCommand<{ blockID: string }> = createCommand();

export const REMOVE_ALL_AUTO_NOTE_BLOCKS_COMMAND: LexicalCommand<void> = createCommand();

export const SlidMobileAutoNotePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        INSERT_AUTO_NOTE_LOADING_BLOCK_COMMAND,
        (payload) => {
          const autoNoteLoadingBlock = $createSlidAutoNoteNode({ isLoading: true, blockID: payload.blockID });
          const lastChild = $getRoot().getLastChild();
          if (lastChild) {
            const isParagraphNode = $isSlidParagraphNode(lastChild);
            const isEmptyParagraphNode = lastChild.getTextContent().trim() === "";
            if (isParagraphNode && isEmptyParagraphNode) {
              lastChild.replace(autoNoteLoadingBlock);
            } else {
              lastChild.insertAfter(autoNoteLoadingBlock);
              const paragraphNode = $createParagraphNode();

              autoNoteLoadingBlock.insertAfter(paragraphNode);
              paragraphNode.select();
            }
          } else {
            $getRoot().append(autoNoteLoadingBlock);
            autoNoteLoadingBlock.insertAfter($createParagraphNode());
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        INSERT_AUTO_NOTE_RESULT_BLOCK_COMMAND,
        (payload) => {
          const autoNoteLoadingNodes = editor.getEditorState().read(() => {
            const listNodes = $nodesOfType(SlidAutoNoteNode);
            return listNodes;
          });

          if (!autoNoteLoadingNodes) {
            return false;
          }

          const newAutoNoteIndicatorBlock = $createSlidAutoNoteNode({ isLoading: false, blockID: payload.blockID ?? "" });
          const cleanedText = payload.result.replace(/^\s*\n/gm, "");
          autoNoteLoadingNodes.forEach((autnoNodeNode) => {
            if (autnoNodeNode.__blockID === payload.blockID) {
              autnoNodeNode.replace(newAutoNoteIndicatorBlock);

              const paragraphNode = $createSlidParagraphNode();
              paragraphNode.append($createTextNode(cleanedText));
              newAutoNoteIndicatorBlock.insertAfter(paragraphNode);

              $convertFromMarkdownString(
                paragraphNode.getTextContent(),
                PLAYGROUND_TRANSFORMERS,
                paragraphNode, // node
                false,
                true
              );

              let lastInsertedNode: LexicalNode = paragraphNode;
              // NOTE: this is to prevent the nodes from being created in a nested structure of slid-paragraph node
              paragraphNode.getChildren().forEach((child) => {
                lastInsertedNode.insertAfter(child);
                lastInsertedNode = child;
                child.selectNext();
              });
              paragraphNode.remove();
            }
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_AUTO_NOTE_BLOCK_BY_BLOCK_ID_COMMAND,
        (payload) => {
          const autoNoteNodes = editor.getEditorState().read(() => {
            const listNodes = $nodesOfType(SlidAutoNoteNode);
            return listNodes;
          });

          if (!autoNoteNodes) {
            return false;
          }

          autoNoteNodes.forEach((node) => {
            if (node.__blockID === payload.blockID) {
              node.remove();
            }
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_ALL_AUTO_NOTE_BLOCKS_COMMAND,
        () => {
          const autoNoteNodes = editor.getEditorState().read(() => {
            const listNodes = $nodesOfType(SlidAutoNoteNode);
            return listNodes;
          });

          autoNoteNodes.forEach((node) => {
            node.remove();
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
};
