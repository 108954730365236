import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export default function BgColorCheckIcon({ width, height, color, style }: SvgIconProps) {
  return (
    <SvgWrapper width={width ? width : 24} height={height ? height : 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M6 12L10.5 16L18 8" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgWrapper>
  );
}
