import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const InlineLinkIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 32} height={height ? height : 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="link">
        <g id="Group 3465392">
          <path
            id="Vector"
            d="M16.1196 11.4777L16.8015 10.665C17.0699 10.3451 17.3987 10.0812 17.7691 9.88831C18.1395 9.69546 18.5442 9.57747 18.9603 9.54107C19.3763 9.50467 19.7954 9.55059 20.1936 9.67619C20.5919 9.80179 20.9615 10.0046 21.2814 10.2731C21.6013 10.5415 21.8652 10.8703 22.0581 11.2407C22.2509 11.6111 22.3689 12.0158 22.4053 12.4318C22.4417 12.8478 22.3958 13.2669 22.2702 13.6652C22.1446 14.0635 21.9418 14.4331 21.6733 14.7529L20.1619 16.5542L19.5209 17.3181C19.2522 17.6384 18.9229 17.9026 18.5521 18.0955C18.1812 18.2884 17.7759 18.4063 17.3594 18.4424C16.9429 18.4785 16.5234 18.4322 16.1248 18.306C15.7262 18.1798 15.3565 17.9763 15.0366 17.707C14.7057 17.4294 14.435 17.0871 14.2411 16.7011C14.0472 16.3151 13.9343 15.8936 13.9092 15.4623"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M15.0545 20.2405L14.3726 21.0532C14.1039 21.3734 13.7746 21.6376 13.4038 21.8305C13.0329 22.0234 12.6276 22.1413 12.2111 22.1774C11.7946 22.2136 11.3751 22.1672 10.9765 22.041C10.5779 21.9148 10.2082 21.7113 9.88833 21.4421C9.24389 20.8994 8.84113 20.1232 8.76846 19.2838C8.6958 18.4445 8.95918 17.6106 9.50077 16.9653L11.6532 14.4001C11.9216 14.0801 12.2504 13.8162 12.6208 13.6234C12.9912 13.4305 13.3959 13.3125 13.812 13.2761C14.228 13.2397 14.6471 13.2856 15.0453 13.4112C15.4436 13.5368 15.8132 13.7397 16.1331 14.0081C16.4653 14.2856 16.7371 14.6282 16.9317 15.0148C17.1264 15.4014 17.2398 15.8238 17.2649 16.2559"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </SvgWrapper>
  );
};
