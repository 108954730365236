import React from "react";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { REMOVE_AUTO_NOTE_BLOCK_BY_BLOCK_ID_COMMAND } from "components/NewEditor/plugins/SlidMobileAutoNotePlugin";

const SlidMobileAutoNoteComponent = ({ isLoading, blockID }: { isLoading: boolean; blockID: string }) => {
  const [editor] = useLexicalComposerContext();
  const { lang } = useAppSelector((state) => state.slidGlobal);

  const handleClickAutoNoteBlock = () => {
    editor.dispatchCommand(REMOVE_AUTO_NOTE_BLOCK_BY_BLOCK_ID_COMMAND, { blockID });
  };

  if (isLoading) {
    return (
      <LoadingBlockWrapper>
        <img src="/src/icons/icon_auto-note-star.svg" alt="auto-note-star" />
        <LoadingText>{lang === "ko" ? "자동 필기 중.." : "Generating auto note.."}</LoadingText>
        <ParagraphContainer data-type={`auto-note-text`} />
      </LoadingBlockWrapper>
    );
  }
  return (
    <>
      <NewBlockIndicator onClick={handleClickAutoNoteBlock}>
        <IndicatorLine />
        <NewBlockText>{lang === "ko" ? "새 필기" : "New Note"}</NewBlockText>
        <IndicatorLine />
      </NewBlockIndicator>
    </>
  );
};

const LoadingBlockWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: var(--red1);
  margin: 1.2rem 0;
`;

const NewBlockIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.2rem 0;
  width: 100%;
  gap: 0.8rem;
  flex: 1;
`;

const IndicatorLine = styled.div`
  height: 1px;
  flex: 1;
  background: var(--red5);
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ParagraphContainer = styled.p`
  font-size: inherit;
  outline: none;
  white-space: pre-wrap;
  -webkit-touch-callout: none;
  word-break: keep-all;
  word-wrap: break-word;
`;

const LoadingText = styled.div`
  &&& {
    /* NOTE: Since Slid Mobile App controls font size by injected js depends on screen mode(landscape/portrait), we need to adjust font size here forcefully */
    font-size: 85% !important;
  }
  font-weight: 500;
  color: var(--red5);
`;

const NewBlockText = styled.div`
  &&& {
    /* NOTE: Since Slid Mobile App controls font size by injected js depends on screen mode(landscape/portrait), we need to adjust font size here forcefully */
    font-size: 80% !important;
  }
  font-weight: 700;
  color: var(--red5);
`;

export default SlidMobileAutoNoteComponent;
