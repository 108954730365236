import React from "react";
import { $createParagraphNode, DecoratorNode, DOMExportOutput, type EditorConfig, LexicalNode, NodeKey, Spread } from "lexical";
import { SerializedDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import AutoNoteBlockView from "./AutoNoteBlockView";

export interface AutoNoteBlock {
  text: string;
}

export type EditorNotes = {
  text: string;
};

export interface TranscriptBlock {
  finalizedText: string;
  result?: string;
  blockId?: string;
}

export type AutoNotesLoaderBlockData = {
  newTranscriptBlock: TranscriptBlock;
  previousTranscriptBlocks: TranscriptBlock[];
  previousNotes: EditorNotes; //NOTE: these come from saving editor content.
  language: string;
  currentDocumentKey?: string;
};

export type SerializedSlidPCAutoNode = Spread<AutoNotesLoaderBlockData, SerializedDecoratorBlockNode>;

export class SlidPCAutoNode extends DecoratorNode<JSX.Element> {
  __data: AutoNotesLoaderBlockData;

  constructor(blockData: AutoNotesLoaderBlockData, key?: NodeKey) {
    super(key);
    this.__data = blockData;
  }

  static getType() {
    return "slid-pc-auto-note";
  }

  static clone(node: SlidPCAutoNode) {
    return new SlidPCAutoNode(node.__data, node.getKey());
  }

  static importJSON(serializedNode: SerializedSlidPCAutoNode) {
    return $createParagraphNode();
  }

  exportJSON() {
    return {
      type: "slid-pc-auto-note",
      version: 1,
    };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");

    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    return { element };
  }

  updateDOM(prevNode: SlidPCAutoNode, dom: HTMLElement): boolean {
    return false;
  }

  isInline() {
    return false;
  }

  canBeEmpty() {
    return true;
  }

  decorate() {
    return <AutoNoteBlockView {...this.__data} nodeKey={this.__key} />;
  }
}

export function $createSlidPCAutoNode(blockData: AutoNotesLoaderBlockData) {
  return new SlidPCAutoNode(blockData);
}

export function $isSlidPCAutoNode(node: LexicalNode | null | undefined): node is SlidPCAutoNode {
  return node instanceof SlidPCAutoNode;
}
