import { Typography14 } from "@slid/slid-ips";
import { AutoNotesEditorIcon } from "components/icons/autoNotesIcons";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const AutoNoteLoadingView = () => {
  const { t } = useTranslation("AutoNotes");
  return (
    <LoadingWrapper>
      <AutoNotesEditorIcon width={20} height={20} color="--blue9" />
      <Typography14 text={t("GeneratingNotes")} color="--blue9" weight={500} />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: var(--blue1);
  margin-top: 0.4em;
  margin-bottom: 0.4em;
`;

export default AutoNoteLoadingView;
