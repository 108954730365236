import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const BoldIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 32} height={height ? height : 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="bold">
        <path
          id="Vector"
          d="M11 15.6842H17.6667C18.5507 15.6842 19.3986 16.0169 20.0237 16.6091C20.6488 17.2014 21 18.0046 21 18.8421C21 19.6796 20.6488 20.4829 20.0237 21.0751C19.3986 21.6673 18.5507 22 17.6667 22H11V10H16.6667C17.4623 10 18.2254 10.2994 18.788 10.8324C19.3506 11.3654 19.6667 12.0883 19.6667 12.8421C19.6667 13.5959 19.3506 14.3188 18.788 14.8518C18.2254 15.3848 17.4623 15.6842 16.6667 15.6842"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgWrapper>
  );
};
