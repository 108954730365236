// auth 2.0
import { alertNoteContentCopied } from "components/alerts/index";
import { sendAmplitudeData } from "utils/amplitude";
import Sweetalert from "sweetalert2";
import { convertToMarkdown, exportToImage, exportToMarkdown, exportToWord, convertForAutoNotesLLMAsMarkdown, cleanMarkdown } from "utils/editor/download/download";
import { GlobalExport } from "utils/editor/download/globalExport";
import { fetchDocument, updateDocument } from "redux/actions/vdocsActions";
import { alertCreatingImageFiles, alertCreatingWordFile, alertEmptyNoteCopyingMarkdown, alertEmptyNoteExporting, alertEmptyNoteExportingAsImage } from "components/alerts/index";
import store from "redux/store";
import { isMacOs } from "react-device-detect";
import { trackEvent } from "utils/eventTracking";
import i18n from "config/i18n/i18n";
import { CustomClipboard } from "utils/editor/download/globalExport/customClipboard";
import { getEventLocationMode } from "utils/utils";
import { convertLexicalJSToEditorJS } from "components/NewEditor/utils/convertLexicalToEditorJS";
import useEditorStore from "store/useEditorStore";
import { PLAYGROUND_TRANSFORMERS } from "components/NewEditor/plugins/MarkdownTransformers";
import { $convertToMarkdownString } from "@lexical/markdown";

const t = i18n.t.bind(i18n);
interface ClipboardItem {
  readonly types: string[];
  getType: (type: string) => Promise<Blob>;
}

declare var ClipboardItem: {
  prototype: ClipboardItem;
  new (objects: Record<string, Blob>): ClipboardItem;
};

interface Clipboard {
  read?(): Promise<Array<ClipboardItem>>;
  write?(items: Array<ClipboardItem>): Promise<void>;
}

export const prepareCurrentNoteForExport = async () => {
  const { currentDocument, isReadOnly, editorInstance } = store.getState().vdocs;

  let contentToCopy;
  const parsedContent = JSON.parse(currentDocument.content);

  //if editor is not in readonly mode and there is an editor instance, save the content first(in mobile ver, there will be no editor instance)
  if (!isReadOnly && editorInstance) {
    const updatedContents = await editorInstance.save();

    await store.dispatch(
      updateDocument({
        documentKey: currentDocument["document_key"],
        title: currentDocument["title"],
        content: updatedContents,
        origin: getEventLocationMode(),
      })
    );
    contentToCopy = updatedContents;
  } else if (parsedContent.blocks) {
    // NOTE: EditorJS case
    await store.dispatch(
      fetchDocument({
        documentKey: currentDocument["document_key"],
      })
    );
    contentToCopy = parsedContent;
  } else {
    // NOTE: LexicalJS case
    const parsedContent = JSON.parse(currentDocument.content);
    contentToCopy = convertLexicalJSToEditorJS(parsedContent);
  }

  return contentToCopy as any;
};

export const downloadWord = async ({ target }: { target: string }) => {
  const { currentDocument } = store.getState().vdocs;

  if (!currentDocument) return alertEmptyNoteExporting({ target });

  const contentToCopy = await prepareCurrentNoteForExport();

  if (!contentToCopy) return alertEmptyNoteExporting({ target });

  alertCreatingWordFile({ target });

  exportToWord({
    currentContent: contentToCopy,
    title: currentDocument.title,
    documentKey: currentDocument["document_key"],
    callBack: () => {
      Sweetalert.close();
    },
  });

  sendAmplitudeData(`SLID_1_DOWNLOAD_DOCUMENT`, {
    type: `docx`,
  });
  sendAmplitudeData(`Download note`, {
    type: `docx`,
  });
};

export const downloadMarkdown = async ({ target }: { target: string }) => {
  const { currentDocument } = store.getState().vdocs;

  if (!currentDocument) return alertEmptyNoteCopyingMarkdown({ target });

  const contentToCopy = await prepareCurrentNoteForExport();

  if (!contentToCopy) return alertEmptyNoteCopyingMarkdown({ target });

  exportToMarkdown({
    currentContent: contentToCopy,
    title: currentDocument.title,
    documentKey: currentDocument["document_key"],
  });

  sendAmplitudeData(`SLID_1_DOWNLOAD_DOCUMENT`, {
    type: `markdown`,
  });
  sendAmplitudeData(`Download note`, {
    type: `markdown`,
  });
};

export const downloadImages = async ({ target }: { target: string }) => {
  const { currentDocument } = store.getState().vdocs;

  if (!currentDocument) alertEmptyNoteExportingAsImage({ target });

  const contentToCopy = await prepareCurrentNoteForExport();

  if (!contentToCopy) return alertEmptyNoteExportingAsImage({ target });

  const imageBlocks = contentToCopy.blocks.filter((block: { type: string }) => {
    return block.type === "image";
  });

  if (imageBlocks.length === 0) {
    alertEmptyNoteExportingAsImage({ target });
    return;
  }

  alertCreatingImageFiles({ target });

  exportToImage({
    currentContent: contentToCopy,
    title: currentDocument.title,
    callBack: () => {
      Sweetalert.close();
    },
  });

  sendAmplitudeData(`SLID_1_DOWNLOAD_DOCUMENT`, {
    type: `image`,
  });
  sendAmplitudeData(`Download note`, {
    type: `image`,
  });
};

/**
 * @param {target} target: copy markdown to clipboard for "notion"
 */
export const copyContentToClipboardAsMarkdown = async ({ target, showToast, copyType, isMobile }: any) => {
  const { lang } = store.getState().slidGlobal;
  const { currentDocument } = store.getState().vdocs;

  if (!currentDocument) return alertEmptyNoteCopyingMarkdown({ target });

  const contentToCopy = await prepareCurrentNoteForExport();

  if (!contentToCopy) return alertEmptyNoteCopyingMarkdown({ target });

  const markdownExportableContent = await convertToMarkdown({
    currentContent: contentToCopy,
    title: currentDocument.title,
    documentKey: currentDocument["document_key"],
    lang: lang,
  });

  const clipBoard = new CustomClipboard([{ type: "text/plain", data: markdownExportableContent }]);

  clipBoard
    .copy()
    .then(() => {
      if (copyType === "clipboard") alertNoteContentCopied({ isMacOs, target });
      else if (isMobile) {
        showToast(t("CopyToastTextForMobile", { ns: "ViewNote" }));
      } else {
        showToast(t("CopyToastText", { ns: "ViewNote", os: isMacOs ? "Cmd" : "Ctrl" }));
      }
    })
    .catch((error) => console.error("An error occurred!!", error));

  trackEvent({
    eventType: `Download note`,
    eventProperties: {
      type: copyType,
    },
  });

  sendAmplitudeData(`SLID_1_DOWNLOAD_DOCUMENT`, {
    type: copyType,
  });
};

/**
 * @param {string} target: convert note to html and copy to clip board
 */
export const copyNoteToClipboard = async ({ target, showToast, copyType, isMobile }: any) => {
  const { lang } = store.getState().slidGlobal;
  const { currentDocument } = store.getState().vdocs;

  if (!currentDocument) return alertEmptyNoteCopyingMarkdown({ target });

  const contentToCopy = await prepareCurrentNoteForExport();

  if (!contentToCopy) return alertEmptyNoteCopyingMarkdown({ target });

  /**
   * create global exporter object using the note content
   */
  const globalExporter = new GlobalExport({
    currentContent: contentToCopy,
    title: currentDocument.title,
    documentKey: currentDocument["document_key"],
    lang: lang,
    mappedVideos: currentDocument["mapped_videos"],
  });

  const htmlExportableContent = globalExporter.getNoteHTML();

  const markdownExportableContent = await convertToMarkdown({
    currentContent: contentToCopy,
    title: currentDocument.title,
    documentKey: currentDocument["document_key"],
    lang: lang,
  });

  const clipBoard = new CustomClipboard([
    { type: "text/html", data: htmlExportableContent },
    { type: "text/plain", data: markdownExportableContent },
  ]);

  clipBoard
    .copy()
    .then(() => {
      if (copyType === "clipboard") alertNoteContentCopied({ isMacOs, target });
      else if (isMobile) {
        showToast(t("CopyToastTextForMobile", { ns: "ViewNote" }));
      } else {
        showToast(t("CopyToastText", { ns: "ViewNote", os: isMacOs ? "Cmd" : "Ctrl" }));
      }
    })
    .catch((error) => console.error("An error occurred!!", error));

  trackEvent({
    eventType: `click COPY TO CLIPBOARD`,
    eventProperties: {
      origin: getEventLocationMode(), // "plain note" | "video note"
    },
  });
};

export const exportToSlidGPT = async () => {
  const { currentDocument, isReadOnly } = store.getState().vdocs;
  const lexicalEditorRef = useEditorStore.getState().lexicalEditorRef;

  if (!currentDocument || isReadOnly) return null;
  let markdownContent;
  if (lexicalEditorRef) {
    lexicalEditorRef.current?.update(() => {
      markdownContent = $convertToMarkdownString(PLAYGROUND_TRANSFORMERS);
    });
  }
  const resultMarkdown = `# ${currentDocument.title}\n\n${markdownContent}`;
  const cleanResultMarkdown = cleanMarkdown(resultMarkdown);

  return cleanResultMarkdown;
};

export const exportForAutoNotesLLMS = async () => {
  const { currentDocument, isReadOnly, editorInstance } = store.getState().vdocs;
  const { lang } = store.getState().slidGlobal;

  if (!currentDocument) return null;

  let contentToCopy = null;

  if (!isReadOnly && editorInstance) {
    const updatedContents = await editorInstance.saver.save();
    contentToCopy = updatedContents;
  }

  // NOTE: maybe we need a speical text here for Korean.
  if (!contentToCopy) return "# Untitled Note <!--No notes have been added yet --> \n\n";

  const markdownExportableContent = convertForAutoNotesLLMAsMarkdown({
    currentContent: contentToCopy,
    title: currentDocument.title || "Untitled Note",
    documentKey: currentDocument["document_key"],
    lang: lang,
  });

  return markdownExportableContent;
};
