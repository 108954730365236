import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createRangeSelection,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  FORMAT_TEXT_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DOWN_COMMAND,
  KEY_SPACE_COMMAND,
} from "lexical";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { mergeRegister } from "@lexical/utils";
import { convertToArrow } from "utils/utils";
import { useImageNodeStore } from "components/NewEditor/store/useImageNodeStore";
import { ModalType, useModalStore } from "store/useModalStore";
import { $isImageNode } from "components/NewEditor/nodes/SlidImageNode/ImageNode";
import { useAppSelector } from "hooks";

const SlidKeyboardPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const { setSelectedImageData } = useImageNodeStore();
  const { showModal } = useModalStore();
  const applicationType = useAppSelector((state) => state.slidGlobal.applicationType);

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(
        KEY_SPACE_COMMAND,
        (event: KeyboardEvent) => {
          if (applicationType === "slidpocket") return false;
          const selection = $getSelection();
          const currentNode = selection?.getNodes()[0];
          if ($isImageNode(currentNode)) {
            event.preventDefault();
            const currentImageData = currentNode.getData();
            setSelectedImageData(currentImageData);
            showModal({ type: ModalType.IMAGE_FULL_SCREEN });
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        (event: KeyboardEvent) => {
          const selection = $getSelection();
          const selectedNode = selection?.getNodes()[0];
          const selectedNodeType = selectedNode?.getType();

          if (!$isRangeSelection(selection)) {
            if (selectedNodeType === "horizontalrule") {
              event.preventDefault();
              const newParagraphNode = $createSlidParagraphNode();
              selectedNode?.replace(newParagraphNode);
              newParagraphNode.select();
              return true;
            }
            return false;
          }

          const selectedParentNodeType = selectedNode?.getParent()?.getType();
          const parentNode = selectedNode?.getParent();
          const isListItem = selectedNodeType === "listitem";
          const isParentListItem = selectedParentNodeType === "listitem";

          if (isListItem) {
            // NOTE: this is a workaround to delete the list item when the list item is selected and the backspace key is pressed while list item is empty.
            event.preventDefault();
            editor.update(() => {
              selection?.getNodes().forEach((node) => {
                const newParagraphNode = $createSlidParagraphNode();
                node.replace(newParagraphNode);
                newParagraphNode.select();
              });
            });
            return true;
          } else if (selectedNodeType === "text" && isParentListItem) {
            // NOTE: this is a workaround to convert list item text to paragraph text when the list item is selected and the backspace key is pressed while list item is NOT empty.
            const anchorNode = selection.anchor.getNode();
            const textContent = anchorNode.getTextContent();

            if (selection.anchor.offset !== 0 || textContent.length === 0) return false;
            event.preventDefault();
            editor.update(() => {
              const textContent = parentNode?.getTextContent();
              const textNode = $createTextNode(textContent);
              const newParagraphNode = $createSlidParagraphNode();
              newParagraphNode.append(textNode);
              parentNode?.replace(newParagraphNode);
              const newSelection = $createRangeSelection();
              newSelection.anchor.set(newParagraphNode.getKey(), 0, "element");
              newSelection.focus.set(newParagraphNode.getKey(), 0, "element");
              $setSelection(newSelection);
            });
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        KEY_DOWN_COMMAND,
        (event: KeyboardEvent) => {
          // note: if cmd+e or ctrl+e is pressed, then we should insert a new inline code node
          if ((event.metaKey || event.ctrlKey) && event.key === "e") {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
            // note: add a space after the inline code node
            const selection = $getSelection();
            const currentNode = selection?.getNodes()[0];
            if (currentNode?.getType() === "code") {
              const textNode = $createTextNode(" ");
              currentNode.insertAfter(textNode);
              const newSelection = $createRangeSelection();
              newSelection.anchor.set(textNode.getKey(), 0, "element");
              newSelection.focus.set(textNode.getKey(), 0, "element");
              $setSelection(newSelection);
            }
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        KEY_DOWN_COMMAND,
        (event: KeyboardEvent) => {
          convertToArrow(event);
          return false;
        },
        COMMAND_PRIORITY_HIGH
      )
    );

    return () => {
      unregister();
    };
  }, [editor]);

  return null;
};

export default SlidKeyboardPlugin;
