import type { TypedUseSelectorHook } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import type { RootState, AppDispatch } from "redux/store";

export { useGetCurrentPricingPlan } from "./useGetCurrentPlan";
export { useAccountTabOption } from "./useAccountOption";
export { useUsageHistory } from "./useUsageHistory";
export { useSearch } from "./useSearch";
export { useVideoNoteOnboarding } from "./useVideoNoteOnboarding";
export { useNoteDownload } from "./useNoteDownload";
export { useImageMarkup } from "./useImageMarkup";
export { useUser } from "./useUser";
export { useNewImageMarkup } from "./useNewImageMarkup";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
