import { $insertNodeToNearestRoot } from "@lexical/utils";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { useAppDispatch } from "hooks";
import { $createRangeSelection, $createTextNode, $getNodeByKey, $getSelection, $setSelection } from "lexical";
import { useHistory } from "react-router-dom";
import { setEditorLastActiveBlockPosition } from "redux/actions/vdocsActions";
import useEditorStore from "store/useEditorStore";
import { getCurrentDocumentKey } from "utils/editor/util";

export const useManualSmartLiveText = () => {
  const { lexicalEditorRef } = useEditorStore();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const insertTranscriptTextToEditor = async (sttBlock) => {
    const insertTranscriptText = () => {
      lexicalEditorRef.current?.update(() => {
        const selection = $createRangeSelection();
        const currentNodeKey = $getSelection()?.getNodes()[0]?.getKey();
        const paragraphNode = $createSlidParagraphNode();
        const textNode = $createTextNode(sttBlock.text);
        paragraphNode.append(textNode);
        if (currentNodeKey === null || currentNodeKey === undefined) {
          $insertNodeToNearestRoot(paragraphNode);
        } else {
          const currentNode = $getNodeByKey(currentNodeKey);
          const currentNodeType = currentNode?.getType();
          const isCurrentNodeEmpty = currentNode?.getTextContent() === "";
          if (isCurrentNodeEmpty && currentNodeType && ["paragraph", "slid-paragraph", "list", "list-item"].includes(currentNodeType)) {
            currentNode.replace(paragraphNode);
            const newParagraphNode = $createSlidParagraphNode();
            paragraphNode.insertAfter(newParagraphNode);
            const paragraphNodeKey = newParagraphNode.getKey();
            dispatch(setEditorLastActiveBlockPosition(paragraphNodeKey));
            selection.anchor.set(newParagraphNode.getKey(), 0, "element");
            selection.focus.set(newParagraphNode.getKey(), 0, "element");
            $setSelection(selection);
          } else {
            $insertNodeToNearestRoot(paragraphNode);
          }
        }
      });
    };

    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return false;
    history.replace(`./${currentDocumentKey}`);
    insertTranscriptText();
    return true;
  };

  return {
    insertTranscriptTextToEditor,
  };
};
