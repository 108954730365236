import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { setAutoNotesSTTErrorListener, setSTTSocketConnectionStatusListener, setWhisperAutoNotesResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import { useAppDispatch, useAppSelector } from "hooks";
import * as Sentry from "@sentry/browser";
import { setEndAutoNotesSTT, setStartAutoNotes, setIsSocketConnected, setIsAutoNotesBlockInserted } from "redux/modules/autoNotesSlice";
import { AutoNotesSTTBlock, AutoNotesResultBlock, LocalSTTData } from "../types";
import { AutoNotesLoaderBlockData } from "components/editor/blocks/autoNotes/types";
import { getCurrentDocumentKey } from "utils/editor/util";
import { exportForAutoNotesLLMS } from "utils/exportNote";
import useEditorStore from "store/useEditorStore";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import WhisperAutoNoteProgressInfo from "./WhisperAutoNoteProgressInfo";
import { $getRoot } from "lexical";
import { $createSlidPCAutoNode } from "components/NewEditor/nodes/SlidPCAutoNode";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { $insertNodeToNearestRoot } from "@lexical/utils";

const AUTO_NOTES_STT_BUFFER_WORD_COUNT = 100;

const WhisperAutoNotesMainUI = () => {
  const [localSTTData, setLocalSTTData] = useState<LocalSTTData | null>(null);
  // TODO: migrate some of this state to redux so that it can be kept around when the main UI is un-mounted. (You want to keep the active block and the previous blocks around even if the main UI is un-mounted for use when it is mounted back.)
  const [activeAutoNotesSTTBlock, setActiveAutoNotesSTTBlock] = useState<AutoNotesSTTBlock>({
    finalizedText: "",
    wordCount: 0,
    blockId: uuidv4(),
  }); //TODO: rename to transcriptBuffer.
  const [previousAutoNoteSTTBlocks, setPreviousAutoNoteSTTBlocks] = useState<AutoNotesSTTBlock[]>([]); // these are STT data blocks. -> rename to previousTranscriptBufferBlocks.

  // this one is used to prevent insertion of the same transcript buffer block twice.
  const [insertedAutoNoteBlocks, setInsertedAutoNoteBlocks] = useState<AutoNotesResultBlock[]>([]); // these are the auto notes result blocks. -> rename to insertedTranscriptBufferBlocks.
  const dispatch = useAppDispatch();
  const { isVideoPlaying, isExtensionMediaPermitted, currentVideo } = useAppSelector((state) => state.vdocs);
  const { isAutoNotesToggledOn, autoNotesResultLang, isAutoNotesBlockInserted } = useAppSelector((state) => state.autoNotes);
  const { setPlaceholderVisibilityBasedOnContent, lexicalEditorRef } = useEditorStore();

  const insertAutoNotesLoaderBlock = async (newBlock: AutoNotesResultBlock, previousAutoNotesResultBlocks: AutoNotesResultBlock[]) => {
    //TODO: if you need to mark this block id as inserted in redux, you can do it here...
    setPlaceholderVisibilityBasedOnContent(true);
    const insertEditorBlock = async (editorMarkdownNotes: string) => {
      const newTranscriptBlock = {
        finalizedText: newBlock.finalizedText,
      };

      // NOTE: we either path these previous transcript blocks this way to show the LLM its previous work or we just save the editor contents and pass them for it as reference.

      // TODO: we might not have to do this once we have the script already added to the editor. OR we can save this data as state on the backend?? And have some kind of agent with state.
      const previousTranscriptBlocks = insertedAutoNoteBlocks //NOTE: this state object might become irrelavant as we put everything in the editor.
        .filter((block) => block.blockId !== newBlock.blockId)
        .map((block) => {
          //TODO: we might be sending this data differently later.
          return {
            finalizedText: block.finalizedText,
            result: block.result,
          };
        });
      const blockData: AutoNotesLoaderBlockData = {
        newTranscriptBlock: newTranscriptBlock,
        previousTranscriptBlocks: previousTranscriptBlocks,
        language: autoNotesResultLang,
        previousNotes: { text: editorMarkdownNotes },
      };
      lexicalEditorRef.current?.update(() => {
        const autoNoteLoadingNode = $createSlidPCAutoNode(blockData);
        const lastChild = $getRoot().getLastChild();

        if (lastChild) {
          const isLastChildParagraphNode = lastChild.getType() === "slid-paragraph";
          const isLastChildEmpty = lastChild.getTextContent() === "";
          if (isLastChildParagraphNode && isLastChildEmpty) {
            lastChild.replace(autoNoteLoadingNode);
          } else {
            lastChild.insertAfter(autoNoteLoadingNode);
          }
          const newParagraphNode = $createSlidParagraphNode();
          autoNoteLoadingNode.insertAfter(newParagraphNode);
          newParagraphNode.select();
        } else {
          $insertNodeToNearestRoot(autoNoteLoadingNode);
        }
      });
    };
    const currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return false;
    const markdownContent = await exportForAutoNotesLLMS();
    await insertEditorBlock(markdownContent);
    dispatch(setIsAutoNotesBlockInserted(true));
    return true;
  };

  useEffect(() => {
    let timeoutId;
    if (isAutoNotesBlockInserted) {
      timeoutId = setTimeout(() => {
        dispatch(setIsAutoNotesBlockInserted(false));
      }, 500);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isAutoNotesBlockInserted, dispatch]);

  useEffect(() => {
    setSTTSocketConnectionStatusListener({
      responseHandler: ({ isSTTSocketConnected }: { isSTTSocketConnected: boolean }) => {
        dispatch(setIsSocketConnected(isSTTSocketConnected)); //socket must be connected for auto notes to work.
      },
    });
    setWhisperAutoNotesResponseListener({
      responseHandler: (receivedData: { result: string }) => {
        setLocalSTTData({ text: receivedData.result, isFinal: true });
      },
    });
    setAutoNotesSTTErrorListener({
      responseHandler: (receivedData: any) => {
        console.log("AUTO NOTES STT ERROR - ", receivedData);
        // dispatch(setSTTError(receivedData.error));
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          scope.setExtra("message", "STT Error during auto notes \n" + JSON.stringify(receivedData));
          Sentry.captureMessage("SLID_WEB_AUTO_NOTE_STT_ERROR");
        });
      },
    });
  }, []);

  useEffect(() => {
    // console.log("localSTTData", localSTTData);
    function appendLocalSTTDataToActiveAutoNotesSTTBlockOnIsFinal() {
      if (localSTTData && localSTTData.isFinal) {
        //TODO: the active block will be built upto like 100 words...
        if (!localSTTData.text) return;
        setActiveAutoNotesSTTBlock((prev) => ({
          ...prev,
          finalizedText: `${prev.finalizedText} ${localSTTData.text}`,
          wordCount: prev.wordCount + localSTTData.text.split(" ").length,
        }));
      }
    }
    appendLocalSTTDataToActiveAutoNotesSTTBlockOnIsFinal();
  }, [localSTTData]);

  useEffect(() => {
    function flushSTTBufferIfFull() {
      if (activeAutoNotesSTTBlock.wordCount >= AUTO_NOTES_STT_BUFFER_WORD_COUNT) {
        setPreviousAutoNoteSTTBlocks((prev) => [...prev, { ...activeAutoNotesSTTBlock }]);
        setActiveAutoNotesSTTBlock({
          finalizedText: "",
          wordCount: 0,
          blockId: uuidv4(),
        });
        setLocalSTTData(null);
      }
    }
    flushSTTBufferIfFull();
  }, [activeAutoNotesSTTBlock]);

  useEffect(() => {
    function insertAutoNotesLoaderBlockFromPreviousAutoNoteSTTBlocks() {
      const latestBlock = previousAutoNoteSTTBlocks[previousAutoNoteSTTBlocks.length - 1];
      if (!latestBlock) return;
      const isLatestBlockAlreadyInserted = insertedAutoNoteBlocks.some((block) => block.blockId === latestBlock.blockId);
      if (!isLatestBlockAlreadyInserted) {
        // generateAutoNotesResult({ finalizedText: latestBlock.finalizedText, result: "", blockId: latestBlock.blockId, status: "default", isInserted: false });

        insertAutoNotesLoaderBlock({ finalizedText: latestBlock.finalizedText, result: "", blockId: latestBlock.blockId, status: "default", isInserted: false }, [...insertedAutoNoteBlocks]);

        // NOTE: the latest block is now added to the auto notes result blocks marked as inserted.
        setInsertedAutoNoteBlocks((prev) => [...prev, { finalizedText: latestBlock.finalizedText, result: "", blockId: latestBlock.blockId, status: "default", isInserted: true }]); //TODO: move this to redux. It is for tracking which auto notes blocks have made it into the editor.
      }
    }
    insertAutoNotesLoaderBlockFromPreviousAutoNoteSTTBlocks();
  }, [previousAutoNoteSTTBlocks, insertedAutoNoteBlocks]);

  useEffect(() => {
    function startOrEndAutoNotes() {
      const shouldStartAutoNotesSTT = autoNotesResultLang && isExtensionMediaPermitted && isAutoNotesToggledOn && (currentVideo?.videoType === "iframe" || isVideoPlaying);
      if (shouldStartAutoNotesSTT) {
        dispatch(setStartAutoNotes());
        const isAutoNotesPermissionRequested = localStorage.getItem("isAutoNotesPermissionRequested");
        if (isAutoNotesPermissionRequested) {
          trackEvent({
            eventType: eventTypes.success.AUTO_NOTES_START,
          });
          localStorage.removeItem("isAutoNotesPermissionRequested");
        }
      } else {
        //end the auto notes stt as conditions are not met
        dispatch(setEndAutoNotesSTT());
      }
    }
    startOrEndAutoNotes();
    //don't include localSTTData.text in the dependency array.
  }, [autoNotesResultLang, isVideoPlaying, isExtensionMediaPermitted, isAutoNotesToggledOn, currentVideo?.videoType, dispatch]);

  return <WhisperAutoNoteProgressInfo />;
};

export default WhisperAutoNotesMainUI;
