// auth 2.0
import {
  SET_IS_WEB_CAPTURE_AVAILABLE,
  SET_DOCUMENT,
  SET_NOTE_BLOCKS,
  FETCH_DOCUMENT,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  MOVE_DOCUMENT,
  SET_CURRENT_VIDEO,
  FETCH_VIDEOS,
  UPDATE_CURRENT_VIDEO,
  UPDATE_VIDEO,
  FETCH_DOCUMENTS,
  FETCH_DELETED_DOCUMENTS,
  SET_VIDEO_INDEX,
  SET_CAPTURE_INSERT_POSITION,
  SET_IS_EDITOR_SAVED,
  SET_EDITOR_MODE,
  TOGGLE_EDITOR,
  SET_VIDEO_PLACEHOLDER_RECT,
  SET_CAPTURE_AREA_RECT,
  SET_IS_CAPTURE_AREA_ACTIVE,
  SET_SHOW_SEARCH_POPUP,
  SET_SHOW_SHARE_POPUP,
  SET_SHOW_IMAGE_MARKUP_POPUP,
  SET_SHOW_IMAGE_FULL_SCREEN,
  SET_NEED_TO_SIGN_UP,
  SET_SHOW_DELETED_DOCUMENTS_POPUP,
  SET_IS_LIST_OPEN,
  SET_IS_EDITOR_OPTION_OPEN,
  SET_IS_PDF_UPLOADER_CLICKED,
  SET_EDITOR_INSTANCE,
  SET_IS_READ_ONLY,
  SET_UNDO_INSTANCE,
  SET_VIDEO_PLAYER_REF,
  SET_EDITOR_WRAPPER_CLASS_NAME,
  SET_EDITOR_LAST_ACTIVE_BLOCK_POSITION,
  SET_IS_RECORD_ACTIVE,
  SET_LOADER_BLOCK_KEYS,
  SET_IS_HELP_MENU_OPEN,
  SET_IS_SAVE_DOCUMENT_CALLED,
  SET_IS_SET_CAPTURE_AREA_BUTTON_CLICKED,
  // desktop
  SET_IFRAME_PORT,
  SET_SELECTED_SOURCE_DATA,
  SET_DESKTOP_DEFAULT_CAPTURE_RECT,
  SET_DESKTOP_CAPTURE_RECT,
  SET_DESKTOP_CAPTURE_RECT_2,
  SET_IS_GUEST_MODE_ALERT_SHOWN,
  SET_IS_DESKTOP_CAPTURE_AREA_SETTING,
  SET_IS_DESKTOP_CAPTURE_AREA_SET,
  // history
  GET_DOCUMENT_HISTORY_LIST,
  CREATE_DOCUMENT_HISTORY,
  SET_IS_CAPTURE_AREA_SET_FOR_SNIP_CAPTURE,
  SET_CAPTURE_RECT,
  SET_IS_CAPTURE_AREA_SET_FOR_ONE_CLICK_CAPTURE,
  SET_IS_CAPTURE_AREA_RESET,
  SET_CURRENT_FOLDER,
  SET_SELECTABLE_PROCESSES,

  //app loading state
  SET_IS_EDITOR_LOADING,
  SET_IS_EDITOR_NOTE_LOADING,

  //for stt
  SET_IS_VIDEO_PLAYING,
  SET_SHOW_DISABLE_MEDIA_RECORDING_MODAL,
  SET_MEDIA_RECORDING_CHANGE_DIRECTION,
  SET_IS_STT_SUPPORTED,

  //for extension permission
  SET_IS_EXTENSION_MEDIA_PERMITTED,
  SET_IS_GETTING_EXTENSION_MEDIA_PERMISSION,
  SET_SHOW_EXTENSION_MEDIA_PERMISSION_MODAL,
  SET_IS_GETTING_CLIP_RECORDING_MEDIA_PERMISSION,

  // For Tablet & Image cropping
  SET_IS_IMAGE_CROPPING_MODAL_OPEN,
  SET_IMAGE_CROP_AREA,
  SET_IMAGE_CROPPING_MODAL_IMAGE_SOURCE,
  SET_EDIT_IMAGE_CROP_AREA,
  SET_SHOULD_UPDATE_IMAGE_BY_CROP,
  SET_CROPPING_IMAGE_BLOCK_DATA,
  SET_TABLET_DEFAULT_CROP_AREA,
} from "redux/actions/actionTypes";

const initialState = {
  isWebCaptureAvailable: false,
  currentDocument: null,
  noteBlocks: [],
  documents: [],
  deletedDocuments: [],
  currentFolder: null,
  videosData: null,
  currentVideo: null,
  videoIndex: 0,
  captureInsertPosition: "current", // current, last
  isEditorSaved: true,
  isCaptureAreaActive: false,
  editorMode: "edit", // edit, readOnly, viewer
  editorIsDisplayed: true,
  videoPlaceholderRect: {
    top: null,
    left: null,
    width: null,
    height: null,
  },
  showSearchPopup: false,
  showSharePopup: false,
  showImageMarkupPopup: false,
  showImageFullScreen: false,
  needToSignUp: false,
  showDeletedDocumentsPopup: false,
  isListOpen: false,
  isEditorOptionOpen: false,
  isPDFUploaderClicked: false,
  isReadOnly: false,
  editorInstance: null,
  undoInstance: null,
  videoPlayerRef: null,
  editorWrapperClassName: null,
  editorLastActiveBlockPosition: null,
  isRecordActive: false,
  loaderBlockKeys: [],
  documentHistory: null,
  documentHistoryList: [],
  isHelpMenuOpen: false,
  isSaveDocumentCalled: false,
  isSetCaptureAreaButtonClicked: false,
  // for stt
  isVideoPlaying: false,
  showDisableMediaRecordingModal: false,
  mediaRecordingChangeDirection: null,
  isSTTSupported: false,

  // for extension capture media api permission
  isExtensionMediaPermitted: false,
  isGettingExtensionMediaPermission: false,
  showExtensionMediaPermissionModal: false,
  isGettingClipRecordingMediaPermission: false,

  // app loading state
  isEditorLoading: true,
  isEditorNoteLoading: true,
  // For web capture
  isCaptureAreaSetForSnipCapture: false,
  captureRect: null,
  isCaptureAreaSetForOneClickCapture: false,
  isCaptureAreaReset: false,
  //tablet
  isImageCroppingModalOpen: false,
  imageCroppingModalImageSource: null,
  imageCropArea: null,
  tabletDefaultCropArea: null,
  editImageCropArea: false,
  shouldUpdateImageByCrop: null,
  croppingImageBlockData: null,
  // desktop
  iframePort: null,
  showGuestModeAlert: false,
  selectedSourceData: null,
  selectableProcesses: null,
  isDesktopCaptureAreaSetting: false,
  isDesktopCaptureAreaSet: false,
  desktopDefaultCaptureRect: {
    width: 1500,
    height: 900,
    x: 0,
    y: 0,
  },
  desktopCaptureRect: {
    width: 600,
    height: 400,
    x: 200,
    y: 200,
  },
  desktopCaptureRect2: {
    width: 600,
    height: 400,
    x: 200,
    y: 200,
  },
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload,
      };
    case SET_NOTE_BLOCKS:
      return {
        ...state,
        noteBlocks: action.payload,
      };
    case FETCH_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload,
      };
    case CREATE_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload,
      };
    case UPDATE_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload,
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        currentDocument: null,
      };
    case MOVE_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload,
      };
    case FETCH_VIDEOS:
      return {
        ...state,
        videosData: action.payload,
      };
    case SET_CURRENT_VIDEO:
      return {
        ...state,
        currentVideo: action.payload,
      };
    case UPDATE_CURRENT_VIDEO:
      return {
        ...state,
        currentVideo: {
          ...state.currentVideo,
          ...action.payload,
        },
      };
    case UPDATE_VIDEO:
      return {
        ...state,
        currentDocument: action.payload,
      };
    case FETCH_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case FETCH_DELETED_DOCUMENTS:
      return {
        ...state,
        deletedDocuments: action.payload,
      };
    case SET_VIDEO_INDEX:
      return {
        ...state,
        videoIndex: action.payload,
      };
    case SET_CAPTURE_INSERT_POSITION:
      return {
        ...state,
        captureInsertPosition: action.payload,
      };
    case SET_IS_EDITOR_SAVED:
      return {
        ...state,
        isEditorSaved: action.payload,
      };

    case SET_EDITOR_MODE:
      return {
        ...state,
        editorMode: action.payload,
      };
    case TOGGLE_EDITOR:
      return {
        ...state,
        editorIsDisplayed: !state.editorIsDisplayed,
      };
    case SET_VIDEO_PLACEHOLDER_RECT:
      return {
        ...state,
        videoPlaceholderRect: action.payload,
      };
    case SET_CAPTURE_AREA_RECT:
      return {
        ...state,
        captureAreaRect: action.payload,
      };
    case SET_IS_CAPTURE_AREA_ACTIVE:
      return {
        ...state,
        isCaptureAreaActive: action.payload,
      };
    case SET_SHOW_SEARCH_POPUP:
      return {
        ...state,
        showSearchPopup: action.payload,
      };
    case SET_SHOW_SHARE_POPUP:
      return {
        ...state,
        showSharePopup: action.payload,
      };
    case SET_SHOW_IMAGE_MARKUP_POPUP:
      return {
        ...state,
        showImageMarkupPopup: action.payload,
      };
    case SET_SHOW_IMAGE_FULL_SCREEN:
      return {
        ...state,
        showImageFullScreen: action.payload,
      };
    case SET_NEED_TO_SIGN_UP:
      return {
        ...state,
        needToSignUp: action.payload,
      };
    case SET_SHOW_DELETED_DOCUMENTS_POPUP:
      return {
        ...state,
        showDeletedDocumentsPopup: action.payload,
      };
    case SET_IS_LIST_OPEN:
      return {
        ...state,
        isListOpen: action.payload,
      };
    case SET_IS_EDITOR_OPTION_OPEN:
      return {
        ...state,
        isEditorOptionOpen: action.payload,
      };
    case SET_IS_PDF_UPLOADER_CLICKED:
      return {
        ...state,
        isPDFUploaderClicked: action.payload,
      };

    case SET_IS_HELP_MENU_OPEN:
      return {
        ...state,
        isHelpMenuOpen: action.payload,
      };
    case SET_IS_SAVE_DOCUMENT_CALLED:
      return {
        ...state,
        isSaveDocumentCalled: action.payload,
      };
    case SET_EDITOR_INSTANCE:
      return {
        ...state,
        editorInstance: action.payload,
      };
    case SET_IS_READ_ONLY:
      return {
        ...state,
        isReadOnly: action.payload,
      };
    case SET_UNDO_INSTANCE:
      return {
        ...state,
        undoInstance: action.payload,
      };
    case SET_VIDEO_PLAYER_REF:
      return {
        ...state,
        videoPlayerRef: action.payload,
      };
    case SET_EDITOR_WRAPPER_CLASS_NAME:
      return {
        ...state,
        editorWrapperClassName: action.payload,
      };
    case SET_EDITOR_LAST_ACTIVE_BLOCK_POSITION:
      return {
        ...state,
        editorLastActiveBlockPosition: action.payload,
      };
    case SET_IS_RECORD_ACTIVE:
      return {
        ...state,
        isRecordActive: action.payload,
      };
    case SET_LOADER_BLOCK_KEYS:
      return {
        ...state,
        loaderBlockKeys: action.payload,
      };
    // desktop
    case SET_IFRAME_PORT:
      return {
        ...state,
        iframePort: action.payload,
      };

    case SET_SELECTED_SOURCE_DATA:
      return {
        ...state,
        selectedSourceData: action.payload,
      };

    case SET_IS_GUEST_MODE_ALERT_SHOWN:
      return {
        ...state,
        showGuestModeAlert: action.payload,
      };

    case SET_IS_DESKTOP_CAPTURE_AREA_SETTING:
      return {
        ...state,
        isDesktopCaptureAreaSetting: action.payload,
      };

    case SET_IS_DESKTOP_CAPTURE_AREA_SET:
      return {
        ...state,
        isDesktopCaptureAreaSet: action.payload,
      };

    case SET_DESKTOP_DEFAULT_CAPTURE_RECT:
      return {
        ...state,
        desktopDefaultCaptureRect: action.payload,
      };

    case SET_DESKTOP_CAPTURE_RECT:
      return {
        ...state,
        desktopCaptureRect: action.payload,
      };
    case SET_DESKTOP_CAPTURE_RECT_2:
      return {
        ...state,
        desktopCaptureRect2: action.payload,
      };
    // history
    case GET_DOCUMENT_HISTORY_LIST:
      return {
        ...state,
        documentHistoryList: action.payload,
      };
    case CREATE_DOCUMENT_HISTORY:
      return {
        ...state,
        documentHistory: action.payload,
      };
    case SET_IS_CAPTURE_AREA_SET_FOR_SNIP_CAPTURE:
      return {
        ...state,
        isCaptureAreaSetForSnipCapture: action.payload,
      };
    case SET_CAPTURE_RECT:
      return {
        ...state,
        captureRect: action.payload,
      };
    case SET_IS_CAPTURE_AREA_SET_FOR_ONE_CLICK_CAPTURE:
      return {
        ...state,
        isCaptureAreaSetForOneClickCapture: action.payload,
      };
    case SET_IS_CAPTURE_AREA_RESET:
      return {
        ...state,
        isCaptureAreaReset: action.payload,
      };
    case SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload,
      };
    case SET_SELECTABLE_PROCESSES:
      return {
        ...state,
        selectableProcesses: action.payload,
      };
    case SET_IS_EDITOR_LOADING:
      return {
        ...state,
        isEditorLoading: action.payload,
      };
    case SET_IS_EDITOR_NOTE_LOADING:
      return {
        ...state,
        isEditorNoteLoading: action.payload,
      };
    case SET_IS_VIDEO_PLAYING:
      return {
        ...state,
        isVideoPlaying: action.payload,
      };
    case SET_IS_EXTENSION_MEDIA_PERMITTED:
      return {
        ...state,
        isExtensionMediaPermitted: action.payload,
      };
    case SET_IS_GETTING_CLIP_RECORDING_MEDIA_PERMISSION:
      return {
        ...state,
        isGettingClipRecordingMediaPermission: action.payload,
      };
    case SET_SHOW_DISABLE_MEDIA_RECORDING_MODAL:
      return {
        ...state,
        showDisableMediaRecordingModal: action.payload,
      };
    case SET_MEDIA_RECORDING_CHANGE_DIRECTION:
      return {
        ...state,
        mediaRecordingChangeDirection: action.payload,
      };
    case SET_IS_STT_SUPPORTED:
      return {
        ...state,
        isSTTSupported: action.payload,
      };
    case SET_IS_GETTING_EXTENSION_MEDIA_PERMISSION:
      return {
        ...state,
        isGettingExtensionMediaPermission: action.payload,
      };
    case SET_SHOW_EXTENSION_MEDIA_PERMISSION_MODAL:
      return {
        ...state,
        showExtensionMediaPermissionModal: action.payload,
      };

    case SET_IS_WEB_CAPTURE_AVAILABLE:
      return {
        ...state,
        isWebCaptureAvailable: action.payload,
      };

    case SET_IS_SET_CAPTURE_AREA_BUTTON_CLICKED:
      return {
        ...state,
        isSetCaptureAreaButtonClicked: action.payload,
      };

    // For Tablet & Image cropping
    case SET_IS_IMAGE_CROPPING_MODAL_OPEN:
      return {
        ...state,
        isImageCroppingModalOpen: action.payload,
      };
    case SET_IMAGE_CROPPING_MODAL_IMAGE_SOURCE:
      return {
        ...state,
        imageCroppingModalImageSource: action.payload,
      };
    case SET_IMAGE_CROP_AREA:
      return {
        ...state,
        imageCropArea: action.payload,
      };
    case SET_TABLET_DEFAULT_CROP_AREA:
      return {
        ...state,
        tabletDefaultCropArea: action.payload,
      };
    case SET_EDIT_IMAGE_CROP_AREA:
      return {
        ...state,
        editImageCropArea: action.payload,
      };
    case SET_SHOULD_UPDATE_IMAGE_BY_CROP:
      return {
        ...state,
        shouldUpdateImageByCrop: action.payload,
      };
    case SET_CROPPING_IMAGE_BLOCK_DATA:
      return {
        ...state,
        croppingImageBlockData: action.payload,
      };

    default:
      return state;
  }
};

export default mainReducer;
