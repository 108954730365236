import { create } from "zustand";
import React from "react";
import { LexicalEditor } from "lexical";

type EditorStore = {
  currentDocumentKey: string;
  showEditorPlaceholder: boolean;
  editorJSHolderRef: React.RefObject<HTMLDivElement>;
  lexicalEditorRef: React.RefObject<LexicalEditor>;
  fileUploadInputRef: React.RefObject<HTMLInputElement>;
  isFetchingDocument: boolean;
  editorDocumentTitle: string;
  editorScrollWrapperRef: React.RefObject<HTMLDivElement>;
  isEditorFocused: boolean;
  setIsFetchingDocument: (isFetching: boolean) => void;
  setPlaceholderVisibilityBasedOnContent: (hasContent: boolean) => void;
  focusEditor: () => void;
  blurEditor: (hasContent: boolean) => void;
  initializeEditor: () => void;
  setEditorDocumentTitle: (title: string) => void;
};

const editorJSHolderRef = React.createRef<HTMLDivElement>();
const lexicalEditorRef = React.createRef<LexicalEditor>();
const fileUploadInputRef = React.createRef<HTMLInputElement>();
const editorScrollWrapperRef = React.createRef<HTMLDivElement>();

const useEditorStore = create<EditorStore>((set, get) => ({
  currentDocumentKey: "",
  showEditorPlaceholder: true,
  editorJSHolderRef,
  lexicalEditorRef,
  fileUploadInputRef,
  editorScrollWrapperRef,
  isFetchingDocument: false,
  isEditorFocused: false,
  setIsFetchingDocument: (isFetching: boolean) => {
    set({
      isFetchingDocument: isFetching,
    });
  },
  editorDocumentTitle: "",
  setEditorDocumentTitle: (title: string) => {
    set({
      editorDocumentTitle: title,
    });
  },
  setPlaceholderVisibilityBasedOnContent: (hasContent: boolean) => {
    set({
      showEditorPlaceholder: !hasContent,
    });
  },
  focusEditor: () => {
    set({
      showEditorPlaceholder: false,
      isEditorFocused: true,
    });
  },
  blurEditor: (hasContent: boolean) => {
    set({
      showEditorPlaceholder: !hasContent,
      isEditorFocused: false,
    });
  },
  initializeEditor: () => {
    set({
      currentDocumentKey: "",
      showEditorPlaceholder: true,
    });
  },
}));

export default useEditorStore;
