import React, { useRef } from "react";
import { $createRangeSelection, $createTextNode, $getRoot, $setSelection } from "lexical";
import useEditorStore from "store/useEditorStore";
import { SlidFeatures } from "utils/privilegeManager";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { sendAmplitudeData } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks";
import { createDocument, setShowSearchPopup } from "redux/actions/vdocsActions";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "redux/store";
import { $createSlidParagraphNode } from "../nodes/SlidParagraphNode";

const usePlaceholder = () => {
  const location = useLocation();
  const history = useHistory();
  const { focusEditor, lexicalEditorRef, fileUploadInputRef, editorDocumentTitle } = useEditorStore();
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const mode = useRef(location.pathname.split("/")[1] === "vdocs" ? "vdocs" : "plain docs");
  const dispatch = useAppDispatch();
  const currentDocument = useAppSelector((state: RootState) => state.vdocs.currentDocument);

  const handleClickRecentNote = () => {
    const isUserPrivilegedToCreateNote = confirmPrivilege(SlidFeatures.noteCreation);
    sendAmplitudeData(`Click open recent note`, {
      origin: mode.current,
    });
    if (!isUserPrivilegedToCreateNote) return showInsufficientPrivilegeModal();
    dispatch(setShowSearchPopup(true));
  };

  const createNewDocumentThenReplaceUrl = async () => {
    const createDocumentResponse: any = await dispatch(
      createDocument({
        title: editorDocumentTitle,
        content: {},
        origin: mode.current,
      })
    );

    if (createDocumentResponse?.document_key) history.replace(`./${createDocumentResponse?.document_key}`);
  };

  const hidePlaceholderAndFocusEditor = () => {
    focusEditor();
    lexicalEditorRef.current?.update(() => {
      const root = $getRoot();
      const lastNode = root.getLastChild();

      if (lastNode) {
        // Check if the node is a text node or an element node
        if (lastNode.getType() === "slid-paragraph") {
          // Place the cursor at the end of the text node
          lastNode.selectEnd();
        } else if (lastNode.getType() === "slid-paragraph") {
          // If it's an element node, select its last child or create a RangeSelection
          const lastChild = root.getLastChild();
          if (lastChild && lastChild.getType() === "slid-paragraph") {
            lastChild.selectEnd();
          } else {
            const rangeSelection = $createRangeSelection();
            rangeSelection.anchor.set(lastNode.getKey(), 0, "element");
            rangeSelection.focus.set(lastNode.getKey(), 0, "element");
            $setSelection(rangeSelection);
          }
        }
      } else {
        // If there are no nodes, optionally add a new node and select it
        const newParagraph = $createSlidParagraphNode();
        root.append(newParagraph);
        const newTextNode = $createTextNode();
        newParagraph.append(newTextNode);
        newTextNode.select();
      }
    });
  };

  const handlePlaceholderClick = async () => {
    // NOTE: create a new document when clicking on placeholder if current document is not found
    if (!currentDocument || currentDocument.document_key === "new") {
      await createNewDocumentThenReplaceUrl();
      // NOTE: settimeout is a workaround to prevent the placeholder from hiding too early before the document is fetched
      setTimeout(() => {
        hidePlaceholderAndFocusEditor();
      }, 500);
      return;
    }

    hidePlaceholderAndFocusEditor();
  };

  return {
    handlePlaceholderClick,
    handleClickRecentNote,
  };
};

export default usePlaceholder;
