import { Typography15 } from "@slid/slid-ips";
import { AutoNotesErrorIcon } from "components/icons/autoNotesIcons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const AutoNoteErrorView = () => {
  const { t } = useTranslation("AutoNotes");
  return (
    <MainWrapper>
      <IconWrapper>
        <AutoNotesErrorIcon width={20} height={20} />
      </IconWrapper>
      <TextWrapper>
        <Typography15 text={t("AutoNotesErrorMessage")} color="--red6" weight={400} />
      </TextWrapper>
    </MainWrapper>
  );
};

export default AutoNoteErrorView;

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: flex-start;
  gap: 6px;
  border-radius: 4px;
  background: var(--red1);
`;

const IconWrapper = styled.div``;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
