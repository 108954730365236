import { $createParagraphNode, DecoratorNode, DOMExportOutput, type EditorConfig, LexicalNode, NodeKey, Spread } from "lexical";
import SlidMobileAutoNoteComponent from "./SlidMobileAutoNoteComponent";
import React from "react";
import { SerializedDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";

export type SerializedSlidAutoNoteNode = Spread<
  {
    isLoading: boolean;
    blockID: string;
  },
  SerializedDecoratorBlockNode
>;

export class SlidAutoNoteNode extends DecoratorNode<JSX.Element> {
  __isLoading: boolean;
  __blockID: string;
  constructor(key?: NodeKey, isLoading: boolean = false, blockID: string = "") {
    super(key);
    this.__isLoading = isLoading;
    this.__blockID = blockID;
  }

  static getType() {
    return "slid-auto-note";
  }

  static clone(node: SlidAutoNoteNode) {
    return new SlidAutoNoteNode(node.__key, node.__isLoading, node.__blockID);
  }

  static importJSON(serializedNode: SerializedSlidAutoNoteNode) {
    return $createParagraphNode();
  }

  exportJSON() {
    return {
      type: "slid-auto-note",
      isLoading: this.__isLoading,
      blockID: this.__blockID,
      version: 1,
    };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");

    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    return { element };
  }

  updateDOM(prevNode: SlidAutoNoteNode, dom: HTMLElement): boolean {
    return false;
  }

  isInline() {
    return false;
  }

  canBeEmpty() {
    return true;
  }

  decorate() {
    return <SlidMobileAutoNoteComponent isLoading={this.__isLoading} blockID={this.__blockID} />;
  }
}

export function $createSlidAutoNoteNode(payload: { isLoading: boolean; blockID: string }) {
  return new SlidAutoNoteNode(undefined, payload.isLoading, payload.blockID);
}

export function $isSlidAutoNoteNode(node: LexicalNode | null | undefined): node is SlidAutoNoteNode {
  return node instanceof SlidAutoNoteNode;
}
